/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { MdDelete } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import ConfirmModal from "components/modal/ConfirmModal";
// import PhoneNumberEditModal from "components/modal/ContactListEditModal";
import PhoneNumberAddModal from "components/modal/PhoneNumberAddModal";
import { useToast } from "@chakra-ui/react";
import { BsFillTelephonePlusFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { deletePhoneNumbers, getPhoneNumbers } from "services/phoneNumbers";
import Loader from "components/Loader/Loader";
import { getAssitantList } from "services/assitant";
import { FaCopy, FaPhoneAlt } from "react-icons/fa";
import {
  createInboundAssistant,
  getInboundAssitantList,
  updateInboundAssistant,
} from "services/inboundAssitant";
import { Trans, useTranslation } from "react-i18next";
import TooltipHorizon from "components/tooltip";
import { IoIosCheckmarkCircle } from "react-icons/io";
const Dialer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [rowData, setRowData] = useState("");
  // const [editData, setEditData] = useState("");
  const [isRequest, setIsRequest] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  // const [isDeleted, setIsDeleted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [, setShowConditionalList] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [assistantList, setAssistantList] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");
  useEffect(() => {
    getAssitantList()
      .then((response: any) => {
        if (response.success) {
          setAssistantList(response?.data);
        }
      })
      .catch((error: string) => {
        console.error("Error:", error);
      });
    fetchDataFromApis();
  }, [refresh]);
  // useEffect(() => {
  //   setIsRequest(true);
  //   fetchDataFromApis();
  // }, [refresh]);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (items: any) => {
    setRowData(items?.id);
    setIsModalOpen(!isModalOpen);
    setIsConfirm(false);
  };

  // const handleEditClose = () => {
  //   setIsEditModalOpen(false);
  // };

  // const handleEditModal = (items: any) => {
  //   setEditData(items);
  //   setIsEditModalOpen(!isEditModalOpen);
  // };

  const handleAddClose = () => {
    setIsAddModalOpen(false);
  };

  const handleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Add New",
        buttonName: "ADD NEW",
      });
    }
  };

  useEffect(() => {
    if (isConfirm === true) {
      deletePhoneNumbers(rowData)
        .then((response) => {
          if (response.success) {
            // setIsDeleted(true);
            setIsRequest(false);
            fetchDataFromApis();
            handleClose();
            toast({
              description: t("messages.dialerDeleted"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
          setIsConfirm(false);
        })
        .catch((error) => {
          console.error("Error submitting feedback:", error);
        });
    }
  }, [isConfirm]);

  const fetchDataFromApis = async () => {
    setIsRequest(true);
    try {
      // Call first API
      const firstData = await getPhoneNumbers();

      // Call second API after the first one completes
      const secondData = await getInboundAssitantList();
      if (firstData) {
        if (firstData && firstData?.data?.length === 0) {
          setShowVideo(true);
        } else {
          setShowVideo(false);
        }
      }
      if (firstData && secondData) {
        setIsRequest(false);
      }
      if (firstData && firstData?.status === 401) {
        navigate("/auth/sign-in");
      }
      setIsRequest(false);
      if (firstData && firstData?.success) {
        if (firstData?.data) {
          for (let item of firstData?.data) {
            for (let inb of secondData?.data) {
              if (item.id === inb.dialer_id) {
                item.incoming_count = inb.incoming_count;
                item.assistant_id = inb.assistant_id;
                item.inboundId = inb.id;
              }
            }
          }
          setCardData(firstData?.data);
          setSelectedAssistant(
            firstData?.data[selectedIndex]?.assistant_id || ""
          );
        }
      }
      if (firstData && !firstData?.success) {
        toast({
          description: firstData?.message,
          status: "info",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsRequest(false);
      // Handle errors here
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createInboundAsst = () => {
    if (selectedAssistant) {
      let createCampaignPayload = {
        name: cardData[selectedIndex].name,
        assistant_id: selectedAssistant,
        dialer_id: cardData[selectedIndex].id,
      };
      setIsRequest(true);
      createInboundAssistant(createCampaignPayload)
        .then((response: any) => {
          if (response.success) {
            fetchDataFromApis();
            setRefresh(!refresh);
            toast({
              description: t("messages.inboundCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            setIsRequest(false);
            toast({
              description: response?.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: string) => {
          setIsRequest(false);
          console.error("Error:", error);
        });
    } else {
      toast({
        description: t("selectAssistant"),
        status: "info",
        duration: 8000,
        isClosable: true,
        position: "top-left",
      });
    }
  };
  const updateInboundAsst = () => {
    if (selectedAssistant) {
      let createCampaignPayload = {
        name: cardData[selectedIndex].name,
        assistant_id: selectedAssistant,
        dialer_id: cardData[selectedIndex].id,
      };
      setIsRequest(true);
      updateInboundAssistant(
        createCampaignPayload,
        cardData[selectedIndex].inboundId
      )
        .then((response: any) => {
          if (response.success) {
            fetchDataFromApis();
            setRefresh(!refresh);
            toast({
              description: t("messages.inboundUpdated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            setIsRequest(false);
            toast({
              description: response?.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: string) => {
          setIsRequest(false);
          console.error("Error:", error);
        });
    } else {
      toast({
        description: t("selectAssistant"),
        status: "info",
        duration: 8000,
        isClosable: true,
        position: "top-left",
      });
    }
  };
  const handleIncomingCalls = (item: any) => {
    if (item?.incoming_count > 0) {
      navigate("/admin/dialers/incoming-calls", {
        state: { rowData: item },
      });
    }
  };
  return (
    <div className="second-step relative">
      {isRequest && <Loader />}
      {showVideo && (
        <div className="mb-3 mt-4 flex justify-end gap-2">
          <button
            onClick={() => {
              setShowVideo(!showVideo);
              setShowConditionalList(true);

              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "HOW TO BUY A NUMBER",
                  buttonName: "HOW TO BUY A NUMBER",
                });
              }
            }}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            {showVideo ? t("goBack") : t("dialers.howToBuyBtnNum")}
          </button>
          <button
            onClick={() => {
              handleAddModal();
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Add New",
                  buttonName: "DD NEW",
                });
              }
            }}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            {t("addNew")}
          </button>
        </div>
      )}
      {(showVideo && !isRequest) ? (
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full rounded-xl bg-white p-5 dark:bg-navy-600 lg:w-1/2">
            <iframe
              title="Twillio Video"
              width="100%"
              height="100%"
              src="/assets/Twilio.mp4"
              allowFullScreen
              className="rounded-lg"
              style={{ minHeight: "500px" }}
            ></iframe>
          </div>
          <div className="w-full rounded-xl bg-white p-5 dark:bg-navy-600 lg:w-1/2">
            <div className="mb-2 text-[16px] font-medium text-blue-800 hover:text-navy-700 dark:hover:text-white">
              {t("dialers.howToBuy.followSteps")}
            </div>
            <div className="mb-4 rounded-lg bg-[#CCEDFC] px-3 py-2 text-[16px] text-sm font-medium hover:text-navy-700 dark:bg-blue-900 dark:hover:text-white">
              <Trans i18nKey="dialers.howToBuy.note">
                <strong />
              </Trans>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  1
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step1")}</h6>
                </div>
              </div>
              <div className="flex h-8 items-center pl-4">
                <div className="h-full w-px bg-blue-800"></div>
              </div>
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  2
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step2")}</h6>
                </div>
              </div>
              <div className="flex h-8 items-center pl-4">
                <div className="h-full w-px bg-gray-500"></div>
              </div>
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  3
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step3")}</h6>
                </div>
              </div>
              <div className="flex h-8 items-center pl-4">
                <div className="h-full w-px bg-blue-800"></div>
              </div>
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  4
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step4")}</h6>
                </div>
              </div>
              <div className="flex h-8 items-center pl-4">
                <div className="h-full w-px bg-blue-800"></div>
              </div>
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  5
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step5")}</h6>
                </div>
              </div>
              <div className="flex h-8 items-center pl-4">
                <div className="h-full w-px bg-blue-800"></div>
              </div>
              <div className="flex items-center">
                <div className="flex h-8 min-w-8 items-center justify-center rounded-full bg-blue-800 text-white">
                  6
                </div>
                <div className="ml-4">
                  <h6 className="text-sm">{t("dialers.howToBuy.step6", {appName: appData?.website_name})}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`z-20 mt-2 ${
            cardData?.length === 0 && !isRequest ? "" : "" // grid grid-cols-1 gap-5 md:grid-cols-2 xxl:grid-cols-3
          }`}
        >
          {cardData?.length === 0 && !isRequest ? (
            <>
            <div className="mb-3 mt-4 flex justify-end gap-2">
              <button
                onClick={() => {
                  setShowVideo(!showVideo);
                  setShowConditionalList(true);

                  if (Array.isArray((window as any).dataLayer)) {
                    (window as any).dataLayer.push({
                      event: "HOW TO BUY A NUMBER",
                      buttonName: "HOW TO BUY A NUMBER",
                    });
                  }
                }}
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                {showVideo ? t("goBack") : t("dialers.howToBuyBtnNum")}
              </button>
            </div>
            <Card extra={"w-full h-full p-4"}>
              <div className="mb-auto flex flex-col items-center justify-center">
                <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                  <BsFillTelephonePlusFill />
                </div>
                <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                  {t("dialers.noDialer")}
                </h4>
                <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                  {t("dialers.getStarted")}
                </p>
                <button
                  onClick={() => {
                    handleAddModal();
                    if (Array.isArray((window as any).dataLayer)) {
                      (window as any).dataLayer.push({
                        event: "Add New",
                        buttonName: "DD NEW",
                      });
                    }
                  }}
                  className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                >
                  <GoPlus className="h-7 w-7" />
                  {t("addNew")}
                </button>
              </div>
            </Card>
            </>
          ) : (
            <>
              {cardData?.length > 0 && (
                <Card
                  extra={`min-h-[84.5vh]  flex flex-col w-full h-full 3xl:p-![18px] bg-white`}
                  image={""}
                >
                  <div className="block md:flex">
                    <div className="w-full overflow-auto border-r border-gray-200 dark:border-gray-700 md:w-1/4">
                      <div className="flex justify-start gap-2 p-4">
                        <button
                          onClick={() => {
                            setShowVideo(!showVideo);
                            setShowConditionalList(true);

                            if (Array.isArray((window as any).dataLayer)) {
                              (window as any).dataLayer.push({
                                event: "HOW TO BUY A NUMBER",
                                buttonName: "HOW TO BUY A NUMBER",
                              });
                            }
                          }}
                          className="flex w-full items-center justify-center gap-1 rounded bg-blue-700 px-2 py-2 text-[11px] text-white hover:bg-blue-800 3xl:text-[16px]"
                        >
                          {showVideo ? t("goBack") : t("dialers.howToBuyBtn")}
                        </button>
                        <button
                          onClick={() => {
                            handleAddModal();

                            if (Array.isArray((window as any).dataLayer)) {
                              (window as any).dataLayer.push({
                                event: "Add New",
                                buttonName: "DD NEW",
                              });
                            }
                          }}
                          className="flex w-full items-center justify-center gap-1 rounded bg-blue-700 px-2 py-2 text-[11px] text-white hover:bg-blue-800 3xl:text-[16px]"
                        >
                          <GoPlus className="h-3 w-3 3xl:h-7 3xl:w-7" />
                          {t("addNew")}
                        </button>
                      </div>
                      <div className="flex flex-col gap-y-2 overflow-auto p-4">
                        {cardData &&
                          cardData?.map((dailer: any, index: number) => (
                            <div key={index}>
                              <div
                                className={`flex w-full cursor-pointer flex-col rounded-lg border ${
                                  selectedIndex === index
                                    ? "bg-teal-500/10"
                                    : "bg-transparent"
                                } hover:border-teal/5 p-2 transition-all duration-100 ease-in-out hover:bg-teal-500/10`}
                                onClick={() => {
                                  setSelectedIndex(index);
                                  setSelectedAssistant(
                                    dailer?.assistant_id || ""
                                  );
                                }}
                              >
                                <div className="flex items-center">
                                  <div className="ellipsis-text text-sm font-semibold">
                                    {dailer?.phone_number}
                                  </div>
                                </div>
                                <div className="description-text text-white/67 text-xs">
                                  <div className="w-full">{dailer?.name}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="w-full p-4 md:w-3/4">
                      <div className="h-full w-full overflow-auto bg-[transparent]">
                        <div className="dialer-right">
                          <div className="mb-4">
                            <div>
                              <div className="flex justify-between">
                                <div className="flex flex-col gap-2">
                                  <span className="flex items-center text-2xl font-medium">
                                    {cardData[selectedIndex]?.phone_number}
                                  </span>
                                  <span
                                    className="font-bold"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {cardData[selectedIndex]?.name}
                                  </span>
                                </div>
                                <div className="flex gap-4">
                                  <MdDelete
                                    onClick={() => {
                                      handleModal(cardData[selectedIndex]);

                                      if (
                                        Array.isArray((window as any).dataLayer)
                                      ) {
                                        (window as any).dataLayer.push({
                                          event: "Delete details",
                                          buttonName: "Delete ",
                                        });
                                      }
                                    }}
                                    className="h-6 w-6 cursor-pointer"
                                  />
                                </div>
                              </div>
                              <div className="mt-2 pb-4">
                                <p className="chakra-text css-4nnolj">
                                  {t("dialers.thisAccount")}
                                </p>
                              </div>
                              <div className="flex w-max items-center rounded-md border border-blue-700 p-4">
                                {t("dialerId")} :
                                <div className="whitespace-nowrap px-6 font-medium text-gray-900 dark:text-white">
                                  {/* {item?.auth_key} */}
                                  {cardData[selectedIndex]?.id
                                    .slice(0, -4)
                                    .replace(/./g, "•") +
                                    cardData[selectedIndex]?.id.slice(-4)}
                                  <span className="pl-20">
                                    <button
                                      className="text-blue-700"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          cardData[selectedIndex]?.id
                                        )
                                      }
                                    >
                                      <TooltipHorizon
                                        trigger={
                                          <span>
                                            <FaCopy />
                                          </span>
                                        }
                                        placement="top"
                                        content={t("assistantForm.copyText")}
                                        extra="rounded text-[11px]"
                                      />
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-black/10 rounded-xl border border-blue-400">
                            <div className="flex flex-col gap-y-4">
                              <div className="block items-center justify-between gap-2 p-4 md:flex">
                                <div>
                                  <h2 className="text-xl">
                                    {t("dialers.inboundSettings")}
                                  </h2>
                                  <p className="text-muted-foreground text-xs ">
                                    {t("dialers.inboundSettingsCaption")}
                                  </p>
                                </div>
                                <button
                                  disabled={
                                    cardData[selectedIndex]?.incoming_count > 0
                                      ? false
                                      : true
                                  }
                                  className={`border-blueSecondary-900 mt-4 flex min-w-[150px] items-center justify-center gap-1 rounded border px-2 py-2 text-sm text-white 3xl:text-lg ${
                                    cardData[selectedIndex]?.incoming_count > 0
                                      ? "bg-blue-700 hover:bg-blue-800"
                                      : " bg-[#888]"
                                  }`}
                                  onClick={() =>
                                    handleIncomingCalls(cardData[selectedIndex])
                                  }
                                >
                                  <FaPhoneAlt className="h-4 w-4 cursor-pointer" />
                                  {cardData[selectedIndex]?.incoming_count}{" "}
                                  {t("dialers.incomingCalls")}
                                </button>
                              </div>
                              <div className="flex-1 overflow-hidden rounded-xl border border-white/5 bg-[#ffffff07] p-6">
                                <div className="flex flex-col items-center justify-between gap-4">
                                  <div className="flex w-full flex-col gap-y-4">
                                    <div className="flex flex-col gap-y-2">
                                      <label className="text-xs font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        {t("dialers.inboundPhone")}
                                      </label>
                                      <div className="bg-black/20 border-black-400 flex cursor-not-allowed flex-row items-center justify-between gap-2 rounded-lg border p-2">
                                        <span className="text-grey/50 text-sm">
                                          {
                                            cardData[selectedIndex]
                                              ?.phone_number
                                          }
                                        </span>
                                        <IoIosCheckmarkCircle className="h-5 w-5 text-blue-700" />
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-y-2">
                                      <label className="text-xs font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        {t("dialers.assistant")}
                                      </label>

                                      <select
                                        className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                                        value={selectedAssistant}
                                        onChange={(e) =>
                                          setSelectedAssistant(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          {t("dialers.selectAssistant")}
                                        </option>
                                        {assistantList &&
                                          assistantList?.map((op, i) => (
                                            <option key={i} value={op.id}>
                                              {op.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <p className="text-warning pt-2 text-xs">
                                  {t("dialers.docText", {appName: appData?.website_name})}{" "}
                                  <a
                                    target="_blank"
                                    href="https://docs.precallai.com/general/dialer/introduction"
                                    rel="noreferrer"
                                    className="text-blue-700"
                                  >
                                    {t("dialers.ourDocs")}
                                  </a>
                                </p>
                                <div className="flex items-center justify-start gap-1">
                                  <button
                                    onClick={() => {
                                      if (cardData[selectedIndex]?.inboundId) {
                                        updateInboundAsst();
                                      } else {
                                        createInboundAsst();
                                      }
                                    }}
                                    className="mt-4 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                                  >
                                    {t("save")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </>
          )}
        </div>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        rowData={rowData}
        setCardData={setCardData}
        setIsConfirm={setIsConfirm}
        setRefresh={setRefresh}
        refresh={refresh}
        type={t("dialers.dialer")}
      />
      {/* <PhoneNumberEditModal
        isOpen={isEditModalOpen}
        handleClose={handleEditClose}
        editData={editData}
        setCardData={setCardData}
        setRefresh={setRefresh}
        refresh={refresh}
        loading={setIsRequest}
      /> */}
      <PhoneNumberAddModal
        isOpen={isAddModalOpen}
        handleClose={handleAddClose}
        setCardData={setCardData}
        setRefresh={setRefresh}
        refresh={refresh}
        loading={setIsRequest}
      />
    </div>
  );
};

export default Dialer;
