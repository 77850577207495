import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET ApiKey LIST

export const getApiKeyList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/api-key/list`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE APIKEY

export const createApiKey = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/api-key/create`,
      null,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE APIKEY

export const deleteApiKey = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/api-key/delete?id=${data}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
