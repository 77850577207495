/* eslint-disable react-hooks/exhaustive-deps */
//useCallback
import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import { signUp } from "../../services/Auth";
import { useColorMode, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import GoogleAuth from "components/googleAuth/GoogleAuth";
import Footer from "components/footer/FooterAuthDefault";
import { useTranslation } from "react-i18next";
import { gtag } from "utils/utils";
import ReCAPTCHA from "react-google-recaptcha";
// import { FcGoogle } from "react-icons/fc";
const googleSiteKey = process.env.REACT_APP_RECAPTCHA_KEY;

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const affiliate_id = searchParams && searchParams.get("aff");
  const [recaptchaKey, setRecaptchaKey] = useState(Date.now());
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");
  useEffect(() => {
    // Update the key when the color mode changes to force re-render
    setRecaptchaKey(Date.now());
  }, [colorMode]);
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    recaptcha: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("signUp.firstNameRequired")),
    lastName: Yup.string().required(t("signUp.lastNameRequired")),
    email: Yup.string()
      .email(t("invalidEmail"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("invalidEmail"))
      .required(t("requireEmail")),
    password: Yup.string()
      .min(8, t("passwordValidation"))
      .required(t("passwordRequired")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwordMatchValidation"))
      .required(t("confirmPasswordRequired")),
    recaptcha: Yup.string().required(t("recaptchaRequired")),
  });

  // const gtagReportConversion = useCallback((url?: string): boolean => {
  //   const callback = () => {
  //     if (typeof url !== 'undefined') {
  //       window.location.href = url;
  //     }
  //   };

  //   (window as any).gtag('event', 'conversion', {
  //     'send_to': 'AW-16575539394/4mDTCJLLgLQZEMLR6t89',
  //     'event_callback': callback,
  //   });

  //   return false;
  // }, []);

  const handleSignup = (values: any) => {
    setLoading(true);
    let signUpPayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      affiliate_id: affiliate_id,
    };
    signUp(signUpPayload)
      .then((response) => {
        if (response.success) {
          gtag("event", "conversion", {
            send_to: "AW-16575539394/4mDTCJLLgLQZEMLR6t89",
          });
          // gtagReportConversion('https://app.precallai.com');
          toast({
            description: t("messages.signup"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          // navigate("/auth/onboarding");
          navigate("/auth/verifyuser");
          setLoading(false);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const token = localStorage.getItem("token");
  const steps = localStorage.getItem("steps");
  useEffect(() => {
    if (token && Number(steps) > 0) {
      navigate("/admin/dashboard");
    }
    if (!appData?.is_register_new_user_allowed) {
      navigate("/auth/sign-in");
    }
  }, []);
  const handleRecaptchaExpire = (setFieldValue: any) => {
    setFieldValue("recaptcha", ""); // Reset reCAPTCHA value
    setTimeout(() => setFieldValue("recaptcha", ""), 0); // Trigger validation
  };
  return (
    <div className=" mt-0 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mt-4 lg:items-center lg:justify-start">
      {loading && <Loader />}
      <div className="mt-5 w-full max-w-full flex-col items-center py-[60px] lg:pl-0 xl:max-w-[420px] xxl:mt-10">
        <h1 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
          {t("signUp.signUpText")}
        </h1>
        <p className="mb-5 ml-1 text-base text-gray-600">
          {t("signUp.signUpCaption")}
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            handleSignup(values);

            if (Array.isArray((window as any).dataLayer)) {
              (window as any).dataLayer.push({
                event: "Sign-up",
                buttonName: "Sign-up",
              });
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="flex flex-col gap-4 md:flex-row">
                <div className="md:w-[50%]">
                  <InputField
                    variant="auth"
                    extra="mb-1"
                    label={t("firstName") + "*"}
                    placeholder="Enter your First Name"
                    id="firstName"
                    type="text"
                    name="firstName"
                  />
                </div>
                <div className="md:w-[50%]">
                  <InputField
                    variant="auth"
                    extra="mb-1"
                    label={t("lastName") + "*"}
                    placeholder="Enter your Last Name"
                    id="lastName"
                    type="text"
                    name="lastName"
                  />
                </div>
              </div>
              <InputField
                variant="auth"
                extra="mb-1"
                label={t("signUp.companyEmail") + "*"}
                placeholder="mail@yourcompanyname.com"
                id="email"
                type="text"
                name="email"
              />
              <InputField
                variant="auth"
                extra="mb-2"
                label={t("password") + "*"}
                placeholder={t("passwordPlaceholder")}
                id="password"
                type="password"
                name="password"
              />
              <InputField
                variant="auth"
                extra="mb-1"
                label={t("confirmPassword") + "*"}
                placeholder={t("passwordPlaceholder")}
                id="confirmPassword"
                type="password"
                name="confirmPassword"
              />
              <div className={`recaptcha-container mb-2 mt-4 ${colorMode}`}>
                <ReCAPTCHA
                  key={recaptchaKey}
                  theme={colorMode}
                  id="recaptcha"
                  sitekey={googleSiteKey}
                  onChange={(value) => {
                    setFieldValue("recaptcha", value);
                  }}
                  onExpired={() => handleRecaptchaExpire(setFieldValue)}
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="error-message"
                />
              </div>
              <button
                type="submit"
                className="linear mt-2 w-full rounded bg-blue-700 py-[8px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
              >
                {t("signUp.signUpText")}
              </button>
            </Form>
          )}
        </Formik>
        <div className="my-3 text-center">{t("or")}</div>
        <div className="google-btn flex w-full justify-center">
          <GoogleAuth setLoading={setLoading} affiliate_id={affiliate_id} />
        </div>
        <div className="mt-4 flex items-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            {t("alreadyMember")}
          </span>
          <Link
            to="/auth/sign-in"
            className="ml-1 text-blue-700 dark:text-blue-700"
          >
            {t("signInText")}
          </Link>
        </div>
        <div className="mt-5 flex items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
