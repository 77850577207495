import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import { checkValidPhoneNumber, verifyOtp } from "services/Auth";
import PhoneInput from "react-phone-input-2";
import { useColorMode } from "@chakra-ui/system";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import OtpInput from 'react-otp-input';
import { MdEdit } from "react-icons/md";

const PhoneNumberVerifyModal = ({ isOpen, handleClose, setIsVerify, loginResponseData }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { colorMode } = useColorMode();
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [isOtpSend, setIsOtpSend] = useState(false);
  
  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(t("otpValid")),
  });

  const formik = useFormik({
    initialValues: {
        otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
        if (values.otp?.length === 6) {
            setLoading(true);
            let payload = {
                phoneNumber: phone,
                otp: values.otp,
            };
            let token = loginResponseData?.token;
            verifyOtp(payload, token).then((response: any) => {
                if (response.success) {
                toast({
                    description: response.message,
                    status: "success",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
                resetForm();
                setIsVerify(true);
                handleClose();
                } else {
                toast({
                    description: response.message,
                    status: "info",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
                }
                setLoading(false);
            });
        }
    },
  });
  const handleCloseModal = () => {
    handleClose();
  };
  const checkPhoneNumber = () => {
    
    if (phone) {
        const myPhone = convertToE164(phone);
        const isValid = isValidPhoneNumber(myPhone);
        
        if (!isValid) {
          toast({
            description: "Please enter a valid phone number with a correct country code.",
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return;
        }
  
        const currentTime = Date.now();
        const savedCount = localStorage.getItem('countOtpSend') || '0';
        const savedTime = localStorage.getItem('lastOtpSendTime');
  
        let countOtpSend = Number(savedCount);
  
        // Check if there's a saved timestamp and if 30 minutes have passed
        if (savedTime) {
          const elapsedTime = currentTime - Number(savedTime);
  
          // Reset count if 30 minutes (1800000 ms) have passed
          if (elapsedTime >= 1800000) {
            countOtpSend = 0; // Reset the count
            localStorage.removeItem('countOtpSend');
            localStorage.removeItem('lastOtpSendTime');
          }
        }
  
        if (countOtpSend >= 3) {
          const timeLeft = Math.ceil((1800000 - (currentTime - Number(savedTime))) / 1000); // time left in seconds
          toast({
            description: t("otpLimitExceeded", { timeLeft }),
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return;
        }
  
        setLoading(true);
        const payload = {
          phoneNumber: phone,
        };
        let token = loginResponseData?.token;
        checkValidPhoneNumber(payload, token)
          .then((response) => {
            if (response.success) {
              setIsOtpSend(true);
              countOtpSend += 1; // Increment count
              localStorage.setItem('countOtpSend', countOtpSend.toString()); // Update the count
              localStorage.setItem('lastOtpSendTime', currentTime.toString()); // Update the last send time
            } else {
              toast({
                description: response.message,
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error :", error);
          });
      } else {
        toast({
          description: "Please enter a valid phone number with a correct country code.",
          status: "info",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
      }
  }
  useEffect(() => {
    if (isOpen && loginResponseData) {
        setPhone(loginResponseData?.phone);
    }
  }, [isOpen, loginResponseData]);
  return (
    <>
      {loading && <Loader />}
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"md"}
        closeOnOverlayClick={false} 
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalHeader>{t("otpVerification")}</ModalHeader>
          <ModalBody pb={6}>
            {
                !isOtpSend ?
                <div>
                    <FormControl>
                        <div className="flex items-center gap-3">
                            <PhoneInput
                            inputProps={{
                                name: "phone",
                                required: true,
                                className:
                                "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] max-w-[500px] items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none",
                            }}
                            buttonStyle={{
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px",
                            }}
                            inputStyle={{
                                background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                                color: colorMode === "dark" ? "white" : "black",
                            }}
                            dropdownStyle={{
                                background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                                color: colorMode === "dark" ? "white" : "black",
                            }}
                            country={"us"}
                            value={phone}
                            onChange={(value, country, event, formattedValue) => {
                                let myPhone = convertToE164(formattedValue);
                                setPhone(myPhone);
                            }}
                            />
                            {/* <button className="bg-blue-700 hover:bg-blue-800 active:bg-blue-800 linear bg-bg-blue-700 flex items-center justify-center gap-2 rounded px-[16px]  py-[6px] text-base font-medium text-white transition duration-200" onClick={() => checkPhoneNumber()}>Send OTP</button> */}
                        </div>
                        
                    </FormControl>
                </div>
                :
                <div>

                    <FormControl>
                        <div className="flex items-center gap-4 mb-5 justify-center">{t("sendOtpMsg")} {phone} <MdEdit className="w-5 h-5 text-blue-700 cursor-pointer" onClick={() => {
                            setIsOtpSend(false);
                            formik.resetForm()
                        }} /></div>
                        <FormLabel>{t("otp")} *</FormLabel>
                        <OtpInput
                            value={formik.values.otp}
                            onChange={(e) => formik.setFieldValue("otp", e)}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            containerStyle="otp-container"
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                            <div className="text-red-500">
                            {formik.errors.otp.toString()}
                            </div>
                        ) : null}
                    </FormControl>
                </div>
            }
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"start"}>
            {
                isOtpSend ?
                <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => formik.handleSubmit()}
                    className="dark:bg-blue-700 dark:text-white"
                    >
                    {t("submitText")}
                </Button>
                :
                <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => checkPhoneNumber()}
                    className="dark:bg-blue-700 dark:text-white"
                    >
                    {t("sendOtp")}
                </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PhoneNumberVerifyModal;
