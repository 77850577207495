interface CardProps {
  variant?: string;
  extra?: string;
  children?: JSX.Element | any[];
  [x: string]: any;
}

const Card = ({ variant, extra, children, ...rest }: CardProps) => (
  <div
    className={`relative flex flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
    {...rest}
  >
    {children}
  </div>
);

export default Card;
