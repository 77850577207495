/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { getAffiliateUserList } from "services/affiliate";
import Loader from "components/Loader/Loader";
import { useToast } from "@chakra-ui/react";
import Pagination from "components/pagination";
import SearchBar from "views/admin/default/components/Searchbar";
import { responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";
const AffiliatedUsers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const [isRequest, setIsRequest] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableDataAll, setTableDataAll] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTableData = tableData?.slice(indexOfFirstUser, indexOfLastUser);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAffiliateUserList()
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          setTableData(response?.data);
          setTableDataAll(response?.data);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (tableData) {
      const filteredData = tableDataAll.filter(
        (item: any) =>
          item?.first_name?.toLowerCase()?.includes(value.toLowerCase()) ||
          item?.email?.toLowerCase()?.includes(value.toLowerCase())
      );
      setTableData(filteredData);
    }
  };

  return (
    <div>
      {isRequest && <Loader />}
      <div>
        <div className="mt-2 flex justify-between gap-4 py-2">
          <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
            {t("affiliate.affiliatedUsers")}
          </span>
          <div className="flex items-center gap-3">
            <SearchBar
              initialData={searchQuery}
              handleFilteredData={handleSearch}
            />
            <button
              onClick={() => navigate("/admin/affiliate")}
              className="flex h-[46px] items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <FaArrowLeft className="h-4 w-4" />
              {t("goBack")}
            </button>
          </div>
        </div>
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mt-1 overflow-x-auto pb-4">
          <table className="responsive-table w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.usersTable.no")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.usersTable.fName")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.usersTable.lName")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("affiliate.usersTable.email")}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData && currentTableData?.length > 0 ? (
                currentTableData?.map((item: any, index) => (
                  <tr key={index}>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.usersTable.no")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {index + 1}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.usersTable.fName")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {item?.first_name}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.usersTable.lName")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {item?.last_name}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("affiliate.usersTable.email")}
                    >
                      <p className="flex items-center justify-end gap-2 sm:justify-center">
                        {item?.email}
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="py-4">
                    <div className="mb-auto flex flex-col items-center justify-center">
                      <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                        {t("affiliate.usersTable.noUsers")}
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            perPage={perPage}
            allTotal={tableData?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default AffiliatedUsers;
