import { parsePhoneNumberFromString } from "libphonenumber-js";
import Swal from "sweetalert2";
import languages from "assets/data/language.json";
// const svgPath = "../../assets";
export const get_token = () => {
  return localStorage.getItem("token");
};

export const gtag = (...args: any[]) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(...args);
};

export function isValidPhoneNumber(phoneNumber: any) {
  try {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  } catch (error) {
    return false;
  }
}
export function formatDateAmPm(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}/
                        ${date.getDate().toString().padStart(2, "0")}/
                        ${date.getFullYear()} ${(date.getHours() % 12 || 12)
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")} ${
    date.getHours() >= 12 ? "PM" : "AM"
  }`;
  return formattedDate;
}
export function checkTransaction() {
  let isTrans = localStorage.getItem("isTransaction");
  if (isTrans === "true") {
    return true;
  } else {
    return false;
  }
}
export function rechargeWalletMsg() {
  Swal.fire({
    title: "Info",
    text: "Please recharge your wallet.",
    icon: "info",
    confirmButtonText: "OK",
  }).then((result: any) => {
    // setIsModalOpen(false);
  });
}
export function convertToE164(formattedValue: any) {
  // Remove all non-digit characters from the formatted value
  const digitsOnly = formattedValue.replace(/\D/g, "");
  // If the first character is not '+', add it
  if (digitsOnly[0] !== "+") {
    return `+${digitsOnly}`;
  }
  return digitsOnly;
}

export function normalizeInput(phoneNumber: any) {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedNumber) {
      return parsedNumber.formatInternational();
    }
    return phoneNumber; // Return the original phone number if parsing fails
  } catch (error) {
    console.error("Error formatting phone number:", error);
    return phoneNumber; // Return the original phone number if there's an error
  }
}

export const getLanguageKeyByValue = (value: string): string | undefined => {
  return Object.keys(languages).find(
    (key) => languages[key as keyof typeof languages] === value
  );
};

export function numberWithCommasAndDollar(number: any) {
  if (number || number === 0) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  } else {
    return "-";
  }
}

export function responseHendler(response: any) {
  if (response.status === 401 || response.status === 403) {
    let themeChange = localStorage.getItem("isThemeChange");
    let themeMode = localStorage.getItem("themeMode");
    let appSetting = localStorage.getItem("appSetting");
    localStorage.clear();
    localStorage.setItem("isThemeChange", themeChange);
    localStorage.setItem("themeMode", themeMode);
    localStorage.setItem("appSetting", appSetting);
    window.location.href = "/auth/sign-in";
  }
}
