import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// ResiveMassage Api
export const ResiveMassage = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/script/startScriptApi`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SendMassege Api

export const SendMassege = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/script/generateChat`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
