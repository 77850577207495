import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const WavePlayer = ({
  audio,
  index,
  currentPlayingIndex,
  setCurrentPlayingIndex,
}: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [audioExists, setAudioExists] = useState(false);

  useEffect(() => {
    const checkAudioExists = async () => {
      try {
        const response = await fetch(audio, { method: "HEAD" });
        if (response.ok) {
          setAudioExists(true);
          const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            height: 40,
            barWidth: 3,
            barRadius: 1,
            cursorWidth: 1,
            waveColor: "#95d1e8",
            progressColor: "#06749d",
            cursorColor: "transparent",
          });

          waveSurfer.load(audio);

          waveSurfer.on("ready", () => {
            waveSurferRef.current = waveSurfer;
          });

          waveSurfer.on("error", (error) => {
            // console.error("WaveSurfer error:", error);
          });

          return () => {
            waveSurfer.destroy();
          };
        } else {
          setAudioExists(false);
        }
      } catch (error) {
        console.log(error);
        setAudioExists(false);
      }
    };

    checkAudioExists();
  }, [audio]);

  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current.destroy();
      waveSurferRef.current = null;
    }
  }, [audio]);

  useEffect(() => {
    if (currentPlayingIndex !== index && isPlaying) {
      waveSurferRef.current?.pause();
      toggleIsPlaying(false);
    }
  }, [currentPlayingIndex, index, isPlaying]);

  const handlePlayPause = () => {
    if (currentPlayingIndex !== index) {
      if (currentPlayingIndex !== null && waveSurferRef.current) {
        waveSurferRef.current.pause();
      }
      setCurrentPlayingIndex(index);
      waveSurferRef.current?.play();
      toggleIsPlaying(true);
    } else {
      waveSurferRef.current?.playPause();
      toggleIsPlaying(waveSurferRef.current?.isPlaying() || false);
    }
  };

  return (
    <div
      className={
        audioExists
          ? "grid grid-cols-[40px_1fr]"
          : "flex items-center justify-center"
      }
    >
      {audioExists ? (
        <div
          onClick={handlePlayPause}
          className="flex cursor-pointer items-center"
        >
          {isPlaying ? (
            <FaPauseCircle className="h-5 w-5" />
          ) : (
            <FaPlayCircle className="h-5 w-5" />
          )}
        </div>
      ) : (
        "-"
      )}
      <div ref={containerRef} />
    </div>
  );
};

export default WavePlayer;
