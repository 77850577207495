import React, { useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const EditknowlageBaseModal = ({
  isOpen,
  handleClose,
  isEdit,
  setIsEditModal,
  cardIndex,
  setCardIndex,
  questionsAnswers,
  formikSet,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  //  .max(250, t("knowledgeBase.charValid")),
  const validationSchema = Yup.object().shape({
    question: Yup.string().required(t("knowledgeBase.questionValid")),
    answer: Yup.string()
      .required(t("knowledgeBase.ansValid"))
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        question: values?.question,
        answer: values?.answer,
      };
      let allQuesAns = questionsAnswers;
      allQuesAns[cardIndex] = payload;
      formikSet.setFieldValue("data", allQuesAns);
      closeKnowladgeBase();
    },
  });
  useEffect(() => {
    if (questionsAnswers) {
      if (isEdit) {
        formik.setValues({
          question: questionsAnswers[cardIndex]?.question || "",
          answer: questionsAnswers[cardIndex]?.answer || "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsAnswers, isEdit, cardIndex]);
  const closeKnowladgeBase = () => {
    setIsEditModal(false);
    setCardIndex(null);
    formik.resetForm();
    handleClose();
  };
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          closeKnowladgeBase();
        }}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader>
            {isEdit
              ? t("knowledgeBase.updateFlashCard")
              : t("knowledgeBase.createFlashCard")}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>{t("knowledgeBase.question")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="question"
                name="question"
                type="text"
                placeholder={t("knowledgeBase.placeholder1")}
                value={formik.values.question}
                onChange={formik.handleChange}
              />
              {formik.touched.question && formik.errors.question ? (
                <div className="text-red-500">
                  {formik.errors.question.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("knowledgeBase.answer")} *</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="answer"
                name="answer"
                rows={3}
                placeholder={t("knowledgeBase.placeholder2")}
                value={formik.values.answer}
                onChange={(e) => {
                  // if (e.target.value.length <= 250) {
                    formik.setFieldValue(
                      "answer",
                      e.target.value
                    );
                  // }
                }}
              ></textarea>
              <div
                className={
                  formik.touched.answer && formik.errors.answer
                    ? "flex justify-between gap-3"
                    : "flex justify-end gap-3"
                }
              >
                {formik.touched.answer && formik.errors.answer ? (
                  <div className="text-red-500">
                    {formik.errors.answer.toString()}
                  </div>
                ) : null}
                {/* <div className="text-right text-[14px] text-gray-700">
                  {formik.values.answer.length}/250{" "}
                  {t("knowledgeBase.characters")}
                </div> */}
              </div>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              type="submit"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button
              onClick={() => {
                closeKnowladgeBase();
              }}
            >
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditknowlageBaseModal;
