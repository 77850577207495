import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import { verifyOtp } from "services/Auth";
import OTPInput from "react-otp-input";
import { MdEdit } from "react-icons/md";

const OtpVerifyModal = ({ isOpen, handleClose, setIsVerified, phone }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(t("otpValid")),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
        if ( values.otp?.length === 6) {
            setLoading(true);
            let payload = {
                otp: values.otp,
                phoneNumber: phone,
            };
            let token = localStorage.getItem("token");
            verifyOtp(payload, token).then((response: any) => {
                if (response.success) {
                toast({
                    description: response.message,
                    status: "success",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
                resetForm();
                setIsVerified(true);
                handleClose();
                } else {
                toast({
                    description: response.message,
                    status: "info",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                });
                }
                setLoading(false);
            });
        }
    },
  });
  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <>
      {loading && <Loader />}
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleCloseModal}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"md"}
        closeOnOverlayClick={false} 
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalHeader>{t("otpVerification")}</ModalHeader>
          <ModalBody pb={6}>
            <div className="flex items-center gap-4 mb-5 justify-center">{t("sendOtpMsg")} {phone} <MdEdit className="w-5 h-5 text-blue-700 cursor-pointer" onClick={() => {
                handleClose();
                formik.resetForm();
            }} /></div>
            <FormControl>
              <FormLabel>{t("otp")} *</FormLabel>
              <OTPInput
                    value={formik.values.otp}
                    onChange={(e) => formik.setFieldValue("otp", e)}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle="otp-container"
                />
              {formik.touched.otp && formik.errors.otp ? (
                <div className="text-red-500">
                  {formik.errors.otp.toString()}
                </div>
              ) : null}
            </FormControl>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("submitText")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OtpVerifyModal;
