import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { profileDetailUpdate } from "services/Auth";
import Loader from "components/Loader/Loader";
interface FormData {
  name: string;
  email: string;
  roles: any[];
}
const initialValues: FormData = {
  name: "",
  email: "",
  roles: [],
};
const AddNewUserModal = ({ onClose, isOpen }: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    roles: Yup.array()
      .of(Yup.string().required("Roles is required"))
      .min(1, "At least one role is required"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      const payload = {
        name: values?.firstName,
        email: values?.lastName,
        roles: values?.roles,
      };
      profileDetailUpdate(payload)
        .then((response) => {
          if (response?.success) {
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            setLoading(false);
            handleCloseModal();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error submitting feedback:", error);
        });
    },
  });

  useEffect(() => {}, [isOpen]);
  const handleCloseModal = () => {
    formik.resetForm();
    onClose();
  };
  return (
    <div>
      {loading && <Loader />}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
        onClose={handleCloseModal}
        size={"xxl"}
        isOpen={isOpen}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent
          maxW={"1000px"}
          borderRadius={8}
          className="dark:bg-navy-900"
        >
          <ModalHeader className="text-center">Add a New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={"20px 24px"}>
            <div>
              <div className="flex justify-between gap-3">
                <FormControl>
                  <FormLabel>Name*</FormLabel>
                  <input
                    className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="name"
                    name="name"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-500">
                      {formik.errors.name.toString()}
                    </div>
                  ) : null}
                </FormControl>
                <FormControl>
                  <FormLabel>Email ID*</FormLabel>
                  <input
                    className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500">
                      {formik.errors.email.toString()}
                    </div>
                  ) : null}
                </FormControl>
              </div>
              <FormLabel>Select Role & Privileages</FormLabel>
              <div className="flex justify-between gap-3">
                <Card className="mt-5 p-3">
                  <FormLabel>Benefits Validator</FormLabel>
                  <div>
                    <FormControl mt={4}>
                      <Checkbox
                        id="view"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        View
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="create"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Create
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="update"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Update
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="delete"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Delete
                      </Checkbox>
                    </FormControl>
                  </div>
                </Card>
                <Card className="mt-5 p-3">
                  <FormLabel>Code Reviewer</FormLabel>
                  <div>
                    <FormControl mt={4}>
                      <Checkbox
                        id="view"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        View
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="create"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Create
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="update"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Update
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="delete"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Delete
                      </Checkbox>
                    </FormControl>
                  </div>
                </Card>
                <Card className="mt-5 p-3">
                  <FormLabel>PA Request Manager</FormLabel>
                  <div>
                    <FormControl mt={4}>
                      <Checkbox
                        id="view"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        View
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="create"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Create
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="update"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Update
                      </Checkbox>
                    </FormControl>
                    <FormControl mt={4}>
                      <Checkbox
                        id="delete"
                        colorScheme="cyan"
                        onChange={formik.handleChange}
                      >
                        Delete
                      </Checkbox>
                    </FormControl>
                  </div>
                </Card>
              </div>
            </div>
          </ModalBody>
          <ModalFooter display={"flex"} justifyContent={"end"} gap={"20px"}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              ADD USER
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddNewUserModal;
