/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { PiSpeakerSimpleHighBold } from "react-icons/pi";
import { MdOutlinePlayCircle, MdPeople, MdSegment } from "react-icons/md";
import NewCpModal from "components/modal/NewCpModal";
import { GoPlus } from "react-icons/go";
import AddCampaignsModal from "components/modal/AddCampaignsModal";
import { getCampaignList } from "services/campaign";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "components/modal/ConfirmModal";
import { FaRegCalendar } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { formatDateAmPm } from "utils/utils";
import SearchBar from "../default/components/Searchbar";
import { useTranslation } from "react-i18next";

const Campaigns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewCpModal, setIsNewCpModalOpen] = useState(false);
  const [isAddCampaignsModalOpen, setIsAddCampaignsModalOpen] = useState(false);
  const [cardData, setCardData] = useState<any[]>([]);
  const [cardDataAll, setCardDataAll] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [rowData, setRowData] = useState();
  const [campaignsData, setCampaignsData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    getCampaignList()
      .then((response: any) => {
        setLoading(false);
        if (response.success) {
          if (response?.data) {
            setCardData(response?.data);
            setCardDataAll(response?.data);
          } else {
            setCardData([]);
          }
        }
      })
      .catch((error: string) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  }, [refresh]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleNewCpClose = () => {
    setIsNewCpModalOpen(false);
  };

  const handleNewCpModal = (items: any) => {
    setIsNewCpModalOpen(!isNewCpModal);
    setRowData(items?.id);
    setCampaignsData(items);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Start Campaign",
        buttonName: "Start",
      });
    }
  };

  const handleAddCampaignsClose = () => {
    setIsAddCampaignsModalOpen(false);
  };

  const handleAddCampaignsModal = () => {
    setIsAddCampaignsModalOpen(!isAddCampaignsModalOpen);
  };

  const handleClick = (items: any) => {
    navigate("/admin/campaigns-detail", {
      state: { rowData: items },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (cardData) {
      const filteredData = cardDataAll.filter(
        (item: any) =>
          typeof item?.campaigns_name === "string" &&
          item?.campaigns_name?.toLowerCase()?.includes(value.toLowerCase())
      );
      setCardData(filteredData);
    }
  };

  return (
    <div className="ningth-step relative mt-4">
      {loading && <Loader />}
      <div className="mb-3 mt-4 flex justify-end gap-4">
        <SearchBar
          initialData={searchQuery}
          handleFilteredData={handleSearch}
        />
        <button
          onClick={() => {
            handleAddCampaignsModal();

            if (Array.isArray((window as any).dataLayer)) {
              (window as any).dataLayer.push({
                event: "Add new Campaigns",
                buttonName: "Add new Campaigns",
              });
            }
          }}
          className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
        >
          <GoPlus className="h-7 w-7" />
          {t("addNew")}
        </button>
      </div>
      {cardData && cardData?.length === 0 && !loading ? (
        <Card extra={"w-full h-full p-4"}>
          {/* Your storage */}
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <PiSpeakerSimpleHighBold />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("campaign.noCampaigns")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("campaign.getStarted")}
            </p>
            <button
              onClick={() => handleAddCampaignsModal()}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("addNew")}
            </button>
          </div>
        </Card>
      ) : (
        <div className="z-20 mt-2  grid grid-cols-1 gap-5 md:grid-cols-2 xxl:grid-cols-3">
          {cardData &&
            cardData?.map((card, index) => (
              <Card
                key={index}
                extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
                price="0.7"
              >
                <div className="flex h-full w-full flex-col">
                  <div className="flex justify-between">
                    <div>
                      <h3
                        onClick={() => handleClick(card)}
                        className="cursor-pointer text-xl font-bold text-blue-700 hover:underline"
                      >
                        {card?.campaigns_name}
                      </h3>
                    </div>
                  </div>
                  <div className="mt-3 border-b border-gray-300"></div>
                  <div className="flex h-full flex-col justify-between">
                    <div className="mt-3 ">
                      <div className="my-2 flex items-center justify-between">
                        {card?.contact_name && (
                          <div className="flex  items-center gap-2">
                            <MdSegment className="h-5 w-4" />
                            Segment
                          </div>
                        )}
                        {card?.contact_name && (
                          <div className="flex  items-center gap-2">
                            <h3 className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm md:text-lg">
                              {card?.contact_name}
                            </h3>
                          </div>
                        )}
                      </div>
                      <div className="my-2 flex items-center justify-between">
                        {card?.contact_name && (
                          <div className="flex  items-center gap-2">
                            <FaPhone className="h-5 w-4" />
                            Dialer
                          </div>
                        )}
                        {card?.dialer_name && (
                          <div className="flex  items-center gap-2">
                            <h3 className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm md:text-lg">
                              {card?.dialer_name} {" "}({card?.phone_number})
                            </h3>
                          </div>
                        )}
                      </div>
                      <div className="my-2 flex items-center justify-between">
                        {card?.assistant_name && (
                          <div className="flex  items-center gap-2">
                            <MdPeople className="h-5 w-4" />
                            Assistant
                          </div>
                        )}
                        {card?.assistant_name && (
                          <div className="flex  items-center gap-2">
                            <h3 className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm md:text-lg">
                              {card?.assistant_name}
                            </h3>
                          </div>
                        )}
                      </div>
                      {card?.scheduled_at ? (
                        <div className="my-2 flex items-center justify-between">
                          <div className="flex  items-center gap-2">
                            <FaRegCalendar className="h-5 w-4" />
                            {t("campaign.scheduledAt")}
                          </div>
                          <div className="flex  items-center gap-2">
                            <h3 className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm md:text-lg">
                              {formatDateAmPm(card?.scheduled_at)}
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {card?.started_at && (
                            <div className="my-2 flex items-center justify-between">
                              <div className="flex  items-center gap-2">
                                <FaRegCalendar className="h-5 w-4" />
                                {t("campaign.startedAt")}
                              </div>
                              <div className="flex  items-center gap-2">
                                <h3 className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm md:text-lg">
                                  {formatDateAmPm(card?.started_at)}
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {/* {card?.started_at && (
                        <div className="my-2 flex items-center justify-between">
                          <div className="flex  items-center gap-2">
                            <FaRegCalendar className="h-5 w-4" />
                            <h3 className="text-sm md:text-lg xxl:text-xl">
                              Started At
                            </h3>
                          </div>
                          <div className="flex  items-center gap-2">
                            <h3 className="text-sm md:text-lg xxl:text-xl">
                              {formatDateAmPm(card?.started_at)}
                            </h3>
                          </div>
                        </div>
                      )} */}
                      <div className="my-2 flex items-center justify-between">
                        <div className="flex  items-center gap-2">
                          {card?.is_automation ? (
                            <FaCheck className="h-5 w-4" color="green"/>
                          ) : (
                            <IoClose className="h-5 w-4" color="red"/>
                          )}

                          {t("campaign.automation")}
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-3 border-b border-gray-300"></div> */}
                    <div className="mt-3 flex items-center justify-center gap-2 py-2">
                      {card.is_started && !card?.scheduled_at && (
                        <button
                          disabled
                          className="flex items-center justify-center gap-2 rounded border border-gray-500 bg-white px-5 py-2 text-gray-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                        >
                          {t("campaign.completedTxt")}
                        </button>
                      )}
                      {!card.is_started && !card?.scheduled_at && (
                        <button
                          onClick={() => handleNewCpModal(card)}
                          className="flex items-center justify-center gap-2 rounded border border-blue-700 bg-white px-5 py-2 text-blue-700 dark:bg-navy-900"
                        >
                          <MdOutlinePlayCircle className="h-6 w-6 text-blue-700" />
                          {t("campaign.start")}
                        </button>
                      )}
                      {/* {card.is_started && !card?.scheduled_at ? (
                        <button
                          disabled
                          className="flex items-center justify-center gap-2 rounded border border-gray-500 bg-white px-5 py-2 text-gray-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                        >
                          COMPLETED
                        </button>
                      ) : (
                        <button
                          onClick={() => handleNewCpModal(card)}
                          className="flex items-center justify-center gap-2 rounded border border-blue-700 bg-white px-5 py-2 text-blue-700 dark:bg-navy-900"
                        >
                          <MdOutlinePlayCircle className="h-6 w-6 text-blue-700" />
                          START
                        </button>
                      )} */}
                      {!card.is_started && card?.scheduled_at && (
                        <>
                          <button
                            disabled
                            className="flex items-center justify-center gap-2 rounded border border-yellow-500 bg-white px-5 py-2 text-yellow-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                          >
                            {t("campaign.scheduled")}
                          </button>
                          <button
                            onClick={() => handleNewCpModal(card)}
                            className="flex items-center justify-center gap-2 rounded border border-blue-700 bg-white px-5 py-2 text-blue-700 dark:bg-navy-900"
                          >
                            <MdOutlinePlayCircle className="h-6 w-6 text-blue-700" />
                            {t("campaign.startImmediately")}
                          </button>
                        </>
                      )}
                      {card.is_started && card?.scheduled_at && (
                        <button
                          disabled
                          className="flex items-center justify-center gap-2 rounded border border-gray-500 bg-white px-5 py-2 text-gray-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                        >
                          {t("campaign.scheduledCompleted")}
                        </button>
                      )}
                      {/* {card.is_started && card?.scheduled_at ? (
                        <button
                          disabled
                          className="flex items-center justify-center gap-2 rounded border border-gray-500 bg-white px-5 py-2 text-gray-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                        >
                          SCHEDULED COMPLETED
                        </button>
                      ) : (
                        <>
                          {card.is_started && !card?.scheduled_at && (
                            <button
                              disabled
                              className="flex items-center justify-center gap-2 rounded border border-gray-500 bg-white px-5 py-2 text-gray-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                            >
                              COMPLETED
                            </button>
                            :
                            <button
                          onClick={() => handleNewCpModal(card)}
                          className="flex items-center justify-center gap-2 rounded border border-blue-700 bg-white px-5 py-2 text-blue-700 dark:bg-navy-900"
                        >
                          <MdOutlinePlayCircle className="h-6 w-6 text-blue-700" />
                          START
                        </button>
                          )}
                        </>
                      )} */}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
        </div>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        type={t("campaign.campaign")}
      />
      <NewCpModal
        isOpen={isNewCpModal}
        handleClose={handleNewCpClose}
        setRefresh={setRefresh}
        refresh={refresh}
        rowData={rowData}
        campaignsData={campaignsData}
      />
      <AddCampaignsModal
        isOpen={isAddCampaignsModalOpen}
        handleClose={handleAddCampaignsClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default Campaigns;
