/* eslint-disable react-hooks/exhaustive-deps */

import { useColorMode } from "@chakra-ui/system";
import logoDark from "../../assets/img/precallai-log-white.png";
import logoLight from "../../assets/img/precallai-logo.png";
import warningIcon from "../../assets/img/warning-icon.svg";
import { useTranslation } from "react-i18next";
//"services/auth";
const Warning = () => {
    const { colorMode } = useColorMode();
  const { t } = useTranslation();
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="text-center mb-8">
            {colorMode === "dark" ? (
                <img
                    src={logoDark}
                    alt="logo"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "150px",
                        margin: "auto"
                    }}
                    className={`duration-500`}
                />
            ) : (
                <img
                    src={logoLight}
                    alt="logo"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "150px",
                        margin: "auto"
                    }}
                    className={`duration-500`}
                />
            )}
            <div className="mt-10 mb-10">
                <img
                    src={warningIcon}
                    alt="warning"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "200px",
                        margin: "auto"
                    }}
                    className={`duration-500`}
                />
            </div>
        </div>
        <h1 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {t('warning1')}
        </h1>
        <p className="mb-9 ml-1 text-base text-gray-600">
            {t('warning2')}
        </p>
        <p className="mb-9 ml-1 text-base text-gray-600">
            {t('warning3')}
        </p>
        <p className="mb-9 ml-1 text-base text-gray-600">
            <span dangerouslySetInnerHTML={{ __html: t('warning4') }} />
        </p>
      </div>
    </div>
  );
};

export default Warning;
