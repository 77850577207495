import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET Contact LIST

export const getContactList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/contactList?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET All Company LIST

export const getAllCompanyList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/AllCompaniesList?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET All Company LIST

export const getCompanyList = async (id: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/companiesList?cid=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE Company

export const CreateCompany = async (data: any, contactId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/crm/createCompany?cid=${contactId}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update Company

export const updateCompany = async (data: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/crm/updateCompanyDetails?company_id=${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE Company

export const deleteCompany = async (id: any, cid: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/crm/deleteCompany?company_id=${id}&contact_id=${cid}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET All Deal LIST

export const getAllDealsList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/AllDealsList?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Contact Deal LIST

export const getDealsList = async (id: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/dealsList?cid=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
// CREATE Deal

export const CreateDeal = async (data: any, cid: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/crm/createDeal?cid=${cid}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update Deal

export const updateDeal = async (data: any, id: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/crm/updateDeal?deal_id=${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE Deal

export const deleteDeal = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/crm/deleteDeal?deal_id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET All Ticket LIST

export const getAllTicketList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/crm/AllTicketList`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Ticket LIST

export const getContactTicketList = async (id: string) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/contactTicketList?cid=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE Company

export const CreateTicket = async (data: any, cid: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/crm/createContactTicket?cid=${cid}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update Company

export const updateTicket = async (data: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/crm/updateTicket?tid=${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE Company

export const deleteTicket = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/crm/deleteTicket?tid=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET CallLogs LIST

export const getCallLogsList = async (params: any) => {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/campaign/callLogs?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE Note

export const CreateNote = async (data: any, cid: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/crm/createNote?cid=${cid}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Notes LIST

export const getNotesList = async (sid: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/notesList?cid=${sid}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Activity LIST

export const getActivityList = async (cid: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/crm/contact-activity?cid=${cid}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
