//CREATE PHONE NUMBERS
import { errorHandler } from "utils/errorHandler";
import axios, { AxiosRequestConfig } from "axios";
export const createPhoneNumbers = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config: AxiosRequestConfig = {
      method: "POST", // specify the HTTP method you're using
      url: `${baseUrl}/dialer/create`,
      data: request, // assign your payload data to the 'data' property
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        // Add any other headers as needed
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET PHONE NYMBER LIST

export const getPhoneNumbers = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(`${baseUrl}/dialer/list`, config);
    return response.data;
  } catch (error: any) {
    errorHandler(error);
  }
};

// DELETE PHONE NYMBER LIST

export const deletePhoneNumbers = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/dialer/delete?id=${data}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE PHONE NYMBER LIST

export const updatePhoneNumbers = async (request: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };

  try {
    // Make the Axios PUT request
    const response = await axios.put(
      `${baseUrl}/dialer/update?id=${id}`,
      request,
      config
    );

    // Return the response data
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Validate AuthId and Auth Token

export const validateAuth = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config: AxiosRequestConfig = {
      method: "POST", // specify the HTTP method you're using
      url: `${baseUrl}/dialer/validate`,
      data: request, // assign your payload data to the 'data' property
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        // Add any other headers as needed
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};