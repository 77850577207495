/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { loginAsApi } from "../../services/Auth";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { transactionListApi } from "services/pricing";
import { useTranslation } from "react-i18next";
const SignInAs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const newToken = searchParams && searchParams.get("token");

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = () => {
    let isToken = localStorage.getItem("token");
    if (newToken) {
      if (isToken) {
        let themeChange = localStorage.getItem("isThemeChange");
        let themeMode = localStorage.getItem("themeMode");
        localStorage.clear();
        localStorage.setItem("isThemeChange", themeChange);
        localStorage.setItem("themeMode", themeMode);
        if (Array.isArray((window as any).dataLayer)) {
          (window as any).dataLayer.push({
            event: "Logout",
            buttonName: "Logout",
          });
        }
        handleSignIn(newToken);
      } else {
        handleSignIn(newToken);
      }
    } else {
      navigate("/auth/sign-in");
    }
    
  };
  const handleSignIn = (value: any) => {
    setLoading(true);
    let signInPayload = {
      token: value,
    };
    loginAsApi(signInPayload)
      .then((response) => {
        if (response.success) {
          localStorage?.setItem("token", response?.data?.token);
          localStorage?.setItem("fName", response?.data?.first_name);
          localStorage?.setItem("lName", response?.data?.last_name);
          localStorage?.setItem("email", response?.data?.email);
          localStorage?.setItem("amount", response?.data?.amount);
          localStorage?.setItem("steps", response?.data?.steps);
          toast({
            description: t("messages.signIn"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          if (response?.data?.steps === 0) {
            setLoading(false);
            navigate("/auth/onboarding");
          } else {
            fetchTransactionHistory(response?.data?.token);
            setLoading(false);
          }
        } else {
          setLoading(false);
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const fetchTransactionHistory = (token: string) => {
    transactionListApi({ token })
      .then((response: any) => {
        if (response?.success) {
          if (response?.data) {
            const hasSuccessStatus = response?.data.some(
              (obj: any) => obj.status === "success"
            );
            localStorage.setItem("isTransaction", hasSuccessStatus);
          }
          navigate("/admin/dashboard");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#ffffff",
        width: "100%",
        height: "100vh",
        zIndex: 999,
      }}
    >
      {loading && <Loader />}
    </div>
  );
};

export default SignInAs;
