/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Checkbox,
} from "@chakra-ui/react";
import { smtpTemplateList } from "services/integrations";
import Loader from "components/Loader/Loader";
import TemplatePreviewModal from "./TemplatePreviewModal";
import { useTranslation } from "react-i18next";

const EditSendEmailModal = ({
  isOpen,
  handleClose,
  setActionData,
  sendEmailData,
  actionData,
}: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [tempContent, setTempContent] = useState("");
  useEffect(() => {
    fetchSMTPData();
  }, [isOpen]);
  const fetchSMTPData = () => {
    setIsLoading(false);
    smtpTemplateList()
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          setTemplateList(response?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(t("assistantForm.conditionValid")),
    template: Yup.string().required(t("assistantForm.templateValid")),
  });

  const formik = useFormik({
    initialValues: {
      template: sendEmailData?.template || "",
      description: sendEmailData?.description || "",
      subject: sendEmailData?.subject || "",
      end_call_email: sendEmailData?.end_call_email || false,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let sendEmailData = {
        type: "sendMail",
        description: values.description,
        say: "sending you the mail",
        template: values.template,
        subject: values.subject,
        end_call_email: values.end_call_email,
      };
      let deletedData = actionData.filter(
        (item: any) => item.type !== "sendMail"
      );
      setActionData(() => [...deletedData, sendEmailData]);
      handleClose();
    },
  });

  useEffect(() => {
    if (sendEmailData) {
      formik.setFieldValue("template", sendEmailData?.template || "");
      formik.setFieldValue("description", sendEmailData?.description || "");
      formik.setFieldValue("subject", sendEmailData?.subject || "");
      formik.setFieldValue(
        "end_call_email",
        sendEmailData?.end_call_email || false
      );
    }
  }, [sendEmailData]);
  const previewTemp = () => {
    let htmlContent = "";
    if (formik.values.template) {
      for (let item of templateList) {
        if (item?.id === formik.values.template) {
          htmlContent = item?.html_content;
        }
      }
    }
    setTempContent(htmlContent);
    setOpenPreviewModal(true);
  };
  const closePreviewModal = () => {
    setOpenPreviewModal(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"md"}
        blockScrollOnMount={false}
      >
        {isLoading && <Loader />}
        <DrawerOverlay />
        <DrawerContent maxW={"600px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("assistantForm.sendEmail")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormLabel color={"gray"}>
              {t("assistantForm.sendEmailCaption")}
            </FormLabel>
            <FormControl mt={4}>
              <FormLabel
                style={{ display: "flex" }}
                className="flex items-center justify-between"
              >
                {t("assistantForm.template")} *
                {formik.values.template && (
                  <div className="mt-2 flex items-center justify-end">
                    <Button
                      colorScheme="blue"
                      variant="link"
                      className="flex items-center gap-2"
                      onClick={() => previewTemp()}
                    >
                      {t("assistantForm.preview")}
                    </Button>
                  </div>
                )}
              </FormLabel>
              <select
                className="max-h-[150px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                value={formik.values.template}
                onChange={(e) => {
                  if (e.target.value === "") {
                    formik.setFieldValue("end_call_sms", false);
                  }
                  formik.setFieldValue("template", e.target.value);
                }}
                id="template"
                name="template"
              >
                <option value="">{t("assistantForm.selectTemplate")}</option>
                {templateList &&
                  templateList.map((op, i) => (
                    <option key={i} value={op.id}>
                      {op.template_name}
                    </option>
                  ))}
              </select>
              {formik.touched.template && formik.errors.template ? (
                <div className="text-red-500">
                  {formik.errors.template.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("assistantForm.subject")}</FormLabel>
              <input
                className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="subject"
                type="text"
                value={formik.values.subject?.replace(/\\n|\\/g, " ")}
                onChange={(e) => {
                  if (e.target.value === "") {
                    formik.setFieldValue("end_call_sms", false);
                  }
                  formik.setFieldValue("subject", e.target.value);
                }}
                placeholder={t("assistantForm.subjectPlaceholder")}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("assistantForm.conditionText")} *</FormLabel>
              <input
                className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                type="text"
                value={formik.values.description}
                onChange={(e) => {
                  if (e.target.value === "") {
                    formik.setFieldValue("end_call_sms", false);
                  }
                  formik.setFieldValue("description", e.target.value);
                }}
                placeholder={t("assistantForm.conditionTextPlaceholder")}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-500">
                  {formik.errors.description.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <Checkbox
                id="end_call_email"
                colorScheme="cyan"
                onChange={formik.handleChange}
                isChecked={formik.values.end_call_email}
                disabled={
                  !formik.values.template ||
                  !formik.values.subject ||
                  !formik.values.description
                }
              >
                {t("assistantForm.emailCallEnds")}
              </Checkbox>
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={handleClose}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <TemplatePreviewModal
        isOpen={openPreviewModal}
        handleClose={closePreviewModal}
        content={tempContent}
      />
    </>
  );
};

export default EditSendEmailModal;
