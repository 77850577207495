import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET USER AMOUNT
export const getUserAmount = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/user/amount`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// PAYMENT API

export const paymentApi = async (paymentPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/stripe-payment`,
      paymentPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//PAYMENT SUCCESS API

export const paymentSuccess = async (code: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/user/payment-success?code=${code}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// TRANSACTION HISTORY API
interface traninterface {
  token?: string;
}
export const transactionListApi = async ({ token }: traninterface) => {
  let authToken = "";
  if (token) {
    authToken = token;
  } else {
    authToken = localStorage.getItem("token");
  }
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + authToken,
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/user/transactionHistoryList`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// INVOICE DOWNLOAD API

export const invoiceDownloadApi = async (month: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/user/generate-invoice?monthNum=${month}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// APPLY COUPON API

export const applyCoupon = async (couponPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/applyCouponCode`,
      couponPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Subscription Plans API

export const getSubscriptionPlans = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/plans`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Subscription Upgrade API

export const upgradeSubscriptionPlans = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/stripe-subscription/get-subscription-plan`,
      request,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Upgrade Plan API

export const upgradeCurrentPlans = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/stripe-subscription/upgrade`,
      request,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Downgrade Plan API

export const downgradeCurrentPlans = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/stripe-subscription/downgrade`,
      request,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Downgrade Plan API

export const getActivePlanDetil = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/activePlanDetails`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Cancel Plan API

export const cancelCurrentPlan = async (subscriptionId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/stripe-subscription/cancel-subscription?subscription_id=${subscriptionId}`,
      {},
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Transaction Logs API

export const getTransactionLogs = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/stripe-subscription/customer-portal`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
