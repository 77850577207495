import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
function WarningIcon() {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 36 36"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 9.735L29.295 29.25H6.705L18 9.735ZM18 3.75L1.5 32.25H34.5L18 3.75Z"
        fill="#ED6C02"
      />
      <path d="M19.5 24.75H16.5V27.75H19.5V24.75Z" fill="#ED6C02" />
      <path d="M19.5 15.75H16.5V23.25H19.5V15.75Z" fill="#ED6C02" />
    </svg>
  );
}
const SaveConfirmModal = ({ onClose, isOpen, onConfirm, onCancel }: any) => {
  const { t } = useTranslation();
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center gap-2">
            <WarningIcon /> {t("unsaveChanges")}
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="mb-5 mt-5 text-center">{t("wantToLeave")}</div>
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent={"center"} gap={"10px"}>
          <Button
            onClick={onCancel}
            colorScheme="blue"
            className="dark:bg-blue-700 dark:text-white"
          >
            {t("stay")}
          </Button>
          <Button
            onClick={onConfirm}
            colorScheme="red"
            className="dark:bg-red-500-700 dark:text-white"
          >
            {t("leave")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default SaveConfirmModal;
