/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const TemplatePreviewModal = ({ isOpen, handleClose, content }: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const resetAndCloseModal = () => {
    handleClose();
  };
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetAndCloseModal}
        size={"md"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"600px"} className="dark:bg-navy-900">
          <ModalHeader>Preview Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TemplatePreviewModal;
