import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET Dashboard Data

export const getDashboardData = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/dashboard/dashboard-menu`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
