/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";
import { useBlocker } from "react-router-dom";
import { useConfirm } from "./confirm/confirm.hooks";
import { ConfirmOptions } from "./confirm/confirm.types";
import { useTranslation } from "react-i18next";

export const usePrompt = ({
  isDirty = false,
  title = "unsaveChanges",
  subtitle = "wantToLeave",
  confirmText = "leave",
  cancelText = "stay",
  onConfirm,
  onCancel,
  type = "warning",
}: ConfirmOptions & { isDirty?: boolean }) => {
  const { t } = useTranslation();
  const blocker = useBlocker(isDirty);
  const { show } = useConfirm();
  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);
    return new Promise<boolean>((resolve) => {
      show({
        title: t(title),
        subtitle: t(subtitle),
        confirmText: t(confirmText),
        cancelText: t(cancelText),
        type,
        onConfirm: () => {
          resolve(true);
          onConfirm?.();
        },
        onCancel: () => {
          resolve(false);
          onCancel?.();
        },
      });
    });
  }, [
    cancelText,
    confirmText,
    isDirty,
    onCancel,
    onConfirm,
    show,
    subtitle,
    title,
    type,
  ]);
  useEffect(() => {
    if (blocker.state === "blocked") {
      confirm().then((result) => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, confirm]);
  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => t(subtitle);
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, t(subtitle)]);
  return {
    confirm,
  };
};
