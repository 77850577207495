/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import { IoMdAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";

const EditTransferNumberModal = ({
  isOpen,
  handleClose,
  setActionData,
  transferCallData,
  actionData,
}: any) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t("assistantForm.targetMessageValid")),
    description: Yup.string().required(t("assistantForm.descriptionValid")),
    transfer_numbers: Yup.array()
      .of(Yup.string().required(t("assistantForm.transferNumbersValid")))
      .min(1, t("assistantForm.transferNumbersValid1")),
  });

  useEffect(() => {
    if (transferCallData) {
      formik.setValues({
        message: transferCallData?.say,
        transfer_number: "",
        transfer_numbers: transferCallData?.number.split(",") || [""],
        description: transferCallData?.description || "",
      });
    }
  }, [transferCallData]);

  const formik = useFormik({
    initialValues: {
      message: transferCallData?.say,
      description: transferCallData?.description,
      transfer_number: "",
      transfer_numbers: transferCallData?.number.split(","),
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      if (values.transfer_numbers) {
        let mobileNum = values.transfer_numbers;
        for (let ii of mobileNum) {
          const isValidNum = isValidPhoneNumber(ii);
          if (!isValidNum) {
            toast({
              description: t("assistantForm.transferNumValidation"),
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            return;
          }
        }
      }
      // let myContact = localStorage.getItem("phone");
      // if (myContact) {
      //   if (myContact === values.transfer_number) {
      //     toast({
      //       description:
      //         "Transfer number must not be the same as phone number.",
      //       status: "error",
      //       duration: 8000,
      //       isClosable: true,
      //       position: "top-left",
      //     });
      //     return;
      //   }
      // }
      let transferNumberData = {
        type: "transferCall",
        number: values.transfer_numbers.join(),
        description: values.description,
        say: values.message,
      };
      let deletedData = actionData.filter(
        (item: any) => item.type !== "transferCall"
      );
      setActionData(() => [...deletedData, transferNumberData]);
      handleClose();
    },
  });
  const addPhoneNumbers = () => {
    formik.setFieldValue("transfer_numbers", [
      ...formik.values.transfer_numbers,
      formik.values.transfer_number,
    ]);
    formik.setFieldValue("transfer_number", "");
  };
  const removePhoneNumbers = (index: any) => {
    formik.setFieldValue(
      "transfer_numbers",
      formik.values.transfer_numbers.filter((_: any, i: any) => i !== index)
    );
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"md"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"600px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("assistantForm.editCallTransfer")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl>
              <FormLabel>{t("assistantForm.transferNumber")} *</FormLabel>
              <div className="mb-3 flex items-center gap-x-2">
                <PhoneInput
                  inputStyle={{
                    background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                    color: colorMode === "dark" ? "white" : "black",
                  }}
                  dropdownStyle={{
                    background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                    color: colorMode === "dark" ? "white" : "black",
                  }}
                  inputProps={{
                    name: "transferNumber",
                    id: "transferNumber",
                    required: true,
                    className: `${
                      !formik.values?.isTransfer ? "dark:bg-gray-900 " : ""
                    } pl-[50px] border-gray-500 h-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] items-center gap-x-2 rounded border border-gray-200 focus:border-blue-500 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-[700] focus:bg-white focus:outline-none`,
                  }}
                  buttonStyle={{
                    borderRadius: "8px",
                  }}
                  country={"us"}
                  onChange={(value, country, event, formattedValue) => {
                    let myPhone = convertToE164(formattedValue);
                    formik.setFieldValue("transfer_number", myPhone);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.transfer_number}
                />
                <Button colorScheme="blue" onClick={() => addPhoneNumbers()}>
                  <IoMdAddCircle className="h-7 w-7" />
                </Button>
              </div>
              <div className="mb-3 flex flex-wrap items-center gap-x-2">
                {formik.values?.transfer_numbers?.map((op: any, i: any) => (
                  <div
                    key={i}
                    className="mb-2 flex flex-wrap items-center gap-3 rounded border-[1px] border-gray-500  bg-gray-200 px-2 py-2 dark:border-gray-700 dark:bg-slate-900 dark:text-white"
                  >
                    {op}{" "}
                    <MdDelete
                      color="red"
                      className="cursor-pointer"
                      onClick={() => removePhoneNumbers(i)}
                    />
                  </div>
                ))}
              </div>
              {formik.touched?.transfer_numbers &&
              formik.errors?.transfer_numbers ? (
                <div className="text-red-500">
                  {formik.errors?.transfer_numbers.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("assistantForm.description")} *</FormLabel>
              <input
                className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                type="text"
                value={formik.values.description?.replace(/\\n|\\/g, " ")}
                onChange={formik.handleChange}
                placeholder={t("assistantForm.descriptionPlaceholder")}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-500">
                  {formik.errors.description.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("assistantForm.reachTarget")} *</FormLabel>
              <input
                className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="message"
                type="text"
                value={formik.values.message}
                onChange={formik.handleChange}
                placeholder={t("assistantForm.reachTargetPlaceholder")}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="text-red-500">
                  {formik.errors.message.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              type="submit"
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={handleClose}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditTransferNumberModal;
