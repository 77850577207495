import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// CREATE Support ticket

export const CreateSupportTicket = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/supportTicket/createTicket`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update Support ticket

export const updateSupportTicket = async (data: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/supportTicket/ticketReply?id=${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Ticket LIST

export const getTecketList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/supportTicket/ticketList`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Ticket Details With Reply GET API

export const TicketDetailsWithReply = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/supportTicket/ticketDetailsWithReply?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
