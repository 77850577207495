/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import Card from "components/card";
import {
  UpdateSmtpConfiguration,
  smtpConfiguration,
} from "services/integrations";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
const SmtpSetup = () => {
  // smtpConfiguration
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchSMTPData();
  }, []);

  const validationSchema = Yup.object().shape({
    smtp_host: Yup.string().required(t("integrations.smtp.SMTPhostValid")),
    smtp_port: Yup.number().required(t("integrations.smtp.SMTPportValid")),
    smtp_username: Yup.string()
      .email(t("integrations.smtp.emailValid1"))
      .required(t("integrations.smtp.emailValid")),
    smtp_password: Yup.string().required(t("integrations.smtp.passwordValid")),
  });

  const formik = useFormik({
    initialValues: {
      smtp_host: "",
      smtp_port: "",
      smtp_username: "",
      smtp_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      // console.log(values);
      let payload = {
        smtp_host: values?.smtp_host,
        smtp_port: values?.smtp_port,
        smtp_username: values?.smtp_username,
        smtp_password: values?.smtp_password,
        smtp_encryption: false,
      };
      setIsLoading(true);
      UpdateSmtpConfiguration(payload)
        .then((response: any) => {
          setIsLoading(false);
          if (response.success) {
            fetchSMTPData();
            resetForm();
            toast({
              description: t("messages.smtpConfigurationUpdated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error submitting feedback:", error);
        });
    },
  });
  const fetchSMTPData = () => {
    setIsLoading(true);
    smtpConfiguration()
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          if (response?.data && response?.data.length > 0) {
            formik.setFieldValue("smtp_host", response?.data[0]?.smtp_host);
            formik.setFieldValue("smtp_port", response?.data[0]?.smtp_port);
            formik.setFieldValue(
              "smtp_username",
              response?.data[0]?.smtp_username
            );
            formik.setFieldValue(
              "smtp_password",
              response?.data[0]?.smtp_password
            );
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  return (
    <div className="m-auto max-w-[900px]">
      {isLoading && <Loader />}
      <Card extra={"w-full h-full p-[16px] bg-cover"}>
        <FormControl>
          <FormLabel>{t("integrations.smtp.SMTPhost")} *</FormLabel>
          <input
            className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
            id="smtp_host"
            type="text"
            value={formik.values.smtp_host}
            onChange={formik.handleChange}
          />
          {formik.touched.smtp_host && formik.errors.smtp_host ? (
            <div className="text-red-500">
              {formik.errors.smtp_host.toString()}
            </div>
          ) : null}
        </FormControl>

        <FormControl mt={4}>
          <FormLabel>{t("integrations.smtp.SMTPport")} *</FormLabel>
          <input
            className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
            id="smtp_port"
            type="number"
            value={formik.values.smtp_port}
            onChange={formik.handleChange}
          />
          {formik.touched.smtp_port && formik.errors.smtp_port ? (
            <div className="text-red-500">
              {formik.errors.smtp_port.toString()}
            </div>
          ) : null}
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>{t("integrations.smtp.email")} *</FormLabel>
          <input
            className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
            id="smtp_username"
            type="email"
            value={formik.values.smtp_username}
            onChange={formik.handleChange}
          />
          {formik.touched.smtp_username && formik.errors.smtp_username ? (
            <div className="text-red-500">
              {formik.errors.smtp_username.toString()}
            </div>
          ) : null}
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>{t("integrations.smtp.password")} *</FormLabel>
          <input
            className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
            id="smtp_password"
            type="password"
            value={formik.values.smtp_password}
            onChange={formik.handleChange}
          />
          {formik.touched.smtp_password && formik.errors.smtp_password ? (
            <div className="text-red-500">
              {formik.errors.smtp_password.toString()}
            </div>
          ) : null}
        </FormControl>
        <div className="mt-4">
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
            className="dark:bg-blue-700 dark:text-white"
          >
            {t("save")}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SmtpSetup;
