import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// CONNECT AUDIANCE

export const connectConnecter = async (type: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/auth/authUrl?provider=${type}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
// CONNECT AUDIANCE CODE

export const connectConnecterCode = async (
  code: any,
  type: any,
  userId: any
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "POST",
      url: `${baseUrl}/connectors/auth/callback?code=${code}&provider=${type}&user_id=${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CONNECTOR LIST

export const connectorList = async (userId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/connectorsList?user_id=${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CONNECTOR REMOVE

export const connectorRemove = async (provider: string, userId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/removeConnector?provider=${provider}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SMTP Templates LIST

export const smtpTemplateList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/smtp/smtp-list`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SMTP Template Create

export const CreateSmtpTemplate = async (formData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/smtp/smtp-integration`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
// SMTP Template Update

export const UpdateSmtpTemplate = async (formData: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/smtp/smtp-update?id=${id}`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SMTP Template File Delete

export const DeleteSmtpTemplateFile = async (req: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/smtp/deleteAttachmentFromS3?temp_id=${id}`,
      req,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
// SMTP Template Delete

export const DeleteSmtpTemplate = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/smtp/smtp-delete?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SMTP Configurations

export const smtpConfiguration = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/smtp/smtpconfig-list`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Update SMTP Configurations

export const UpdateSmtpConfiguration = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/smtp/smtpconfig-create-update`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// WhatsApp Configurations

export const whatsAppConfiguration = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/whatsapp/whatsapp-config`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Send WhatsApp Configurations

export const createWhatsAppConfiguration = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/whatsapp/whatsapp-config`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Update WhatsApp Configurations

export const updateWhatsAppConfiguration = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/whatsapp/whatsapp-update`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// whatsapp Delete

export const deleteWhatsAppConfig = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
    data,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/whatsapp/whatsapp-delete`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// whatsapp Verify

export const whatsAppVerify = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/whatsapp/whatsapp-verify`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Whatsapp Templates

export const getWhatsAppTemp = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/whatsapp/template-list`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GHL calenders

export const getGHLcalenders = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/go-high-level/get-calenders`,
      config
    );
    return response.data;
  } catch (error) {
    // errorHandler(error);
  }
};

// ElevenLab data

export const getElevenlabs = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/elevenlabs`, config);
    return response.data;
  } catch (error) {
    // errorHandler(error);
  }
};

// Send Elevenlabs Configurations

export const createElevenlabsConfiguration = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(`${baseUrl}/elevenlabs`, data, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// ElevenLab Voices

export const getElevenlabsVoices = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  try {
    const response = await axios.get(
      `${baseUrl}/elevenlabs/voices?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// ElevenLab Voice Detail

export const getElevenlabsVoiceDetail = async (voiceId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/elevenlabs/voice?voice_id=${voiceId}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Send ElevenLab Voice

export const sendElevenlabsVoice = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  try {
    const response = await axios.get(
      `${baseUrl}/elevenlabs/voice?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Get Default ElevenLab Voice

export const getDefaultElevenlabsVoice = async (apiKey: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/elevenlabs/default?api_key=${apiKey}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Cartesia data

export const getCartesia = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/cartesia`, config);
    return response.data;
  } catch (error) {
    // errorHandler(error);
  }
};

// Send Cartesia Configurations

export const createCartesiaConfiguration = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(`${baseUrl}/cartesia`, data, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Cartesia Voices

export const getCartesiaVoices = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  try {
    const response = await axios.get(
      `${baseUrl}/cartesia/voices?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// ElevenLab Delete

export const deleteElevenLabConfig = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/elevenlabs?id=${data?.id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// Cartesia Delete

export const deleteCartesiaConfig = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.delete(
      `${baseUrl}/cartesia?id=${data?.id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
