/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import { useColorMode } from "@chakra-ui/system";
import SaveConfirmModal from "./SaveConfirmModal";
import Loader from "components/Loader/Loader";
import {
  DeleteSmtpTemplateFile,
  UpdateSmtpTemplate,
} from "services/integrations";
import { useDropzone } from "react-dropzone";
import { SlCloudUpload } from "react-icons/sl";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "./ConfirmModal";
import { useTranslation } from "react-i18next";
const modules = {
  toolbar: false,
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const SMTPEditModal = ({
  isOpen,
  handleClose,
  rowData,
  refresh,
  setRefresh,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();
  // const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [isFocused, setIsFocused] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteUrl, setIsDeleteUrl] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const validationSchema = Yup.object().shape({
    template_name: Yup.string().required(
      t("integrations.smtp.templateNameValid")
    ),
    subject: Yup.string().required(t("integrations.smtp.subjectValid")),
    from_name: Yup.string().required(t("integrations.smtp.fromNameValid")),
    from_email: Yup.string()
      .email(t("integrations.smtp.emailValid1"))
      .required(t("integrations.smtp.fromToValid")),
    reply_to: Yup.string()
      .email(t("integrations.smtp.emailValid1"))
      .required(t("integrations.smtp.replyToValid")),
    html_content: Yup.string().required(t("integrations.smtp.contentValid")),
  });

  const formik = useFormik({
    initialValues: {
      template_name: rowData?.template_name || "",
      subject: rowData?.subject || "",
      from_name: rowData?.from_name || "",
      from_email: rowData?.from_email || "",
      reply_to: rowData?.reply_to || "",
      html_content: rowData?.html_content || "",
      url: rowData?.attachment || "",
      file: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("template_name", values.template_name);
      formData.append("subject", values.subject);
      formData.append("from_name", values.from_name);
      formData.append("from_email", values.from_email);
      formData.append("reply_to", values.reply_to);
      formData.append("html_content", values.html_content);
      formData.append("url", isDeleteUrl ? "" : values.url);
      formData.append("file", values.file);
      UpdateSmtpTemplate(formData, rowData?.id)
        .then((response: any) => {
          setIsLoading(false);
          if (response.success) {
            setRefresh(!refresh);
            resetForm();
            handleClose();
            toast({
              description: t("messages.smtpTempUpdated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
    },
  });
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    if (rowData && isOpen) {
      formik.setValues({
        template_name: rowData?.template_name || "",
        subject: rowData?.subject || "",
        from_name: rowData?.from_name || "",
        from_email: rowData?.from_email || "",
        reply_to: rowData?.reply_to || "",
        html_content: rowData?.html_content || "",
        url: rowData?.attachment || "",
        file: "",
      });
    }
  }, [rowData, isOpen]);
  useEffect(() => {
    if (isConfirm) {
      deleteSmtpFile();
    }
  }, [isConfirm]);

  const deleteSmtpFile = () => {
    let req = {
      url: rowData?.attachment,
    };
    setIsLoading(true);
    DeleteSmtpTemplateFile(req, rowData?.id)
      .then((response: any) => {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
        setIsConfirm(false);
        if (response.success) {
          setIsDeleteUrl(true);
          formik.setFieldValue("url", "");
          toast({
            description: response.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsConfirm(false);
        console.error("Error:", error);
      });
  };
  const checkIsDirtyForm = () => {
    let initialValue = {
      template_name: rowData?.template_name || "",
      subject: rowData?.subject || "",
      from_name: rowData?.from_name || "",
      from_email: rowData?.from_email || "",
      reply_to: rowData?.reply_to || "",
      html_content: rowData?.html_content || "",
      url: rowData?.attachment || "",
      file: "",
    };
    let isDirty =
      JSON.stringify(initialValue) !== JSON.stringify(formik?.values);
    if (isDirty) {
      openWarningModal();
    } else {
      handleCloseModal();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleCloseModal();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
  };
  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("file", acceptedFiles[0]);
    },
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "application/zip": [".zip"],
      "application/x-rar-compressed": [".rar"],
    },
    maxSize: 10 * 1024 * 1024,
  });
  const quillClass =
    colorMode === "dark" ? "my-react-quill-dark" : "my-react-quill";

  return (
    <>
      {isLoading && <Loader />}
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        size={"md"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("integrations.smtp.editTemplate")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl>
              <FormLabel>{t("integrations.smtp.templateName")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="template_name"
                type="text"
                value={formik.values.template_name}
                onChange={formik.handleChange}
              />
              {formik.touched.template_name && formik.errors.template_name ? (
                <div className="text-red-500">
                  {formik.errors.template_name.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.subject")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="subject"
                type="text"
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
              {formik.touched.subject && formik.errors.subject ? (
                <div className="text-red-500">
                  {formik.errors.subject.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.fromName")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="from_name"
                type="text"
                value={formik.values.from_name}
                onChange={formik.handleChange}
              />
              {formik.touched.from_name && formik.errors.from_name ? (
                <div className="text-red-500">
                  {formik.errors.from_name.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.fromEmail")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="from_email"
                type="email"
                value={formik.values.from_email}
                onChange={formik.handleChange}
              />
              {formik.touched.from_email && formik.errors.from_email ? (
                <div className="text-red-500">
                  {formik.errors.from_email.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.replyTo")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="reply_to"
                type="email"
                value={formik.values.reply_to}
                onChange={formik.handleChange}
              />
              {formik.touched.reply_to && formik.errors.reply_to ? (
                <div className="text-red-500">
                  {formik.errors.reply_to.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.emailBody")} *</FormLabel>
              <ReactQuill
                theme="snow"
                value={formik.values.html_content}
                onChange={(value: any) => {
                  formik.setFieldValue("html_content", value);
                }}
                modules={modules}
                formats={formats}
                className={`${quillClass} train-quill propmt-quill h-full max-h-[215px] min-h-[215px] w-full overflow-auto rounded bg-white text-[16px] focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:text-white md:max-h-[396px] md:min-h-[396px] lg:max-h-[280px] lg:min-h-[280px]`}
                id="html_content"
                placeholder="Enter email body"
                onFocus={handleFocus}
                onBlur={handleBlur}
                style={{
                  border: isFocused ? "1px solid #14bfff" : "1px solid #adb5bd",
                }}
              />
              {formik.touched.html_content && formik.errors.html_content ? (
                <div className="text-red-500">
                  {formik.errors.html_content.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("integrations.smtp.attachment")}</FormLabel>

              {formik.values?.url ? (
                <div className="flex w-full items-center justify-center">
                  <label
                    htmlFor="dropzone-file"
                    className="flex  h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100  dark:bg-navy-900 "
                  >
                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                      <div className="flex items-center justify-between p-4">
                        <p className="mb-2 text-sm font-semibold text-gray-500 ">
                          {formik.values?.url || ""}
                        </p>
                        <MdDelete
                          onClick={() => {
                            setIsConfirmModalOpen(true);
                          }}
                          color="red"
                          className="h-6 w-6 cursor-pointer text-red-600"
                        />
                      </div>
                    </div>
                  </label>
                </div>
              ) : (
                <section>
                  <div {...getRootProps()}>
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="flex  h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100  dark:bg-navy-900 "
                      >
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                          {formik.values?.file ? (
                            <div>
                              <p className="mb-2 text-sm font-semibold text-gray-500 ">
                                {formik.values?.file?.name}
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <span className="mb-4  font-bold text-gray-500">
                                <SlCloudUpload className="h-16 w-16" />
                              </span>
                              <p className="mb-2 text-sm font-semibold text-gray-500 ">
                                {t("integrations.smtp.uploadFile")}
                              </p>
                              <p className="text-xs text-gray-500 ">
                                {t("integrations.smtp.dragDrop")}
                              </p>
                            </div>
                          )}
                        </div>
                        <input {...getInputProps()} />
                      </label>
                    </div>
                  </div>
                </section>
              )}
            </FormControl>
          </DrawerBody>
          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("integrations.smtp.update")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        setIsConfirm={setIsConfirm}
        type={t("integrations.smtp.attachment")}
      />
    </>
  );
};

export default SMTPEditModal;
