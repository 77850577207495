import axios from "axios";
import { errorHandler } from "utils/errorHandler";

// CREATE CONTACT

export const CreateContact = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "POST",
      url: `${baseUrl}/segment/create`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET CONTACT LIST

export const getContactList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/segment/list?${queryString}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE CONTACTLIST

export const updateContactList = async (payload: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "PUT",
      url: `${baseUrl}/segment/update?id=${id}`,
      data: payload,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE CONTACT LIST

export const deleteContactList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "DELETE",
      url: `${baseUrl}/segment/delete?id=${data}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE AUdIANCE

export const createAudience = async (request: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "POST",
      url: `${baseUrl}/segment/contact/create?id=${id}`,
      data: request,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
// GET CONTACT CAMPAIGN
export const getContactCampaign = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/segment/campaigns?id=${data}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET AUdIANCE

export const getAudienceList = async (data: any) => {
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/segment/contact/list?${queryString}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE AUdIANCE

export const deleteAudience = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "DELETE",
      url: `${baseUrl}/segment/contact/delete?id=${data?.id}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE AUdIANCE

export const updateAudience = async (payload: any, contact_id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const config = {
      method: "PUT",
      url: `${baseUrl}/segment/contact/update?id=${contact_id}`,
      data: payload,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE AUdIANCE

export const uploadCsvAudience = async (files: any, audienceId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const formData = new FormData();
  formData.append("file", files[0]);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
  };
  const config = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/segment/contact/bulk-upload?id=${audienceId}`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CONNECT AUDIANCE

export const connectAudience = async (type: any, contactId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/linkWithConnector?provider=${type}&segment_id=${contactId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// RESYNC AUDIANCE

export const resyncAudience = async (type: any, contactId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/leadReSync?provider=${type}&segment_id=${contactId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CONNECTOR LIST

export const connectorList = async (contactId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/contactConnectorsList?segment_id=${contactId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CONNECTOR REMOVE

export const connectorRemove = async (provider: string, contactId: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const config = {
      method: "GET",
      url: `${baseUrl}/connectors/removeContactConnector?provider=${provider}&segment_id=${contactId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
