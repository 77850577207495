import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET Campaign LIST

export const getCampaignList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/campaign/list`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE CAMPAIGN

export const createCampaign = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/campaign/create`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// START CAMPAIGN

export const startCampaign = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/campaign/start?id=${data}`,
      null,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET Campaign Voice assistant LIST

export const getCampaignVoiceList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/user/voices`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET CAMPAIGN AUdIANCE

export const getCampaignAudienceList = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");
  try {
    const config = {
      method: "POST",
      url: `${baseUrl}/campaign/audiences?${queryString}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET CAMPAIGN AUdIANCE

export const campaignFavourite = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const config = {
      method: "POST",
      url: `${baseUrl}/campaign/doFav`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
