//useCallback
import React, { useEffect, useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useToast } from "@chakra-ui/react";
import { googleLogin } from "services/Auth";
import { useNavigate } from "react-router-dom";
import { transactionListApi } from "services/pricing";

const GoogleAuth = ({ setLoading, login, affiliate_id }: any) => {
  const toast = useToast();
  const navigate = useNavigate();
  const clientId =
    "123196386728-puflqobugfh64ls0enkfgmqffbr9bsts.apps.googleusercontent.com";

  const [buttonWidth, setButtonWidth] = useState("500px");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setButtonWidth(width > 450 ? "500px" : "250px");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Login with Google",
        buttonName: "Login with Google",
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const gtagReportConversion = useCallback((url?: string): boolean => {
  //   const callback = () => {
  //     if (typeof url !== 'undefined') {
  //       window.location.href = url;
  //     }
  //   };

  //   (window as any).gtag('event', 'conversion', {
  //     'send_to': 'AW-16575539394/4mDTCJLLgLQZEMLR6t89',
  //     'event_callback': callback,
  //   });

  //   return false;
  // }, []);

  const submitGoogleLogin = (credentialResponse: any) => {
    googleLogin(credentialResponse, affiliate_id)
      .then((response) => {
        if (response?.success) {
          localStorage?.setItem("token", response?.data?.token);
          localStorage?.setItem("amount", response?.data?.amount);
          localStorage?.setItem("steps", response?.data?.steps);
          toast({
            description: response?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          if (response?.data?.steps === 0) {
            setLoading(false);
            // gtagReportConversion('https://www.precallai.com');
            navigate("/auth/onboarding");
          } else {
            fetchTransactionHistory(response?.data?.token);
            setLoading(false);
          }
        } else {
          setLoading(false);
          toast({
            description: response?.message,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const fetchTransactionHistory = (token: string) => {
    transactionListApi({ token })
      .then((response: any) => {
        if (response?.success) {
          if (response?.data) {
            const hasSuccessStatus = response?.data.some(
              (obj: any) => obj.status === "success"
            );
            localStorage.setItem("isTransaction", hasSuccessStatus);
          }
          navigate("/admin/dashboard");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div style={{ colorScheme: "light" }}>
        <GoogleLogin
          size="large"
          width={buttonWidth}
          shape="circle"
          text={login ? "signin_with" : "signup_with"}
          onSuccess={(credentialResponse) => {
            setLoading(true);
            submitGoogleLogin(credentialResponse);
          }}
          onError={() => {
            toast({
              description: "Login Failed",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
