import ReactDOM from "react-dom/client";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { AppConfigProvider } from "AppConfigProvider";
import { resources } from './resources'; 
// Check if a language is stored in localStorage
const storedLanguage = localStorage.getItem("language");

// Detect browser language if no language is stored in localStorage
const browserLanguage = navigator.language.split("-")[0]; // Extract language code
const supportedLanguages = ["en", "es", "fr", "de"]; // Add your supported languages here

// Set the language in i18n
if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
  i18n.use(initReactI18next).init({
    resources,
    lng: storedLanguage, // Default language
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
  });
} else if (supportedLanguages.includes(browserLanguage)) {
  i18n.use(initReactI18next).init({
    resources,
    lng: browserLanguage, // Default language
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
  });
  localStorage.setItem("language", browserLanguage); // Optionally store the detected language
} else {
  i18n.use(initReactI18next).init({
    resources,
    lng: "en", // Default language
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
  });
}

// i18n.use(initReactI18next).init({
//   resources,
//   lng: savedLanguage, // Default language
//   interpolation: {
//     escapeValue: false, // Not needed for React as it escapes by default
//   },
// });
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GTMProvider
    state={{
      id: "GTM-MQV7HP82",
    }}
  >
    <ChakraProvider>
      <AppConfigProvider>
        <App />
      </AppConfigProvider>
    </ChakraProvider>
  </GTMProvider>
);
