import React, { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { SlCloudUpload } from "react-icons/sl";
import { MdOutlineCloudDownload } from "react-icons/md";
import { uploadCsvAudience } from "services/contact";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { isValidPhoneNumber } from "utils/utils";
import { useTranslation } from "react-i18next";
const UploadContactModal = ({
  isOpen,
  handleClose,
  audienceId,
  setRefresh,
  refresh,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();
  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href = `/assets/SampleAudienceList.csv`;
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const requiredHeaders = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "description",
  ];
  const handleFileUpload = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result as string;
        parseCSV(fileContent, acceptedFiles);
      };
      reader.readAsText(file);
    });
  };
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateData = (data: any[]) => {
    // Example validation logic for each row
    for (const row of data) {
      if (!row.first_name) {
        toast({
          description: t("audiences.fnameValidation"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return false;
      }
      if (!row.last_name) {
        toast({
          description: t("audiences.lnameValidation"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return false;
      }
      if (!row.phone) {
        toast({
          description: t("audiences.phoneValidation"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return false;
      }
      // Additional specific validation rules can be added here
      if (row.email) {
        if (!validateEmail(row.email)) {
          toast({
            description: `${row.email} ${t(
              "audiences.emailValidationInUploadFile"
            )}`,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return false;
        }
      }
      if (row.phone) {
        const isValidNum = isValidPhoneNumber(row.phone);
        if (!isValidNum) {
          toast({
            description: `${row.phone} ${t(
              "audiences.phoneValidationInUploadFile"
            )}`,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return false;
        }
      }
    }
    return true;
  };
  const parseCSV = (fileContent: string, acceptedFiles: File[]) => {
    Papa.parse(fileContent, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        const data = results.data;
        const headers = results.meta.fields;

        if (validateHeaders(headers)) {
          // Further validation of CSV data can be done here
          if (validateData(data)) {
            uploadCsvAudience(acceptedFiles, audienceId)
              .then((response) => {
                if (response.success) {
                  setRefresh(!refresh);
                  toast({
                    description: response?.message,
                    status: "success",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                  });
                  handleClose();
                } else {
                  toast({
                    description: response?.message,
                    status: "info",
                    duration: 8000,
                    isClosable: true,
                    position: "top-left",
                  });
                  handleClose();
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        } else {
          toast({
            description: t("audiences.invalidCSVHeaders"),
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      },
      error: (error: any) => {
        console.error("Error parsing CSV file:", error);
        toast({
          description: t("audiences.errorParsing"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
      },
    });
  };
  const validateHeaders = (headers: string[] | undefined) => {
    if (!headers) return false;
    return requiredHeaders.every((header) => headers.includes(header));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
    },
    maxSize: 10 * 1024 * 1024,
  });
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalHeader>{t("audiences.uploadContacts")}</ModalHeader>
          <ModalCloseButton />
          <div className="w-full border-b border-gray-500"></div>
          <ModalBody pb={6}>
            <div className="w-full">
              <div className="mt-2 flex items-center justify-end pt-2">
                <button
                  onClick={handleDownloadSample}
                  className="flex items-center justify-center gap-2 rounded border border-blue-700  bg-white px-5 py-2 text-blue-700 dark:bg-navy-900 "
                >
                  <MdOutlineCloudDownload className="h-6 w-6" />
                  {t("audiences.downloadSample")}
                </button>
              </div>
              {/* <div className="mt-4 w-full rounded border border-gray-300 py-4 shadow-inner">
                <div className="flex items-center justify-center">
                  <SlCloudUpload className="h-16 w-16 text-blue-700" />
                </div>
                <div className="mt-2 flex flex-col items-center justify-center gap-1 py-2">
                  <h1 className="text-3xl font-medium">Upload CSV File</h1>
                  <p>
                    Upload your file in same formate as in above sample file
                  </p>
                </div>
                <div className="mt-6 flex items-center justify-center py-2">
                  <label
                    htmlFor="fileInput"
                    className="flex cursor-pointer items-center justify-center gap-2 rounded border  border-blue-700 bg-white px-5 py-2 text-blue-700 dark:bg-navy-900"
                  >
                    <SlCloudUpload className="h-6 w-6" />
                    UPLOAD FILE
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".csv"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </div>
              </div> */}
              <div className="mt-4">
                <section>
                  <div {...getRootProps()}>
                    <div className="flex w-full items-center justify-center">
                      <label
                        htmlFor="dropzone-file"
                        className="flex  h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100  dark:bg-navy-900 "
                      >
                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                          <span className="mb-4  font-bold text-gray-500">
                            <SlCloudUpload className="h-16 w-16" />
                          </span>
                          <p className="mb-2 text-sm font-semibold text-gray-500 ">
                            {t("audiences.uploadFileText")}
                          </p>
                          <p className="text-xs text-gray-500 ">
                            {t("audiences.dragDrop")}
                          </p>
                        </div>
                        <input {...getInputProps()} />
                      </label>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadContactModal;
