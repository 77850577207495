/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button, useToast } from "@chakra-ui/react";
// import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
import ContactDetailComponent from "components/contactDetailComponent";
import { getContactDetail } from "services/playground";

const ContactDetailModal = ({
  onClose,
  isOpen,
  contactNo
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  useEffect(() => {
    if (isOpen) {
        let req = {
            phone_number: contactNo
        }
        fetchContactDetail(req);
    }
  }, [isOpen]);
const fetchContactDetail = (params: any) => {
    setLoading(true);
    getContactDetail(params)
      .then((response) => {
        if (response.success) {
          let allData: any = response?.data;
          setContactDetail(allData);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const handleCloseModal = () => {
    onClose();
    setContactDetail(null);
  };
  return (
    <div>
      {/* {loading && <Loader />} */}
      <Drawer
        placement="right"
        onClose={handleCloseModal}
        size={"md"}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent
          borderRadius={8}
          maxW="700px"
          className="dark:bg-navy-900"
        >
          <DrawerHeader fontSize={24} fontWeight={500}>
            {t("contactDetails")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody padding={"0px 24px 20px 24px"}>
            <div className="mb-[32px] mt-4">
                <Card extra={"w-full sm:overflow-auto px-6"}>
                    {
                        contactDetail && contactDetail?.contact &&
                        <ContactDetailComponent contactData={contactDetail} />
                    }
                    {
                        !contactDetail && !loading &&
                        <div
                        style={{
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginTop: "50px",
                            marginBottom: "50px",
                        }}
                        >
                        {t("noRecordFound")}
                        </div>
                    }
                </Card>
            </div>
          </DrawerBody>
          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button onClick={handleCloseModal}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default ContactDetailModal;
