import React, { useRef } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { BsTelephoneForwardFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ConfirmRedailCallModal = ({
  isOpen,
  handleClose,
  setIsRedialConfirm,
  playgroundData,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  let phoneNumber = playgroundData?.phone_number;
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div className="mt-6 flex flex-col items-center justify-center">
              <BsTelephoneForwardFill className="mb-8 text-[40px] text-blue-700" />
              <p className="mb-4 text-[#000000]  dark:text-white">
                {t("playground.redialCall", { phoneNumber })}
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                setIsRedialConfirm(true);
                handleClose();
              }}
              colorScheme="blue"
              mr={3}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("yes")}
            </Button>
            <Button onClick={handleClose}>{t("cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmRedailCallModal;
