/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../../services/Auth";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import Footer from "components/footer/FooterAuthDefault";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const code = searchParams && searchParams.get("code");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const steps = localStorage.getItem("steps");
  useEffect(() => {
    if (token && Number(steps) > 0) {
      navigate("/admin/dashboard");
    }
  }, []);
  useEffect(() => {
    if (!code) {
      navigate("/auth/sign-in");
    }
  }, [code]);
  const resetInitialValues = {
    password: "",
    confirm_password: "",
  };
  const resetValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t("passwordValidation"))
      .required(t("passwordRequired")),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwordMatchValidation"))
      .required(t("confirmPasswordRequired")),
  });
  const handleReset = (values: any) => {
    let resetPasswordPayload = {
      password: values.password,
    };
    setLoading(true);
    resetPassword(resetPasswordPayload, code)
      .then((response) => {
        if (response.success) {
          toast({
            description: t("messages.resetPassword"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          navigate("/auth/sign-in");
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {loading && <Loader />}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {t("resetPassword.resetPasswordHeading")}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {" "}
          {t("resetPassword.resetPasswordCaption")}
        </p>
        <Formik
          initialValues={resetInitialValues}
          validationSchema={resetValidationSchema}
          onSubmit={(values: any) => {
            handleReset(values);

            if (Array.isArray((window as any).dataLayer)) {
              (window as any).dataLayer.push({
                event: "Reset-password",
                buttonName: "Reset-password",
              });
            }
          }}
        >
          <Form>
            <InputField
              variant="auth"
              name="password"
              extra="mb-3"
              label={t("password") + "*"}
              placeholder={t("passwordPlaceholder")}
              id="password"
              type="password"
            />
            <InputField
              variant="auth"
              name="confirm_password"
              extra="mb-3"
              label={t("confirmPassword") + "*"}
              placeholder={t("passwordPlaceholder")}
              id="confirm_password"
              type="password"
            />

            <button
              type="submit"
              className="linear mt-2 w-full rounded bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
            >
              {t("resetPassword.resetPasswordText")}
            </button>
          </Form>
        </Formik>
        <div className="mt-5 flex items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
