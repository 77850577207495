/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Card from "components/card";
import { MdEdit, MdDelete, MdPermContactCalendar } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import AddAudianceModal from "components/modal/AddAudianceModal";
import EditAudianceModal from "components/modal/EditAudianceModal";
import { SlCloudUpload } from "react-icons/sl";
import UploadContactModal from "components/modal/UploadContactModal";
import ghl_icon from "../../../../../assets/img/ghl_icon.svg";
import zohoIcon from "../../../../../assets/svg/zoho-logo-web.svg";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  getContactCampaign,
  getAudienceList,
  deleteAudience,
  connectorList,
  connectAudience,
  resyncAudience,
  connectorRemove,
} from "services/contact";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FaArrowLeft } from "react-icons/fa6";
import { Alert, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
import Loader from "components/Loader/Loader";
import ApiModal from "components/modal/ApiModal";
import Dropdown from "components/dropdown";
import { FaChevronDown, FaCopy, FaHubspot, FaSalesforce } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { IoMdCreate, IoMdSync } from "react-icons/io";
import { formatDateAmPm, responseHendler } from "utils/utils";
import TooltipHorizon from "components/tooltip";
import * as XLSX from "xlsx";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
type RowObj = {
  full_name: string;
  phone: string;
  email: string;
  job_title: string;
  lifecycle_stage: string;
  lead_status: string;
  description: any;
  sync_by: string;
  actions: any;
};

const AudianceList = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAudianceModalOpen, setIsAudianceModalOpen] = useState(false);
  const [rowData, setRowData] = useState<RowObj | null>(null);
  const [connectorListData, setConnectorListData] = useState(null);
  const [isRequest, setIsRequest] = useState(true);
  const [syncLoading, setSyncLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [contactCampaign, setContactCampaign] = useState("");
  const [openApiModal, setOpenApiModal] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [isOpenRemoveConnectorConfirm, setIsOpenRemoveConnectorConfirm] =
    useState(false);
  const [connectorTypeSet, setConnectorTypeSet] = useState("");
  // const [isCloseHubspot, setIsCloseHubspot] = useState(false);
  // const [isCloseSalesForce, setIsCloseSalesForce] = useState(false);
  const contactData = JSON.parse(localStorage.getItem("contactData"));
  const audienceId = location?.state?.rowData?.id || contactData?.id;

  useEffect(() => {
    if (audienceId) {
      getContactCampaign(audienceId)
        .then((response) => {
          setIsRequest(false);
          responseHendler(response);
          if (response.success) {
            if (response.data) {
              setContactCampaign(
                `Please note that this audience list is currently being utilized in ${response.data} active campaigns. Any additions made here will automatically apply to your ongoing campaign roster. Proceed with caution to ensure consistency and relevance across all your campaigns.`
              );
              let activeCampaigns = response.data;
              setContactCampaign(
                t("audiences.activeCampaignsMsg", { activeCampaigns })
              );
            }
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [audienceId]);

  const fetchData = useCallback(() => {
    const params = {
      id: audienceId,
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchAudienceList(params);
  }, [pagination?.pageIndex, pagination?.pageSize, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (tableData?.length === 1 && pagination?.pageIndex !== 0) {
      setPagination({
        pageIndex: pagination?.pageIndex - 1,
        pageSize: 10,
      });
    }
    const params = {
      id: audienceId,
      page:
        tableData?.length === 1 && pagination?.pageIndex !== 0
          ? pagination?.pageIndex - 1
          : pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchAudienceList(params);
    fetchConnectorList(audienceId);
  }, [audienceId, refresh]);
  const fetchAudienceList = (params: any) => {
    setIsRequest(true);
    getAudienceList(params)
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          const itemsWithFullName = response?.data.map((item: any) => ({
            ...item,
            full_name: `${item.first_name} ${item.last_name}`,
          }));
          setTableData(itemsWithFullName);
          setTotalPages(response?.totalPages);
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  };
  const handleSearch = (value: any) => {
    // const value = e.target.value;
    setSearchQuery(value);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    // if (tableDataAll) {
    //   const filteredData = tableDataAll.filter(
    //     (item: any) =>
    //       typeof item?.first_name === "string" &&
    //       item?.first_name?.toLowerCase()?.includes(value.toLowerCase())
    //   );
    //   setTableData(filteredData);
    // }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (rowData: RowObj) => {
    setRowData(rowData);
    setIsModalOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: RowObj) => {
    setRowData(rowData);
    setIsConfirmModalOpen(true);
    setIsConfirm(false);
  };

  const handleAudianceClose = () => {
    setIsAudianceModalOpen(false);
  };

  const handleAudianceModal = () => {
    setIsAudianceModalOpen(true);
  };

  const handleUploadClose = () => {
    setUploadModalOpen(false);
  };

  const handleUploadModal = () => {
    setUploadModalOpen(true);
  };

  useEffect(() => {
    if (isConfirm === true) {
      deleteAudience(rowData)
        .then((response) => {
          if (response.success) {
            handleConfirmClose();
            setIsRequest(false);
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            handleConfirmClose();
            setIsRequest(false);
            setRefresh(!refresh);
          } else {
            toast({
              description: response?.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchConnectorList = (audienceId: string) => {
    connectorList(audienceId)
      .then((response: any) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          setConnectorListData(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsRequest(false);
      });
  };

  const handleApiModal = () => {
    setOpenApiModal(true);
    // window.open(
    //   "https://docs.precallai.com/api-reference/contacts/createAudience",
    //   "_blank"
    // );
  };
  const handleApiCloseModal = () => {
    setOpenApiModal(false);
  };
  const handleConnect = (type: any) => {
    localStorage.setItem("connectorType", type);
    setIsRequest(true);
    connectAudience(type, audienceId)
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          // window.location.href = response?.data;
          setRefresh(!refresh);
          fetchConnectorList(audienceId);
          // navigate("/admin/contact-list/audiance");
          toast({
            description: `${
              type === "hubspot"
                ? t("integrations.integ.hubspot")
                : type === "salesforce"
                ? t("integrations.integ.salesforce")
                : type === "gohighlevel"
                ? t("integrations.integ.GoHighLevel")
                : t("zoho")
            } ${t("messages.connectedConnector")}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsRequest(false);
      });
  };
  const handleSync = (type: any) => {
    localStorage.setItem("connectorType", type);
    setSyncLoading(true);
    resyncAudience(type, audienceId)
      .then((response) => {
        setSyncLoading(false);
        responseHendler(response);
        if (response.success) {
          setRefresh(!refresh);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSyncLoading(false);
      });
  };
  const handleCloseConnectorConfirm = () => {
    setIsOpenRemoveConnectorConfirm(false);
  };
  const handleDeleteConnector = (status: string) => {
    setConnectorTypeSet(status);
    setIsOpenRemoveConnectorConfirm(true);
  };
  const removeConnector = () => {
    setIsRequest(true);
    connectorRemove(connectorTypeSet, audienceId)
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          setRefresh(!refresh);
          toast({
            description: `${
              connectorTypeSet === "hubspot"
                ? t("integrations.integ.hubspot")
                : connectorTypeSet === "salesforce"
                ? t("integrations.integ.salesforce")
                : connectorTypeSet === "gohighlevel"
                ? t("integrations.integ.GoHighLevel")
                : t("zoho")
            } ${t("messages.removeConnector")}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsRequest(false);
      });
  };
  // const closeHub = () => {
  //   setIsCloseHubspot(true);
  // };
  // const closeSales = () => {
  //   setIsCloseSalesForce(true);
  // };
  const downloadCSV = () => {
    const headers = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "sync_by",
      "description",
    ];

    const data = tableData.map((item: any) => [
      item?.first_name,
      item?.last_name,
      item?.email,
      item?.phone,
      item?.sync_by,
      item?.description,
    ]);
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${location?.state?.rowData?.name || contactData?.name}.csv`,
      {
        bookType: "csv",
      }
    );
  };
  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("full_name", {
      id: "Name",
      header: () => <CustomHeader headerText={t("audiences.name")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.row.original.full_name ? (
            <span>{info.row.original.full_name}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "Phone Number",
      header: () => <CustomHeader headerText={t("audiences.phone")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.row.original.phone ? (
            <span>{info.row.original.phone}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("email", {
      id: "Email",
      header: () => <CustomHeader headerText={t("audiences.email")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.row.original.email ? (
            <span>{info.row.original.email}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("job_title", {
      id: "Job Title",
      header: () => <CustomHeader headerText={t("audiences.jobTitle")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.row.original.job_title ? (
            <span>{info.row.original.job_title}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("lifecycle_stage", {
      id: "Lifecycle Stage",
      header: () => <CustomHeader headerText={t("audiences.lifecycle")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.row.original.lifecycle_stage ? (
            <span>{info.row.original.lifecycle_stage}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("lead_status", {
      id: "Lead Status",
      header: () => <CustomHeader headerText={t("audiences.leadStatus")} />,
      cell: (info) => (
        <p className="flex items-center justify-end gap-2 sm:justify-center">
          {info.row.original.lead_status ? (
            <span>{info.row.original.lead_status}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("description", {
      id: "Description",
      header: () => <CustomHeader headerText={t("audiences.description")} />,
      cell: (info: any) => (
        <TooltipHorizon
          trigger={
            <div className="m-auto max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap sm:justify-center md:text-center">
              {info.getValue() || "-"}
            </div>
          }
          placement="top"
          content={info.getValue()}
          extra="rounded text-[11px]"
        />
      ),
    }),
    columnHelper.accessor("sync_by", {
      id: "Sync By",
      header: () => <CustomHeader headerText={t("audiences.syncBy")} />,
      cell: (info) => (
        <p className="flex items-center justify-end gap-2 sm:justify-center">
          {info.row.original.sync_by ? (
            <span>{info.row.original.sync_by}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "Actions",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("audiences.actions")}
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center justify-end gap-4 sm:justify-center">
          <MdEdit
            onClick={() => handleModal(info.row.original)}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
          <MdDelete
            onClick={() => handleConfirmModal(info.row.original)}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data: tableData,
    columns: columns,
    state: {
      pagination,
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const goBack = () => {
    navigate("/admin/segments");
  };
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const connectortype = localStorage.getItem("connectorType");
  return (
    <div>
      {isRequest && <Loader />}
      <div className="mb-2">
        <div className="mt-2">
          {contactCampaign && <Alert status="warning">{contactCampaign}</Alert>}
        </div>
        <div className="mt-2 flex flex-wrap justify-end gap-4 py-2">
          <div className="flex w-full items-center rounded-md border border-blue-700 p-2 md:w-max">
            {t("segmentId")} :
            <div className="whitespace-nowrap px-6 font-medium text-gray-900 dark:text-white">
              {/* {item?.auth_key} */}
              {audienceId?.slice(0, -4).replace(/./g, "•") +
                audienceId?.slice(-4)}
              <span className="pl-5 md:pl-20">
                <button
                  className="text-blue-700"
                  onClick={() => navigator.clipboard.writeText(audienceId)}
                >
                  <TooltipHorizon
                    trigger={
                      <span>
                        <FaCopy />
                      </span>
                    }
                    placement="top"
                    content={t("assistantForm.copyText")}
                    extra="rounded text-[11px]"
                  />
                </button>
              </span>
            </div>
          </div>
          <button
            onClick={() => goBack()}
            className="flex items-center justify-center gap-2  rounded bg-blue-700 px-3 py-3 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
          <SearchBarInput
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => handleUploadModal()}
            className="flex items-center justify-center gap-2  rounded bg-blue-700 px-3 py-3 text-white hover:bg-blue-800"
          >
            <SlCloudUpload className="h-6 w-6" />
            {t("audiences.uploadBulk")}
          </button>
          {tableData && tableData?.length > 0 && (
            <button
              onClick={() => downloadCSV()}
              className="flex items-center justify-center gap-2  rounded bg-blue-700 px-3 py-3 text-white hover:bg-blue-800"
            >
              <IoCloudDownloadOutline className="h-6 w-6" />
              {t("audiences.exportCSV")}
            </button>
          )}
          <Dropdown
            button={
              <button className="flex h-full min-h-10 items-center justify-center gap-2  rounded bg-blue-700 px-4 py-0 text-white hover:bg-blue-800">
                {t("audiences.create")} <FaChevronDown className="h-4 w-4" />
              </button>
            }
            classNames={"py-2 top-8 right-[0px] w-max"}
          >
            {(closeDropdown) => (
              <div className="my-5 grid grid-cols-1 gap-5 rounded bg-white p-4 dark:bg-gray-900 sm:grid-cols-2">
                <div
                  className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                  onClick={handleAudianceModal}
                >
                  <IoMdCreate className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.addManually")}
                </div>
                <div
                  className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                  onClick={handleApiModal}
                >
                  <FaCode className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.addAPI")}
                </div>
                {connectorListData?.hubspot ? (
                  <div className="relative rounded border border-green-400 p-2 text-center font-semibold text-green-400">
                    {connectorListData?.hubspot && (
                      <MdDelete
                        className={`absolute right-8 top-1 h-6 w-6 cursor-pointer text-red-700`}
                        onClick={() => handleDeleteConnector("hubspot")}
                      />
                    )}
                    {/* {connectorListData?.hubspot?.error && (
                      <IoMdClose
                        className={`absolute right-0 top-0 h-6 w-6 ${
                          syncLoading &&
                          connectortype === "hubspot" &&
                          "animate-spin"
                        } cursor-pointer text-red-700`}
                        onClick={() => closeHub()}
                      />
                    )} */}
                    {!connectorListData?.hubspot?.error && (
                      <IoMdSync
                        className={`absolute right-0 top-1 h-6 w-6 ${
                          syncLoading &&
                          connectortype === "hubspot" &&
                          "animate-spin"
                        } cursor-pointer text-blue-700`}
                        onClick={() => handleSync("hubspot")}
                      />
                    )}
                    <FaHubspot
                      className={`m-auto mb-2 h-6 w-6 ${
                        connectorListData?.hubspot?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }`}
                    />
                    <span
                      className={
                        connectorListData?.hubspot?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }
                    >
                      {t("audiences.linkedHub")}
                    </span>
                    {connectorListData?.hubspot?.error ? (
                      <div className="max-w-[300px] text-[14px] text-red-700 dark:text-red-700">
                        {connectorListData?.hubspot?.error}
                      </div>
                    ) : (
                      <>
                        {connectorListData?.hubspot?.connector_last_sync_at && (
                          <div className="text-[14px] text-gray-700 dark:text-gray-400">
                            {t("audiences.lastSync")} :{" "}
                            {formatDateAmPm(
                              connectorListData?.hubspot?.connector_last_sync_at
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                    onClick={() => handleConnect("hubspot")}
                  >
                    <FaHubspot className="m-auto mb-2 h-6 w-6" />
                    {t("audiences.linkHub")}
                  </div>
                )}
                {connectorListData?.salesForce ? (
                  <div className="relative rounded border border-green-400 p-2 text-center font-semibold text-green-400">
                    {connectorListData?.salesForce && (
                      <MdDelete
                        className={`absolute right-8 top-1 h-6 w-6 cursor-pointer text-red-700`}
                        onClick={() => handleDeleteConnector("salesforce")}
                      />
                    )}
                    {/* {connectorListData?.salesForce?.error && (
                      <IoMdClose
                        className={`absolute right-0 top-0 h-6 w-6 ${
                          syncLoading &&
                          connectortype === "salesforce" &&
                          "animate-spin"
                        } cursor-pointer text-red-700`}
                        onClick={() => closeSales()}
                      />
                    )} */}
                    {!connectorListData?.salesForce?.error && (
                      <IoMdSync
                        className={`${
                          syncLoading &&
                          connectortype === "salesforce" &&
                          "animate-spin"
                        } absolute right-0 top-1 h-6 w-6 cursor-pointer text-blue-700`}
                        onClick={() => handleSync("salesforce")}
                      />
                    )}
                    <FaSalesforce
                      className={`m-auto mb-2 h-6 w-6 ${
                        connectorListData?.salesForce?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }`}
                    />
                    <span
                      className={
                        connectorListData?.salesForce?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }
                    >
                      {t("audiences.linkedSales")}
                    </span>
                    {/* <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      last sync :{" "}
                      {formatDateAmPm(
                        connectorListData?.salesForce?.connector_last_sync_at
                      )}
                    </div> */}
                    {connectorListData?.salesForce?.error ? (
                      <div className="max-w-[300px] text-[14px] text-red-700 dark:text-red-700">
                        {connectorListData?.salesForce?.error}
                      </div>
                    ) : (
                      <>
                        {connectorListData?.salesForce
                          ?.connector_last_sync_at && (
                          <div className="text-[14px] text-gray-700 dark:text-gray-400">
                            {t("audiences.lastSync")} :{" "}
                            {formatDateAmPm(
                              connectorListData?.salesForce
                                ?.connector_last_sync_at
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                    onClick={() => handleConnect("salesforce")}
                  >
                    <FaSalesforce className="m-auto mb-2 h-6 w-6" />
                    {t("audiences.linkSales")}
                  </div>
                )}
                {connectorListData?.goHighLevel ? (
                  <div className="relative rounded border border-green-400 p-2 text-center font-semibold text-green-400">
                    {connectorListData?.goHighLevel && (
                      <MdDelete
                        className={`absolute right-8 top-1 h-6 w-6 cursor-pointer text-red-700`}
                        onClick={() => handleDeleteConnector("gohighlevel")}
                      />
                    )}
                    {/* {connectorListData?.salesForce?.error && (
                      <IoMdClose
                        className={`absolute right-0 top-0 h-6 w-6 ${
                          syncLoading &&
                          connectortype === "salesforce" &&
                          "animate-spin"
                        } cursor-pointer text-red-700`}
                        onClick={() => closeSales()}
                      />
                    )} */}
                    {!connectorListData?.goHighLevel?.error && (
                      <IoMdSync
                        className={`${
                          syncLoading &&
                          connectortype === "gohighlevel" &&
                          "animate-spin"
                        } absolute right-0 top-1 h-6 w-6 cursor-pointer text-blue-700`}
                        onClick={() => handleSync("gohighlevel")}
                      />
                    )}
                    <img
                      src={ghl_icon}
                      alt="Go high level logo"
                      className="m-auto mb-2 h-[24px] w-[24px]"
                    />
                    <span
                      className={
                        connectorListData?.goHighLevel?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }
                    >
                      {t("audiences.linkedGHL")}
                    </span>
                    {/* <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      last sync :{" "}
                      {formatDateAmPm(
                        connectorListData?.salesForce?.connector_last_sync_at
                      )}
                    </div> */}
                    {connectorListData?.goHighLevel?.error ? (
                      <div className="max-w-[300px] text-[14px] text-red-700 dark:text-red-700">
                        {connectorListData?.goHighLevel?.error}
                      </div>
                    ) : (
                      <>
                        {connectorListData?.goHighLevel
                          ?.connector_last_sync_at && (
                          <div className="text-[14px] text-gray-700 dark:text-gray-400">
                            {t("audiences.lastSync")} :{" "}
                            {formatDateAmPm(
                              connectorListData?.goHighLevel
                                ?.connector_last_sync_at
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                    onClick={() => handleConnect("gohighlevel")}
                  >
                    <img
                      src={ghl_icon}
                      alt="Go high level logo"
                      className="m-auto mb-2 h-[24px] w-[24px]"
                    />
                    {t("audiences.linkGHL")}
                  </div>
                )}
                {connectorListData?.zoho ? (
                  <div className="relative rounded border border-green-400 p-2 text-center font-semibold text-green-400">
                    {connectorListData?.zoho && (
                      <MdDelete
                        className={`absolute right-8 top-1 h-6 w-6 cursor-pointer text-red-700`}
                        onClick={() => handleDeleteConnector("zoho")}
                      />
                    )}
                    {/* {connectorListData?.salesForce?.error && (
                      <IoMdClose
                        className={`absolute right-0 top-0 h-6 w-6 ${
                          syncLoading &&
                          connectortype === "salesforce" &&
                          "animate-spin"
                        } cursor-pointer text-red-700`}
                        onClick={() => closeSales()}
                      />
                    )} */}
                    {!connectorListData?.zoho?.error && (
                      <IoMdSync
                        className={`${
                          syncLoading &&
                          connectortype === "zoho" &&
                          "animate-spin"
                        } absolute right-0 top-1 h-6 w-6 cursor-pointer text-blue-700`}
                        onClick={() => handleSync("zoho")}
                      />
                    )}
                    <img
                      src={zohoIcon}
                      alt="Zoho logo"
                      className="m-auto mb-2 h-[24px] w-[24px]"
                    />
                    <span
                      className={
                        connectorListData?.zoho?.error
                          ? "text-red-700"
                          : "text-green-400"
                      }
                    >
                      {t("zohoLinked")}
                    </span>
                    {/* <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      last sync :{" "}
                      {formatDateAmPm(
                        connectorListData?.salesForce?.connector_last_sync_at
                      )}
                    </div> */}
                    {connectorListData?.zoho?.error ? (
                      <div className="max-w-[300px] text-[14px] text-red-700 dark:text-red-700">
                        {connectorListData?.zoho?.error}
                      </div>
                    ) : (
                      <>
                        {connectorListData?.zoho?.connector_last_sync_at && (
                          <div className="text-[14px] text-gray-700 dark:text-gray-400">
                            {t("audiences.lastSync")} :{" "}
                            {formatDateAmPm(
                              connectorListData?.zoho?.connector_last_sync_at
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer rounded border border-blue-700 p-2 text-center font-semibold"
                    onClick={() => handleConnect("zoho")}
                  >
                    <img
                      src={zohoIcon}
                      alt="Zoho logo"
                      className="m-auto mb-2 h-[24px] w-[24px]"
                    />
                    {t("zohoLink")}
                  </div>
                )}
              </div>
            )}
          </Dropdown>
        </div>
      </div>
      {tableData && tableData?.length === 0 && !isRequest ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("audiences.noAudience")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("audiences.getStarted")}
            </p>
            <div className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div
                className="cursor-pointer rounded border border-blue-700 p-5 text-center font-semibold"
                onClick={handleAudianceModal}
              >
                <IoMdCreate className="m-auto mb-2 h-6 w-6" />
                {t("audiences.addManually")}
              </div>
              <div
                className="cursor-pointer rounded border border-blue-700 p-4 text-center font-semibold"
                onClick={handleApiModal}
              >
                <FaCode className="m-auto mb-2 h-6 w-6" />
                {t("audiences.addAPI")}
              </div>
              {connectorListData?.hubspot &&
              !connectorListData?.hubspot?.error ? (
                <div className="relative rounded border border-green-400 p-5 text-center font-semibold text-green-400">
                  <MdDelete
                    className={`absolute right-10 top-2 h-6 w-6 cursor-pointer text-red-700`}
                    onClick={() => handleDeleteConnector("hubspot")}
                  />
                  <IoMdSync
                    className={`${
                      syncLoading &&
                      connectortype === "hubspot" &&
                      "animate-spin"
                    } absolute right-2 top-2 h-6 w-6 cursor-pointer text-blue-700`}
                    onClick={() => handleSync("hubspot")}
                  />
                  <FaHubspot className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.linkedHub")}
                  {connectorListData?.hubspot?.connector_last_sync_at && (
                    <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      {t("audiences.lastSync")} :{" "}
                      {formatDateAmPm(
                        connectorListData?.hubspot?.connector_last_sync_at
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="cursor-pointer rounded border border-blue-700 p-5 text-center font-semibold"
                  onClick={() => handleConnect("hubspot")}
                >
                  <FaHubspot className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.linkHub")}
                </div>
              )}
              {connectorListData?.salesForce &&
              !connectorListData?.salesForce?.error ? (
                <div className="relative rounded border border-green-400 p-5 text-center font-semibold text-green-400">
                  <MdDelete
                    className={`absolute right-10 top-2 h-6 w-6 cursor-pointer text-red-700`}
                    onClick={() => handleDeleteConnector("salesforce")}
                  />
                  <IoMdSync
                    className={`absolute right-2 top-2 h-6 w-6 cursor-pointer text-blue-700 ${
                      syncLoading &&
                      connectortype === "salesforce" &&
                      "animate-spin"
                    }`}
                    onClick={() => handleSync("salesforce")}
                  />
                  <FaSalesforce className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.linkedSales")}
                  {connectorListData?.salesForce?.connector_last_sync_at && (
                    <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      {t("audiences.lastSync")} :{" "}
                      {formatDateAmPm(
                        connectorListData?.salesForce?.connector_last_sync_at
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="cursor-pointer rounded border border-blue-700 p-5 text-center font-semibold"
                  onClick={() => handleConnect("salesforce")}
                >
                  <FaSalesforce className="m-auto mb-2 h-6 w-6" />
                  {t("audiences.linkSales")}
                </div>
              )}
              {connectorListData?.goHighLevel &&
              !connectorListData?.goHighLevel?.error ? (
                <div className="relative rounded border border-green-400 p-5 text-center font-semibold text-green-400">
                  <MdDelete
                    className={`absolute right-10 top-2 h-6 w-6 cursor-pointer text-red-700`}
                    onClick={() => handleDeleteConnector("gohighlevel")}
                  />
                  <IoMdSync
                    className={`absolute right-2 top-2 h-6 w-6 cursor-pointer text-blue-700 ${
                      syncLoading &&
                      connectortype === "gohighlevel" &&
                      "animate-spin"
                    }`}
                    onClick={() => handleSync("gohighlevel")}
                  />
                  {/* <FaSalesforce className="m-auto mb-2 h-6 w-6" /> */}
                  <img
                    src={ghl_icon}
                    alt="Go high level logo"
                    className="m-auto mb-2 h-[24px] w-[24px]"
                  />
                  {t("audiences.linkedGHL")}
                  {connectorListData?.goHighLevel?.connector_last_sync_at && (
                    <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      {t("audiences.lastSync")} :{" "}
                      {formatDateAmPm(
                        connectorListData?.goHighLevel?.connector_last_sync_at
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="cursor-pointer rounded border border-blue-700 p-5 text-center font-semibold"
                  onClick={() => handleConnect("gohighlevel")}
                >
                  {/* <FaSalesforce className="m-auto mb-2 h-6 w-6" /> */}
                  <img
                    src={ghl_icon}
                    alt="Go high level logo"
                    className="m-auto mb-2 h-[24px] w-[24px]"
                  />
                  {t("audiences.linkGHL")}
                </div>
              )}
              {connectorListData?.zoho && !connectorListData?.zoho?.error ? (
                <div className="relative rounded border border-green-400 p-5 text-center font-semibold text-green-400">
                  <MdDelete
                    className={`absolute right-10 top-2 h-6 w-6 cursor-pointer text-red-700`}
                    onClick={() => handleDeleteConnector("zoho")}
                  />
                  <IoMdSync
                    className={`absolute right-2 top-2 h-6 w-6 cursor-pointer text-blue-700 ${
                      syncLoading && connectortype === "zoho" && "animate-spin"
                    }`}
                    onClick={() => handleSync("zoho")}
                  />
                  {/* <FaSalesforce className="m-auto mb-2 h-6 w-6" /> */}
                  <img
                    src={zohoIcon}
                    alt="Zoho logo"
                    className="m-auto mb-2 h-[24px] w-[24px]"
                  />
                  {t("zohoLinked")}
                  {connectorListData?.zoho?.connector_last_sync_at && (
                    <div className="text-[14px] text-gray-700 dark:text-gray-400">
                      {t("audiences.lastSync")} :{" "}
                      {formatDateAmPm(
                        connectorListData?.zoho?.connector_last_sync_at
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="cursor-pointer rounded border border-blue-700 p-5 text-center font-semibold"
                  onClick={() => handleConnect("zoho")}
                >
                  {/* <FaSalesforce className="m-auto mb-2 h-6 w-6" /> */}
                  <img
                    src={zohoIcon}
                    alt="Zoho logo"
                    className="m-auto mb-2 h-[24px] w-[24px]"
                  />
                  {t("zohoLink")}
                </div>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto pb-4">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        scope="col"
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.setPageIndex(0)}
              disabled={pagination?.pageIndex === 0}
            >
              {"<<"}
            </button>
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <div className="flex items-center gap-2">
              <span>
                {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                {t("of")} {totalPages}
              </span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
                className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900 dark:text-white"
              />
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.setPageIndex(totalPages - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Card>
      )}
      <AddAudianceModal
        isOpen={isAudianceModalOpen}
        handleClose={handleAudianceClose}
        audienceId={audienceId}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <ApiModal
        isOpen={openApiModal}
        handleClose={handleApiCloseModal}
        audienceId={audienceId}
      />
      <EditAudianceModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        rowData={rowData}
        setTableData={setTableData}
        setRefresh={setRefresh}
        refresh={refresh}
        reference="audiance"
        contactData={contactData}
      />
      <UploadContactModal
        isOpen={isUploadModalOpen}
        handleClose={handleUploadClose}
        audienceId={audienceId}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        rowData={rowData}
        setIsConfirm={setIsConfirm}
        type={t("audiences.audience")}
      />
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpenRemoveConnectorConfirm}
        onClose={handleCloseConnectorConfirm}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div className="mt-6 flex flex-col items-center justify-center">
              <svg
                className="mx-auto mb-3.5 h-11 w-11 text-red-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-[#000000]  dark:text-white">
                {t("audiences.removeConnector")}
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                removeConnector();
                handleCloseConnectorConfirm();
              }}
              colorScheme="blue"
              mr={3}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("yes")}
            </Button>
            <Button onClick={handleCloseConnectorConfirm}>{t("cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AudianceList;
const columnHelper = createColumnHelper<RowObj>();
