/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "components/Loader/Loader";
import Card from "components/card";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CallDurationTotal from "components/callDurationTotal";
import { formatDateAmPm, numberWithCommasAndDollar } from "utils/utils";
import {
  getInboundAssitantHistory,
  getInboundAssitantList,
} from "services/inboundAssitant";
import { Tag, useToast } from "@chakra-ui/react";
import CallTranscribeModal from "components/modal/CallTranscribeModal";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
import { getPhoneNumbers } from "services/phoneNumbers";
import ContactDetailModal from "components/modal/ContactDetailModal";
type RowObj = {
  from_number: any;
  call_duration_in_sec: any;
  call_cost: any;
  created_at: string;
  recording_url: string;
  call_sentiment: any;
  call_transcribe: any;
  action: any;
};
const IncomingCallLogs = () => {
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialerList, setDialerList] = useState([]);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callTranscribe, setCallTranscribe] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDialer, setSelectedDialer] = useState("");
  const [selectedDialerName, setSelectedDialerName] = useState("");
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedIncomingCall, setSelectedIncomingCall] = useState("");
  useEffect(() => {
    fetchDataFromApis();
  }, []);
  const fetchDataFromApis = async () => {
    try {
      // Call first API
      const firstData = await getPhoneNumbers();

      // Call second API after the first one completes
      const secondData = await getInboundAssitantList();
      if (firstData && firstData?.status === 401) {
        navigate("/auth/sign-in");
      }
      if (firstData && firstData?.success) {
        if (firstData?.data) {
          for (let item of firstData?.data) {
            for (let inb of secondData?.data) {
              if (item.id === inb.dialer_id) {
                item.incoming_count = inb.incoming_count;
                item.assistant_id = inb.assistant_id;
                item.inboundId = inb.id;
              }
            }
          }
          if (firstData?.data && firstData?.data.length > 0) {
            setSelectedDialer(firstData?.data[0]?.inboundId);
            setSelectedDialerName(firstData?.data[0]?.name);
            setDialerList(firstData?.data);
          }
        }
      }
      if (firstData && !firstData?.success) {
        toast({
          description: firstData?.message,
          status: "info",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors here
    }
  };
  const fetchData = useCallback(() => {
    if (selectedDialer) {
      const params = {
        id: selectedDialer,
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        search: searchQuery,
      };
      fetchInboundHistory(params);
    }
  }, [
    pagination?.pageIndex,
    pagination?.pageSize,
    searchQuery,
    selectedDialer,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchInboundHistory = (params: any) => {
    setLoading(true);
    getInboundAssitantHistory(params)
      .then((response) => {
        if (response.success) {
          let allData: any = response?.data;
          allData.sort(function (a: any, b: any) {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          setIncomingCalls(allData);
          setTotalPages(response?.totalPages || 0);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const openTranscribeModal = (data: any) => {
    setCallTranscribe(data);
    setIsModalOpen(true);
  };
  const handleCloseTranscribe = () => {
    setIsModalOpen(false);
  };
  const handleSearch = (e: any) => {
    setSearchQuery(e);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("from_number", {
      id: "From Number",
      header: () => <CustomHeader headerText={t("incomingCalls.FromNumber")} />,
    }),
    columnHelper.accessor("call_duration_in_sec", {
      id: "Duration",
      header: () => <CustomHeader headerText={t("incomingCalls.duration")} />,
    }),
    columnHelper.accessor("call_cost", {
        id: "Cost",
        header: () => <CustomHeader headerText={t("cost")} />,
    }),
    columnHelper.accessor("created_at", {
      id: "CalledAt",
      header: () => <CustomHeader headerText={t("incomingCalls.calledAt")} />,
    }),
    columnHelper.accessor("recording_url", {
      id: "Recording",
      header: () => <CustomHeader headerText={t("incomingCalls.recording")} />,
    }),
    columnHelper.accessor("call_sentiment", {
      id: "Sentiments",
      header: () => <CustomHeader headerText={t("incomingCalls.sentiments")} />,
    }),
    columnHelper.accessor("action", {
      id: "Action",
      header: () => <CustomHeader headerText={""} />,
    }),
  ];
  const table = useReactTable({
    data: incomingCalls,
    columns: columns,
    state: {
      sorting,
      pagination,
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    // manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const goToContactDetailPage = (data: any) => {
    setSelectedIncomingCall(data?.from_number);
    setContactModalOpen(true);
  }
  const handleCloseContact = () => {
    setContactModalOpen(false);
  }
  // const doManualCalling = (item: any) => {};
  return (
    <div>
      {loading && <Loader />}

      <div>
        <div className="mb-2">
          <div className="mb-3 mt-4 flex flex-col items-center justify-between gap-2 md:flex-row">
            <div>
              <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
                {t("incomingCalls.incomingCalls")} (
                {selectedDialerName})
              </span>
            </div>
            <div className="flex flex-col justify-end gap-4 sm:flex-row">
              <select
                value={selectedDialer}
                onChange={(e) => setSelectedDialer(e.target.value)}
                className="h-[50px] w-full rounded border-[1px] border-gray-500 p-2 text-[18px] leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                {dialerList?.map((item: any, index: number) => (
                  <option key={index} value={item?.inboundId}>
                    {item?.name} ({item?.phone_number})
                  </option>
                ))}
              </select>
              <SearchBarInput
                initialData={searchQuery}
                handleFilteredData={handleSearch}
              />
            </div>
          </div>
        </div>
        {incomingCalls && (
          <Card extra={"w-full sm:overflow-auto px-6"}>
            <div className="mt-1 overflow-x-auto">
              <table className="responsive-table w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="!border-px !border-gray-400"
                    >
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            scope="col"
                            className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                          >
                            <div className="items-center justify-between text-xs text-gray-200">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows &&
                    table.getRowModel().rows.map((op, i) => (
                      <tr key={op.index}>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={t("incomingCalls.FromNumber")}
                        >
                          <p
                            onClick={() => goToContactDetailPage(op?.original)}
                            className="flex cursor-pointer items-center justify-end gap-2 text-blue-700 hover:underline sm:justify-center"
                          >
                            {op?.original.from_number ? (
                              <span>{op?.original.from_number}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </td>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={t("incomingCalls.duration")}
                        >
                          <p className="flex items-center justify-end gap-2 sm:justify-center">
                            <CallDurationTotal
                              totalDurationInSeconds={Number(
                                op?.original?.call_duration_in_sec || 0
                              )}
                            />
                          </p>
                        </td>
                        <td
                            className="min-w-[150px] border-white/0 py-3 pr-4"
                            data-label={t("cost")}
                            >
                            <p className="flex items-center justify-end gap-2 sm:justify-center">
                                <span>
                                {numberWithCommasAndDollar(Number(op?.original.call_cost) || 0)}
                                </span>
                            </p>
                        </td>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={t("incomingCalls.calledAt")}
                        >
                          <p className="flex items-center justify-end gap-2 text-center sm:justify-center">
                            {formatDateAmPm(op?.original?.created_at)}
                          </p>
                        </td>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={t("incomingCalls.recording")}
                        >
                          <div className="min-w-[200px] text-center">
                            {op?.original?.recording_url ? (
                              <WavePlayer
                                audio={
                                  op?.original?.recording_url?.startsWith(
                                    "http://"
                                  ) ||
                                  op?.original?.recording_url?.startsWith(
                                    "https://"
                                  )
                                    ? op?.original?.recording_url
                                    : audioUrl + op?.original?.recording_url
                                }
                                index={op?.index}
                                currentPlayingIndex={currentPlayingIndex}
                                setCurrentPlayingIndex={setCurrentPlayingIndex}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={t("incomingCalls.sentiments")}
                        >
                          <p className="flex min-w-[200px] items-center justify-end gap-2 sm:justify-center">
                            {op?.original?.call_sentiment ? (
                              <span>
                                {op?.original?.call_sentiment.includes(
                                  "positive"
                                ) ? (
                                  <Tag variant="solid" colorScheme="green">
                                    {t("incomingCalls.positive")}
                                  </Tag>
                                ) : op?.original?.call_sentiment.includes(
                                    "negative"
                                  ) ? (
                                  <Tag variant="solid" colorScheme="red">
                                    {t("incomingCalls.negative")}
                                  </Tag>
                                ) : (
                                  <Tag variant="solid" colorScheme="blue">
                                    {t("incomingCalls.neutral")}
                                  </Tag>
                                )}
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </td>
                        <td
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={""}
                        >
                          <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
                            {op?.original?.call_transcribe ? (
                              <button
                                onClick={() =>
                                  openTranscribeModal(op?.original)
                                }
                                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                              >
                                {t("incomingCalls.callTranscribe")}
                              </button>
                            ) : (
                              "-"
                            )}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.setPageIndex(0)}
                disabled={pagination?.pageIndex === 0}
              >
                {"<<"}
              </button>
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </button>
              <div className="flex items-center gap-2">
                <span>
                  {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                  {t("of")} {totalPages}
                </span>
                <input
                  type="number"
                  min="1"
                  max={totalPages}
                  value={table.getState().pagination.pageIndex + 1}
                  onChange={(e) =>
                    table.setPageIndex(Number(e.target.value) - 1)
                  }
                  className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900"
                />
                <button
                  className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                  onClick={() => table.setPageIndex(totalPages - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
              </div>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <CallTranscribeModal
              isOpen={isModalOpen}
              handleClose={handleCloseTranscribe}
              rowData={callTranscribe?.call_transcribe}
            />
            <ContactDetailModal
              onClose={handleCloseContact}
              isOpen={contactModalOpen}
              contactNo={selectedIncomingCall}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default IncomingCallLogs;
const columnHelper = createColumnHelper<RowObj>();
