/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TicketList from "../ticketList";
import SearchBar from "../default/components/Searchbar";
import { useTranslation } from "react-i18next";
const Support = () => {
  const { t, i18n } = useTranslation();

  const [faqQuestions, setFaqQuestions] = useState([]);
  const [accordionData, setAccordionData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");

  useEffect(() => {
    setFaqQuestions(t("faqQuestions", { returnObjects: true, appName: appData?.website_name }));
    setAccordionData(t("faqQuestions", { returnObjects: true, appName: appData?.website_name }));
  }, [i18n.language]);

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    if (accordionData) {
      const filteredData = faqQuestions
        .map((item: any) => {
          const filteredFaqData = item.faqData.filter(
            (faq: any) =>
              faq.question.toLowerCase().includes(value) ||
              faq.answer.toLowerCase().includes(value)
          );

          if (
            item.heading.toLowerCase().includes(value) ||
            filteredFaqData.length > 0
          ) {
            return {
              ...item,
              faqData:
                filteredFaqData.length > 0 ? filteredFaqData : item.faqData,
            };
          }
          return null;
        })
        .filter((item: any) => item !== null);
      setAccordionData(filteredData);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState("Faq");
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);

    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };

  return (
    <div className="eleventh-step relative mt-5">
      <div className="mt-5 flex flex-col justify-between gap-3 xxl:flex-row">
        <div className="mb-2 mt-[10px] flex w-full justify-center pt-5">
          <div className="m-auto mb-4 w-full max-w-[500px] border-b-[2px] border-gray-200 dark:border-gray-700">
            <ul
              className="-mb-px flex flex-wrap justify-center gap-5 text-center text-lg font-medium"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "Faq"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("Faq")}
                  type="button"
                  role="tab"
                  aria-controls="styled-profile"
                  aria-selected={activeTab === "Faq"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                    {t("supports.faqs")}
                  </div>
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "Support"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("Support")}
                  type="button"
                  role="tab"
                  aria-controls="styled-dashboard"
                  aria-selected={activeTab === "Support"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                    {t("supports.support")}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full">
        {activeTab === "Support" ? (
          <TicketList />
        ) : (
          <div>
            <div className="text-center text-[30px] font-bold">
              {t("supports.helpText")}
            </div>
            <div className="my-[20px] text-center font-medium text-gray-400">
              {t("supports.useKnowledgebase")}
            </div>
            <div className="flex justify-center">
              <SearchBar
                initialData={searchQuery}
                handleFilteredData={handleSearch}
                placeholder={t("supports.searchPlace")}
                extraCss={"w-full max-w-[500px]"}
              />
            </div>
            <div className="mt-3 text-center text-[24px] font-bold">
              {t("supports.cantfind")}
            </div>
            <div className="my-[20px] text-center font-medium">
              {t("supports.selectATopic")}
            </div>
            <Accordion allowToggle className="mt-[20px]">
              {accordionData.map((item: any, index: any) => (
                <AccordionItem
                  key={index}
                  className="mt-2 rounded-lg bg-white dark:bg-[#111C44] dark:text-white"
                >
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"700"}
                      >
                        {item?.heading}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Accordion allowMultiple mt={4}>
                      {item?.faqData?.map((questions: any, i: any) => (
                        <AccordionItem
                          key={i}
                          className="mt-2 rounded-lg bg-white dark:bg-[#111C44] dark:text-white"
                        >
                          <h2>
                            <AccordionButton>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                fontWeight={"500"}
                              >
                                {questions?.question}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {questions?.answer}
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
            {accordionData?.length === 0 && (
              <div className="text-center text-[20px] font-semibold">
                {t("noRecordFound")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;
