import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { IoMdCheckmark } from "react-icons/io";
import { Checkbox, useToast } from "@chakra-ui/react";
import { submitFeedback } from "services/feedback";
import { useTranslation } from "react-i18next";

const FeedBackModal = ({ onClose, isOpen }: any) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [ratingValue, setRatingValue] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [othersText, setOthersText] = useState("");
  const [checkboxes, setCheckboxes] = useState([
    { text: t("feedbackForm.categories.Performance"), checked: false },
    { text: t("feedbackForm.categories.Support"), checked: false },
    { text: t("feedbackForm.categories.Bugs"), checked: false },
    { text: t("feedbackForm.categories.LookAndFeel"), checked: false },
    { text: t("feedbackForm.categories.Features"), checked: false },
    { text: t("feedbackForm.categories.Others"), checked: false },
  ]);

  const handleClick = (type: string) => {
    if (currentStep === 2 && type !== "SKIP") {
      submitFeedback(ratingValue, feedbackText, getSelectedOptionsString)
        .then((response) => {
          toast({
            description: t("messages.feedBackSubmitted"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          onClose();
          setCurrentStep(0);
          setRatingValue(0);
          setCheckboxes([
            { text: t("feedbackForm.categories.Performance"), checked: false },
            { text: t("feedbackForm.categories.Support"), checked: false },
            { text: t("feedbackForm.categories.Bugs"), checked: false },
            { text: t("feedbackForm.categories.LookAndFeel"), checked: false },
            { text: t("feedbackForm.categories.Features"), checked: false },
            { text: t("feedbackForm.categories.Others"), checked: false },
          ]);
        })
        .catch((error) => {
          console.error("Error submitting feedback:", error);
        });
    } else if (currentStep === 2) {
      onClose();
      setCurrentStep(0);
      setRatingValue(0);
      setCheckboxes([
        { text: t("feedbackForm.categories.Performance"), checked: false },
        { text: t("feedbackForm.categories.Support"), checked: false },
        { text: t("feedbackForm.categories.Bugs"), checked: false },
        { text: t("feedbackForm.categories.LookAndFeel"), checked: false },
        { text: t("feedbackForm.categories.Features"), checked: false },
        { text: t("feedbackForm.categories.Others"), checked: false },
      ]);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };
  const ratingIcon = () => {
    if (ratingValue > 0 && ratingValue < 4) {
      return "😞";
    } else if (ratingValue > 3 && ratingValue < 7) {
      return "😑";
    } else if (ratingValue > 6 && ratingValue < 10) {
      return "😊";
    } else if (ratingValue === 10) {
      return "😍";
    } else {
      return "";
    }
  };

  const handleCheckboxChange = (index: number) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index].checked = !newCheckboxes[index].checked;
      return newCheckboxes;
    });
  };

  const getSelectedOptionsString = () => {
    const selectedOptions = checkboxes
      .filter((checkbox) => checkbox.checked && checkbox.text !== "Others")
      .map((checkbox) => checkbox.text);
    if (checkboxes[5].checked && othersText.trim() !== "") {
      selectedOptions.push(othersText.trim());
    }
    return selectedOptions.join(", ");
  };

  const steps = [
    t("feedbackForm.tab1"),
    t("feedbackForm.tab2"),
    t("feedbackForm.tab3"),
  ];
  const rating = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <Drawer
        placement="right"
        onClose={onClose}
        size={"md"}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent
          borderRadius={8}
          maxW="700px"
          className="dark:bg-navy-900"
        >
          <DrawerHeader fontSize={24} fontWeight={500}>
            {t("feedbackForm.feedbackForm")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody padding={"0px 24px 20px 24px"}>
            {t("feedbackForm.feedbackFormCaption")}
            <div className="mb-[32px] mt-4 flex items-center justify-center">
              {steps.map((step, index) => (
                <div key={index} className="flex items-center">
                  {index > 0 && (
                    <div className="mr-2 h-[1px] w-6 bg-gray-300"></div>
                  )}
                  <div className="flex items-center">
                    <div
                      className={`${
                        currentStep >= index
                          ? "bg-blue-700 text-white"
                          : "bg-[#00000061] text-white"
                      } mr-2 flex h-6 w-6 items-center justify-center rounded-full`}
                    >
                      {currentStep > index ? <IoMdCheckmark /> : index + 1}
                    </div>
                    <span className="hidden whitespace-nowrap dark:text-white md:flex">
                      {step}
                    </span>
                  </div>
                  {index < steps.length - 1 && (
                    <div className="ml-2 h-[1px] w-6 bg-gray-300 px-2"></div>
                  )}
                </div>
              ))}
            </div>
            {currentStep === 0 && (
              <div className="mb-2 mt-[16px] flex w-full flex-col">
                <div className="mb-1 font-bold text-[#00000099] dark:text-white">
                  {t("feedbackForm.aFewSentences")}
                </div>
                <textarea
                  onChange={(e) => setFeedbackText(e.target.value)}
                  className="w-full rounded  border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  placeholder={t("feedbackForm.placeholder")}
                  rows={10}
                  style={{
                    maxHeight: "10rem",
                    overflowY: "auto",
                    minHeight: "46px",
                  }}
                />
              </div>
            )}
            {currentStep === 1 && (
              <div className="flex flex-col gap-4">
                <div className="text-center font-semibold">
                  {t("feedbackForm.whichAspects")}
                </div>
                <div className="text-center">
                  {t("feedbackForm.chooseAsMany")}
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                  {checkboxes.map((checkbox, index) => (
                    <Checkbox
                      key={index}
                      colorScheme={"cyan"}
                      defaultChecked={checkbox.checked}
                      onChange={() => handleCheckboxChange(index)}
                      className="w-full justify-center rounded-[4px] border border-[#cccccc] px-5 py-3"
                    >
                      {checkbox.text}
                    </Checkbox>
                  ))}
                </div>
                {checkboxes[5].checked && (
                  <div>
                    <div className="mb-1 font-bold text-[#00000099] dark:text-white">
                      {t("feedbackForm.otherInput")}
                    </div>
                    <input
                      autoFocus
                      value={othersText}
                      onChange={(e) => setOthersText(e.target.value)}
                      className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                      type="text"
                      placeholder={t("feedbackForm.otherInputPlace")}
                    />
                  </div>
                )}
              </div>
            )}
            {currentStep === 2 && (
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <span>{t("feedbackForm.rateUs")}:</span>
                  <span>{ratingIcon()}</span>
                </div>
                <div className="mt-2 flex flex-wrap justify-start gap-2 md:justify-between">
                  {rating.map((rating, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer rounded-[6px] ${
                        ratingValue >= rating ? "bg-blue-700" : "bg-[#f5f5f5] "
                      }  p-2 px-5 font-medium dark:text-[#000000]`}
                      onClick={() => {
                        setRatingValue(rating);
                        ratingIcon();
                      }}
                    >
                      {rating}
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex justify-between">
                  <span className="text-[12px]">
                    {t("feedbackForm.needImprovement")}
                  </span>
                  <span className="text-[12px]">
                    {t("feedbackForm.excellent")}
                  </span>
                </div>
              </div>
            )}
          </DrawerBody>

          <div
            className={`mt-[10px] flex flex-col justify-start gap-[20px] p-[20px] pt-0 sm:flex-row`}
          >
            {currentStep > 0 && (
              <button
                onClick={handlePrevious}
                className="linear flex min-w-[100px] items-center justify-center gap-2 rounded border-[1px] border-blue-700 bg-white px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200 hover:bg-white dark:bg-navy-900"
              >
                {t("previous")}
              </button>
            )}
            <div className="flex justify-start gap-[20px]">
              <button
                onClick={() => handleClick("SKIP")}
                className="linear flex min-w-[100px] items-center justify-center gap-2 rounded border-[1px] border-blue-700 bg-white px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200 hover:bg-white dark:bg-navy-900"
              >
                {t("skip")}
              </button>
              <button
                onClick={() => handleClick("NEXT")}
                className="linear flex min-w-[100px] items-center justify-center gap-2 rounded bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
              >
                {currentStep === 2 ? t("finish") : t("next")}
              </button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default FeedBackModal;
