/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "components/card";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  createCartesiaConfiguration,
  deleteCartesiaConfig,
  getCartesia,
} from "services/integrations";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { responseHendler } from "utils/utils";
import ConfirmModal from "components/modal/ConfirmModal";
import { MdDelete } from "react-icons/md";
const CartesiaComponent = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRemoveConfirm, setIsOpenRemoveConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [configData, setConfigData] = useState([]);
  //   const [showPassword, setShowPassword] = useState(false);
  const planOption = [
    {
      plan: "Free",
      concurrency: "1",
    },
    {
      plan: "Pro",
      concurrency: "3",
    },
    {
      plan: "Startup",
      concurrency: "5",
    },
    {
      plan: "Scale",
      concurrency: "15",
    },
  ];
  useEffect(() => {
    fetchCartesiaData();
  }, []);
  const validationSchema = Yup.object().shape({
    api_key: Yup.string().required(t("integrations.apiKeyValid")),
    plan: Yup.string().required(t("planValid")),
    concurrency: Yup.string().required(t("concurrencyValid")),
  });

  const formik = useFormik({
    initialValues: {
      api_key: "",
      plan: "",
      concurrency: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      // console.log(values);
      let payload: any = {
        api_key: values?.api_key,
        plan: values?.plan,
        concurrency: values?.concurrency,
      };
      setIsLoading(true);
      createCartesiaConfiguration(payload)
        .then((response: any) => {
          setIsLoading(false);
          if (response.success) {
            fetchCartesiaData();
            resetForm();
            toast({
              description: t("messages.cartesiaConfigCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error submitting feedback:", error);
        });
    },
  });
  const fetchCartesiaData = () => {
    setIsLoading(true);
    getCartesia()
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          setConfigData(response?.data);
          if (response?.data && response?.data.length > 0) {
            formik.setFieldValue("api_key", response?.data[0]?.api_key);
            formik.setFieldValue("plan", response?.data[0]?.plan);
            formik.setFieldValue("concurrency", response?.data[0]?.concurrency);
          } else {
            formik.resetForm();
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const confirmRemove = () => {
    setIsOpenRemoveConfirm(true);
  };
  const handleCloseConfirm = () => {
    setIsOpenRemoveConfirm(false);
  };

  useEffect(() => {
    if (isConfirm === true) {
      setIsLoading(true);
      let req = {
        id: configData && configData.length > 0 ? configData[0]?.id : "",
      };
      deleteCartesiaConfig(req)
        .then((response) => {
          setIsLoading(false);
          setIsConfirm(false);
          responseHendler(response);
          if (response.success) {
            formik.resetForm();
            toast({
              description: t("messages.cartesiaConfigDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            fetchCartesiaData();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);
  const goToDocs = () => {
    window.open(
      "https://docs.precallai.com/general/integrations/cartesia",
      "_blank"
    );
  };
  return (
    <>
      <div className="mb-2">
        <div className="mt-2 flex justify-start gap-4 py-2">
          <button
            onClick={() => navigate("/admin/integrations")}
            className="flex h-[46px] items-center justify-center gap-1 rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>

      <div className="m-auto max-w-[900px]">
        {isLoading && <Loader />}
        <Card extra={"w-full h-full p-[16px] bg-cover"}>
          <div className="mb-10 flex items-center justify-between gap-4">
            <div className="font-semibold">
              {t("integrations.generateCartesiaConfig")}
              <span
                className="cursor-pointer pl-2 text-blue-700 hover:underline"
                onClick={() => goToDocs()}
              >
                {t("integrations.whatsAppConfig.seeDoc")}
              </span>
            </div>
            {configData && configData.length > 0 && (
              <span>
                <MdDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmRemove();
                  }}
                  className={`h-6 w-6 cursor-pointer`}
                  color="red"
                />
              </span>
            )}
          </div>
          <FormControl mt={4}>
            <FormLabel>{t("integrations.apiKey")} *</FormLabel>
            <div className="relative">
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 pr-12 text-sm leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="api_key"
                type={"text"}
                value={formik.values.api_key}
                onChange={formik.handleChange}
              />
              {/* <span
                className="absolute inset-y-0 right-0 top-2 cursor-pointer pr-4"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiFillEyeInvisible className="h-5 w-5 text-gray-400" />
                ) : (
                  <AiFillEye className="h-5 w-5 text-gray-400" />
                )}
              </span> */}
            </div>
            {formik.touched.api_key && formik.errors.api_key ? (
              <div className="text-red-500">
                {formik.errors.api_key.toString()}
              </div>
            ) : null}
          </FormControl>
          <div className="flex flex-col gap-6 md:flex-row">
            <div className="relative mt-5 flex w-full flex-col gap-1 md:w-[50%]">
              <label className="  dark:text-white" htmlFor="companyName">
                {t("planTxt")} *
              </label>
              <select
                id="plan"
                name="plan"
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 pr-12 text-sm leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                value={formik.values.plan}
                onChange={(e) => {
                  let value = e.target.value;
                  formik.setFieldValue("plan", value);
                  const selectedOption = planOption.find(
                    (option) => option.plan === value
                  );
                  if (selectedOption) {
                    formik.setFieldValue(
                      "concurrency",
                      selectedOption.concurrency
                    );
                  }
                }}
              >
                <option value="">Select plan...</option>
                {planOption &&
                  planOption.map((op, i) => (
                    <option key={i} value={op?.plan}>
                      {op?.plan}
                    </option>
                  ))}
              </select>
              {/* <input
                id="company_name"
                type="text"
                value={formik?.values.company_name}
                className="h-[50px] rounded border border-gray-500 px-4  
            focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
                onChange={(e) =>
                  formik.setFieldValue("company_name", e.target.value)
                }
              /> */}
              {formik.touched.company_name && formik.errors.company_name ? (
                <div className="text-red-500">
                  {formik.errors.company_name.toString()}
                </div>
              ) : null}
            </div>
            <div className="relative mt-5 flex w-full flex-col gap-1 md:w-[50%]">
              <label className="  dark:text-white" htmlFor="companyName">
                {t("concurrencyTxt")}
              </label>
              <input
                id="concurrency"
                name="concurrency"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.concurrency}
                disabled
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 pr-12 text-sm leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              />
              {formik.touched.concurrency && formik.errors.concurrency ? (
                <div className="text-red-500">
                  {formik.errors.concurrency.toString()}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
          </div>
        </Card>

        <ConfirmModal
          isOpen={isOpenRemoveConfirm}
          handleClose={handleCloseConfirm}
          setIsConfirm={setIsConfirm}
          type="Cartesia Config"
        />
      </div>
    </>
  );
};

export default CartesiaComponent;
