/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  FormLabel,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Card from "components/card";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  createWhatsAppConfiguration,
  deleteWhatsAppConfig,
  updateWhatsAppConfiguration,
  whatsAppConfiguration,
} from "services/integrations";
import { convertToE164, responseHendler } from "utils/utils";
import PhoneInput from "react-phone-input-2";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
const WhatsAppComponent = () => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRemoveConfirm, setIsOpenRemoveConfirm] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [configData, setConfigData] = useState([]);
  useEffect(() => {
    fetchWhatsAppData();
  }, []);
  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required(
      t("integrations.whatsAppConfig.phoneValid")
    ),
    access_token: Yup.string().required(
      t("integrations.whatsAppConfig.tokenValid")
    ),
    phone_business_id: Yup.number().required(
      t("integrations.whatsAppConfig.businessIdValid")
    ),
    whatsapp_phone_id: Yup.number().required(
      t("integrations.whatsAppConfig.phoneIdValid")
    ),
  });

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      access_token: "",
      whatsapp_phone_id: "",
      phone_business_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      // console.log(values);
      let payload: any = {
        phone_number: values?.phone_number,
        access_token: values?.access_token,
        phone_business_id: values?.phone_business_id,
        whatsapp_phone_id: values?.whatsapp_phone_id,
      };
      setIsLoading(true);
      if (configData && configData.length > 0) {
        payload.hash_id = configData[0]?.id;
        updateWhatsAppConfiguration(payload)
          .then((response: any) => {
            setIsLoading(false);
            if (response.success) {
              fetchWhatsAppData();
              resetForm();
              toast({
                description: t("messages.whatsappConfigUpdated"),
                status: "success",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            } else {
              toast({
                description: response.message,
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error submitting feedback:", error);
          });
      } else {
        createWhatsAppConfiguration(payload)
          .then((response: any) => {
            setIsLoading(false);
            if (response.success) {
              fetchWhatsAppData();
              resetForm();
              toast({
                description: t("messages.whatsappConfigCreated"),
                status: "success",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            } else {
              toast({
                description: response.message,
                status: "error",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error submitting feedback:", error);
          });
      }
    },
  });
  useEffect(() => {
    if (isConfirm === true) {
      setIsLoading(true);
      let req = {
        hash_id: configData && configData.length > 0 ? configData[0]?.id : "",
      };
      deleteWhatsAppConfig(req)
        .then((response) => {
          setIsLoading(false);
          setIsConfirm(false);
          responseHendler(response);
          if (response.success) {
            formik.resetForm();
            toast({
              description: t("messages.whatsappConfigDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            fetchWhatsAppData();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);
  const fetchWhatsAppData = () => {
    setIsLoading(true);
    whatsAppConfiguration()
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          setConfigData(response?.data);
          if (response?.data && response?.data.length > 0) {
            formik.setFieldValue(
              "phone_number",
              response?.data[0]?.whatsapp_number
            );
            formik.setFieldValue(
              "access_token",
              response?.data[0]?.whatsapp_token
            );
            formik.setFieldValue(
              "phone_business_id",
              response?.data[0]?.whatsapp_business_id
            );
            formik.setFieldValue(
              "whatsapp_phone_id",
              response?.data[0]?.whatsapp_phone_id
            );
          } else {
            formik.resetForm();
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const confirmRemove = () => {
    setIsOpenRemoveConfirm(true);
  };
  const handleCloseConfirm = () => {
    setIsOpenRemoveConfirm(false);
  };
  //   const verifyNumber = () => {
  //     setIsLoading(true);
  //     let payload = {
  //       hash_id: configData && configData.length > 0 ? configData[0]?.id : "",
  //     };
  //     whatsAppVerify(payload)
  //       .then((response) => {
  //         setIsLoading(false);
  //         if (response.success) {
  //           fetchWhatsAppData();
  //           toast({
  //             description: response?.message,
  //             status: "success",
  //             duration: 8000,
  //             isClosable: true,
  //             position: "top-left",
  //           });
  //         } else {
  //           toast({
  //             description: response?.message,
  //             status: "error",
  //             duration: 8000,
  //             isClosable: true,
  //             position: "top-left",
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         console.error("Error:", error);
  //       });
  //   };
  const goToDocs = () => {
    window.open(
      "https://docs.precallai.com/general/integrations/whatsapp",
      "_blank"
    );
  };
  return (
    <>
      <div className="mb-2">
        <div className="mt-2 flex justify-start gap-4 py-2">
          <button
            onClick={() => navigate("/admin/integrations")}
            className="flex h-[46px] items-center justify-center gap-1 rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>

      <div className="m-auto max-w-[900px]">
        {isLoading && <Loader />}
        <Card extra={"w-full h-full p-[16px] bg-cover"}>
          <div className="mb-10 flex items-center justify-between gap-4">
            <div className="font-semibold">
              {t("integrations.whatsAppConfig.generateWhatsAppConfig")}
              <span
                className="cursor-pointer pl-2 text-blue-700 hover:underline"
                onClick={() => goToDocs()}
              >
                {t("integrations.whatsAppConfig.seeDoc")}
              </span>
            </div>
            {configData && configData.length > 0 && (
              <span>
                <MdDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmRemove();
                  }}
                  className={`h-6 w-6 cursor-pointer`}
                  color="red"
                />
              </span>
            )}
          </div>
          <FormControl>
            <FormLabel>
              {t("integrations.whatsAppConfig.phoneNumber")} *
            </FormLabel>
            <PhoneInput
              inputStyle={{
                background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                color: colorMode === "dark" ? "white" : "black",
              }}
              dropdownStyle={{
                background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                color: colorMode === "dark" ? "white" : "black",
              }}
              inputProps={{
                name: "phone_number",
                id: "phone_number",
                required: true,
                className:
                  "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] items-center gap-x-2 rounded border border-gray-200 bg-white focus:border-blue-500 px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-[700] focus:bg-white focus:outline-none",
              }}
              buttonStyle={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
              country={"us"}
              onChange={(value, country, event, formattedValue) => {
                let myPhone = convertToE164(formattedValue);
                formik.setFieldValue("phone_number", myPhone);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
            />
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <div className="text-red-500">
                {formik.errors.phone_number.toString()}
              </div>
            ) : null}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("integrations.whatsAppConfig.phoneId")} *</FormLabel>
            <input
              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 text-sm leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              id="whatsapp_phone_id"
              name="whatsapp_phone_id"
              type="number"
              value={formik.values.whatsapp_phone_id}
              onChange={formik.handleChange}
            />
            {formik.touched.whatsapp_phone_id &&
            formik.errors.whatsapp_phone_id ? (
              <div className="text-red-500">
                {formik.errors.whatsapp_phone_id.toString()}
              </div>
            ) : null}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>
              {t("integrations.whatsAppConfig.accessToken")} *
            </FormLabel>
            <div className="relative">
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 pr-12 text-sm leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="access_token"
                type={showPassword ? "text" : "password"}
                value={formik.values.access_token}
                onChange={formik.handleChange}
              />
              <span
                className="absolute inset-y-0 right-0 top-2 cursor-pointer pr-4"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiFillEyeInvisible className="h-5 w-5 text-gray-400" />
                ) : (
                  <AiFillEye className="h-5 w-5 text-gray-400" />
                )}
              </span>
            </div>
            {formik.touched.access_token && formik.errors.access_token ? (
              <div className="text-red-500">
                {formik.errors.access_token.toString()}
              </div>
            ) : null}
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>
              {t("integrations.whatsAppConfig.businessId")} *
            </FormLabel>
            <input
              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 text-sm leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              id="phone_business_id"
              name="phone_business_id"
              type="number"
              value={formik.values.phone_business_id}
              onChange={formik.handleChange}
            />
            {formik.touched.phone_business_id &&
            formik.errors.phone_business_id ? (
              <div className="text-red-500">
                {formik.errors.phone_business_id.toString()}
              </div>
            ) : null}
          </FormControl>
          {/* {configData && configData.length > 0 && (
            <div className="mt-4 flex items-center justify-end">
              {configData[0]?.is_activated ? (
                <Tag variant="solid" colorScheme="green">
                  Activated
                </Tag>
              ) : (
                <button
                  onClick={() => verifyNumber()}
                  className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                >
                  Verify
                </button>
              )}
            </div>
          )} */}
          <div className="mt-4">
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              Save
            </Button>
          </div>
        </Card>
        <ConfirmModal
          isOpen={isOpenRemoveConfirm}
          handleClose={handleCloseConfirm}
          setIsConfirm={setIsConfirm}
          type="WhatsApp Config"
        />
      </div>
    </>
  );
};

export default WhatsAppComponent;
