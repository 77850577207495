import { Tooltip } from "@chakra-ui/tooltip";
const TooltipHorizon = (props: {
  extra: string;
  trigger: JSX.Element;
  content: any;
  placement: "left" | "right" | "top" | "bottom";
}) => {
  const { extra, trigger, content, placement } = props;
  return (
    <Tooltip
      placement={placement}
      borderRadius={"10px"}
      label={content}
      pointerEvents={"all"}
      className={`w-max bg-white px-4 py-3 shadow-xl shadow-shadow-500 dark:!bg-white dark:shadow-none ${extra}`}
    >
      {trigger}
    </Tooltip>
  );
};

export default TooltipHorizon;
