import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
const VerifyUser = () => {
  const { t } = useTranslation();
  return (
    <div className="mb-16 mt-16 flex h-full w-full max-w-full flex-col items-center justify-start px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="mb-4 flex items-center gap-2 text-4xl font-bold text-green-600">
          <FaCheckCircle /> {t("congratulationsText")}
        </h1>
        {/* <p className="mb-8 text-xl font-medium text-gray-600">
          Sign Up Successful
        </p> */}
        <p className="mb-8  text-2xl font-bold text-gray-600">
          {t("linkSentText")}{" "}
        </p>
        {/* <button className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
          Go to Home
        </button> */}
      </div>
    </div>
  );
};

export default VerifyUser;
