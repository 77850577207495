/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/card";
import { useEffect, useState } from "react";
import { Box, Tag, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import TooltipHorizon from "components/tooltip";
import Pagination from "components/pagination";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { deleteInviteUser, getInviteUsersList } from "services/inviteUsers";
import ConfirmModal from "components/modal/ConfirmModal";
import { FaUserPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import AddNewUserModal from "components/modal/AddNewUserModal";
import { useTranslation } from "react-i18next";
const InviteUser = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invitedUsersData, setInvitedUsersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState<any>(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);

  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentUsers = invitedUsersData?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "desc",
  });
  const handleSortByName = () => {
    let direction = "asc";
    if (sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: "name", direction });

    const sortedData = [...invitedUsersData].sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      if (nameA < nameB) {
        return direction === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setInvitedUsersData(sortedData);
  };

  useEffect(() => {
    setLoading(true);
    fetchInvitedUsers();
  }, []);
  useEffect(() => {
    if (isConfirm) {
      removeInvitedUser();
    }
  }, [isConfirm]);
  const fetchInvitedUsers = () => {
    getInviteUsersList()
      .then((response) => {
        if (response?.success) {
          const sortedData = response?.data.sort((a: any, b: any) => {
            const dateA: any = new Date(a.created_at);
            const dateB: any = new Date(b.created_at);
            return dateB - dateA;
          });

          setInvitedUsersData(sortedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };
  const removeInvitedUser = () => {
    deleteInviteUser(rowData?.hash_id)
      .then((response) => {
        if (response.success) {
          handleConfirmClose();
          setIsConfirm(false);
          setRowData(null);
          toast({
            description: t("messages.invitedUserDeleted"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          fetchInvitedUsers();
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleEdit = (rowData: any) => {
    setRowData(rowData);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: any) => {
    setRowData(rowData);
    setIsConfirmModalOpen(true);
  };
  const openAddUserModal = () => {
    setIsOpenAddUserModal(true);
  };
  const closeAddUserModal = () => {
    setIsOpenAddUserModal(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      {loading && <Loader />}
      <div className="mt-3 flex h-fit w-full flex-col gap-5 xxl:flex-row">
        <div className="w-full lg:col-span-12 lg:!mb-0">
          <div className="mb-2">
            <div className="my-[10px] text-[15px] font-medium text-gray-400">
              Invite user(s) and assign system defined rolesto them. You can
              also add and manage them later in the configuration page
            </div>
            <div className="mt-2 flex justify-between gap-4 py-2">
              <div className="my-[10px] flex items-center gap-2 text-[20px] font-semibold hover:text-navy-700 dark:hover:text-white">
                Users <Tag variant="solid">0</Tag>
              </div>
              <button
                onClick={() => openAddUserModal()}
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                <GoPlus className="h-7 w-7" />
                Add New User
              </button>
            </div>
          </div>
          <Card extra={"w-full h-full p-[16px] bg-cover"}>
            <div className="text-[22px] font-semibold capitalize hover:text-navy-700 dark:hover:text-white">
              Invited Users
            </div>
            <Box p={4} pt={6}>
              <table className="responsive-table w-full">
                <thead className="border-b-2">
                  <tr>
                    <th scope="col">
                      {" "}
                      <p
                        className="flex cursor-pointer items-center justify-center text-sm font-bold text-gray-600 dark:text-white"
                        onClick={handleSortByName}
                      >
                        User Name
                        {sortConfig.key === "created_at" && (
                          <span>
                            {sortConfig.direction === "asc" ? (
                              <IoIosArrowRoundUp className="h-6 w-6" />
                            ) : (
                              <IoIosArrowRoundDown className="h-6 w-6" />
                            )}
                          </span>
                        )}
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                        Email ID
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        Role Assigned
                      </p>
                    </th>
                    <th scope="col">
                      <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                        Actions
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.length ? (
                    currentUsers.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="p-4" data-label="Date">
                          {" "}
                          <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                            {new Date(item.created_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </p>
                        </td>
                        <td className="p-4" data-label="Amount">
                          {" "}
                          <p className="flex items-center justify-center text-sm font-bold text-navy-700 dark:text-white">
                            $ {item?.amount}
                          </p>
                        </td>
                        <td className="p-4" data-label="Transaction id">
                          {" "}
                          <TooltipHorizon
                            trigger={
                              <p className="m-auto max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold text-navy-700 dark:text-white">
                                {item?.transaction_id}
                              </p>
                            }
                            placement="top"
                            content={item?.transaction_id}
                            extra="rounded-full text-[11px]"
                          />
                        </td>
                        <td className="p-4" data-label="Actions">
                          {" "}
                          <div className="flex items-center justify-end gap-4 sm:justify-center">
                            <MdEdit
                              onClick={() => handleEdit(item)}
                              color="gray"
                              className="h-6 w-6 cursor-pointer"
                            />
                            <MdDelete
                              onClick={() => handleConfirmModal(item)}
                              color="gray"
                              className="h-6 w-6 cursor-pointer"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-4" colSpan={4}>
                        <div className="col-span-3 mb-auto flex flex-col items-center justify-center">
                          <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                            <FaUserPlus />
                          </div>
                          <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                            No Invited User Found
                          </h4>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                perPage={perPage}
                allTotal={invitedUsersData?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Box>
            <ConfirmModal
              isOpen={isConfirmModalOpen}
              handleClose={handleConfirmClose}
              setIsConfirm={setIsConfirm}
              type="Invited User"
            />
            <AddNewUserModal
              isOpen={isOpenAddUserModal}
              onClose={closeAddUserModal}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InviteUser;
