/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Card from "components/card";
import { useTranslation } from "react-i18next";
import CampaignsCallLogs from "./campaignsLog";
import PlayGroundHistory from "../callPlayground/PlayGroundHistory";
import IncomingCallLogs from "./incomingCallLog";
const CallLogs = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("campaigns");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };
  return (
    <div>
      <Card extra={"w-full sm:overflow-auto px-6 mt-8"}>
        <div className="mb-10">
          <div className="mb-2 mt-[10px] flex justify-center pt-5">
            <div className="mb-4 w-full border-b-[2px] border-gray-200 dark:border-gray-700">
              <ul
                className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
                role="tablist"
              >
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "campaigns"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("campaigns")}
                    type="button"
                    role="tab"
                    aria-controls="styled-dashboard"
                    aria-selected={activeTab === "campaigns"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                      {t("campaignLogs")}
                    </div>
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "playground"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("playground")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "playground"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      {t("playgroundLogs")}
                    </div>
                  </button>
                </li>
                <li className="me-2" role="presentation">
                  <button
                    className={`inline-block p-1 ${
                      activeTab === "incomingLogs"
                        ? "border-b-2 border-blue-700 text-blue-700"
                        : " text-gray-600"
                    }`}
                    onClick={() => handleTabClick("incomingLogs")}
                    type="button"
                    role="tab"
                    aria-controls="styled-profile"
                    aria-selected={activeTab === "incomingLogs"}
                  >
                    <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                      {t("incomingCallLogs")}
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {activeTab === "campaigns" && (
            <div>
              <CampaignsCallLogs />
            </div>
          )}
          {activeTab === "playground" && (
            <div>
              <PlayGroundHistory />
            </div>
          )}
          {activeTab === "incomingLogs" && (
            <div>
              <IncomingCallLogs />
            </div>
          )}
        </div>
      </Card>
      
    </div>
  );
};

export default CallLogs;
