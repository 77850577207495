/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { MdEdit, MdPermContactCalendar } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SearchBar from "views/admin/assitstants/assistanceList/components/Searchbar";
import { GoPlus } from "react-icons/go";
import { numberWithCommasAndDollar, responseHendler } from "utils/utils";
import DealEditModal from "components/modal/DealsModal/EditDealModal";
import DealAddModal from "components/modal/DealsModal/AddDealModal";
import { deleteDeal, getDealsList } from "services/crm";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

type RowObj = {
  deal_name: string;
  amount: any;
  stage: any;
  action: any;
};

const ContactDeals = ({ contactData }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAddDealModalOpen, setIsAddDealModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [sorting, setSorting] = useState<SortingState>([]);
  const [dealList, setDealList] = useState([]);
  const [tableDataAll, setTableDataAll] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [contactId] = useState(contactData?.contact_id);

  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    fetchDealList();
  }, [refresh]);
  const fetchDealList = () => {
    setIsLoading(true);
    getDealsList(contactData?.contact_id)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            setDealList(response?.data);
            setTableDataAll(response?.data);
          } else {
            setDealList([]);
            setTableDataAll([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (isConfirm) {
      deleteDealRecord();
    }
  }, [isConfirm]);
  const deleteDealRecord = () => {
    setIsLoading(true);
    deleteDeal(rowData?.id)
      .then((response) => {
        setIsLoading(false);
        setIsConfirm(false);
        if (response.success) {
          setRefresh(!refresh);
          setRowData(null);
          toast({
            description: t("messages.dealDeleted"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (rowData: any) => {
    setRowData(rowData?.row?.original);
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: any) => {
    setRowData(rowData?.row?.original);
    setIsConfirmModalOpen(!isModalOpen);
  };

  const handleAddDealClose = () => {
    setIsAddDealModalOpen(false);
  };

  const handleAddDealModal = () => {
    setIsAddDealModalOpen(!isAddDealModalOpen);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (tableDataAll) {
      const filteredData = tableDataAll.filter(
        (item: any) =>
          typeof item?.first_name === "string" &&
          item?.first_name?.toLowerCase()?.includes(value.toLowerCase())
      );
      setDealList(filteredData);
    }
  };
  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("deal_name", {
      id: "Deal Name",
      header: () => <CustomHeader headerText={t("contactDeals.dealName")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "Amount",
      header: () => <CustomHeader headerText={t("contactDeals.amount")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {numberWithCommasAndDollar(info.getValue())}
        </p>
      ),
    }),
    columnHelper.accessor("stage", {
      id: "Stage",
      header: () => <CustomHeader headerText={t("contactDeals.stage")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    // columnHelper.accessor("description", {
    //   id: "Description",
    //   header: () => <CustomHeader headerText="Description" />,
    //   cell: (info: any) => (
    //     <TooltipHorizon
    //       trigger={
    //         <div className="m-auto max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap sm:justify-center md:text-center">
    //           {info.getValue()}
    //         </div>
    //       }
    //       placement="top"
    //       content={info.getValue()}
    //       extra="rounded text-[11px]"
    //     />
    //   ),
    // }),
    columnHelper.accessor("action", {
      id: "Actions",
      header: () => <CustomHeader headerText={t("contactDeals.actions")} />,
      cell: (info) => (
        <div className="flex  items-center justify-end gap-4 sm:justify-center">
          <MdEdit
            onClick={() => {
              handleModal(info);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Edit Contact",
                  buttonName: "Edit Contact",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
          <MdDelete
            onClick={() => {
              handleConfirmModal(info);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Delete Contact",
                  buttonName: "Delete Contact",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data: dealList,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div>
      {isLoading && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <SearchBar
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => handleAddDealModal()}
            className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            {t("addNew")}
          </button>
        </div>
      </div>

      {dealList?.length === 0 && !isLoading ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("contactDeals.noDeal")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("contactDeals.getStarted")}
            </p>
            <button
              onClick={() => {
                handleAddDealModal();

                if (Array.isArray((window as any).dataLayer)) {
                  (window as any).dataLayer.push({
                    event: "Add New Company",
                    buttonName: "Add new",
                  });
                }
              }}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("addNew")}
            </button>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          scope="col"
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {/* {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })} */}
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}
      <DealEditModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        rowData={rowData}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <DealAddModal
        isOpen={isAddDealModalOpen}
        handleClose={handleAddDealClose}
        setRefresh={setRefresh}
        refresh={refresh}
        contactId={contactId}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        setIsConfirm={setIsConfirm}
        setRefresh={setRefresh}
        refresh={refresh}
        type={t("contactDeals.deal")}
      />
    </div>
  );
};

export default ContactDeals;
const columnHelper = createColumnHelper<RowObj>();
