const AuthDropdown = ({
  question,
  options,
  selectedValue,
  setSelectedValue,
  placeholderValue,
  toggleDropdown,
  isOpen,
  extra,
  infoType,
  setSelectedId,
}: any) => {
  let optionsValue = options;
  switch (infoType) {
    case "roles":
      optionsValue =
        options?.roles &&
        options?.roles.map((role: any) => ({
          name: role?.role,
          id: role?.hash_id,
        }));
      break;
    case "industries":
      optionsValue =
        options.industries &&
        options?.industries.map((industry: any) => ({
          name: industry?.industry_name,
          id: industry?.hash_id,
        }));
      break;
    case "objectives":
      optionsValue =
        options.objectives &&
        options?.objectives.map((objective: any) => ({
          name: objective?.objective,
          id: objective?.hash_id,
        }));
      break;
    default:
      optionsValue = options?.map((option: any, index: number) => ({
        name: option,
        id: index + 1,
      }));
      break;
  }
  return (
    <div className="mb-2 mt-[16px] flex w-full flex-col">
      {question && (
        <div className="mb-1 font-bold text-[#00000099] dark:text-white">
          {question}
        </div>
      )}
      <div className="relative inline-block max-w-[500px]">
        <button
          type="button"
          className={`dark-text-[#fff] inline-flex w-full items-center justify-between gap-x-2 rounded border bg-white px-4 py-3 text-sm font-medium shadow-sm ${
            isOpen ? "border-blue-700" : "border-gray-200"
          } disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:bg-navy-900 ${extra}`}
          onClick={toggleDropdown}
        >
          {selectedValue || placeholderValue}
          <svg
            className={`size-4 ${isOpen ? "rotate-180" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </button>

        {isOpen && (
          <div
            className="absolute left-0 top-full z-10 mt-2 w-full min-w-60 rounded
          
          bg-white p-2 opacity-100 shadow-md transition-opacity duration-200 dark:bg-navy-700"
          >
            {optionsValue &&
              optionsValue.map((option: any) => (
                <div
                  key={option?.id}
                  className="flex cursor-pointer items-center gap-x-3.5 rounded-lg px-3 py-2 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700"
                  onClick={() => {
                    setSelectedValue(option?.name);
                    if (setSelectedId) setSelectedId(option?.id);
                    toggleDropdown();
                  }}
                >
                  {option?.name}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthDropdown;
