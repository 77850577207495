const CallDurationTotal = ({ totalDurationInSeconds }) => {
  let durationText = "";

  if (totalDurationInSeconds >= 3600) {
    const hours = Math.floor(totalDurationInSeconds / 3600);
    const minutes = Math.floor((totalDurationInSeconds % 3600) / 60);
    const seconds = totalDurationInSeconds % 60;
    durationText = `${hours} : ${minutes
      .toString()
      .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
  } else if (totalDurationInSeconds >= 60) {
    const minutes = Math.floor(totalDurationInSeconds / 60);
    const seconds = totalDurationInSeconds % 60;
    durationText = `${minutes.toString().padStart(2, "0")} : ${seconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    durationText = `00 : ${totalDurationInSeconds.toString().padStart(2, "0")}`;
  }

  return <>{durationText}</>;
};

export default CallDurationTotal;
