/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CallTranscribeModal = ({ isOpen, handleClose, rowData }: any) => {
  const [cardData, setCardData] = useState([]);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (rowData) {
      const unescapedString = rowData
        .replace(/\\\\\\"/g, "") // Fix escaped double quotes
        .replace(/\\'/g, "'") // Fix single quotes
        .replace(/\\"/g, '"') // Fix escaped double quotes
        .replace(/\\\\n/g, " ") // Fix escaped newlines
        .replace(/\\\\r/g, "\\r") // Fix escaped carriage returns
        .replace(/\\\\t/g, "\\t") // Fix escaped tabs
        .replace(/\n/g, "") // Fix escaped tabs
        .replace(/\\/g, "") // Fix escaped tabs
        .replace(/\\\\/g, "\\"); // Fix double backslashes
      const parsedData = JSON.parse(unescapedString); // eslint-disable-next-line
      parsedData.forEach((obj: any) => {
        if (obj.text) {
          obj.text = obj.text.replace(/\n/g, "\\n").replace(/"/g, '\\"');
        }
      });
      setCardData(parsedData); // eslint-disable-next-line
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <div>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"1200px"} className="dark:bg-navy-900">
          <ModalHeader>{t("playground.callTranscribe")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} overflow={"auto"}>
            <div className="mt-4">
              <div className="p-4">
                {cardData &&
                  cardData.map((op, i) => (
                    <p key={i} className="mb-3">
                      {op.type === "gpt" ? (
                        <Tag variant="subtle" colorScheme="cyan">
                          {t("playground.agent")}
                        </Tag>
                      ) : (
                        <Tag>{t("playground.user")}</Tag>
                      )}

                      <span
                        className="pl-2"
                        dangerouslySetInnerHTML={{ __html: op.text }}
                      ></span>
                    </p>
                  ))}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CallTranscribeModal;
