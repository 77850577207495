/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  FormControl,
  //   FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormLabel,
} from "@chakra-ui/react";
import { connectorList } from "services/integrations";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
const CRMsyncModal = ({
  isOpen,
  handleClose,
  setActionData,
  actionData,
  syncData,
  setZapierData,
  zapierData,
}: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const userId = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [connectorListData, setConnectorListData] = useState(null);
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");
  useEffect(() => {
    fetchConnectorList(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isOpen]);
  const fetchConnectorList = (userId: string) => {
    connectorList(userId)
      .then((response: any) => {
        setLoading(false);
        if (response?.success) {
          setConnectorListData(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  const validationSchema = Yup.object().shape({
    hubspot: Yup.boolean(),
    salesforce: Yup.boolean(),
    gohighlevel: Yup.boolean(),
    zoho: Yup.boolean(),
    zapierHook: Yup.boolean(),
    zapier_link: Yup.string().when(["zapierHook"], ([zapierHook], schema) => {
      if (zapierHook) {
        return Yup.string()
          .url(t("assistantForm.invalidURL"))
          .required(t("assistantForm.linkValid"));
      }
      return schema;
    }),
  });
  const formik = useFormik({
    initialValues: {
      hubspot: syncData?.hubspot || false,
      salesforce: syncData?.salesforce || false,
      gohighlevel: syncData?.gohighlevel || false,
      zoho: syncData?.zoho || false,
      zapierHook: zapierData ? true : false || false,
      zapier_link: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      let payload: any = {
        type: "crm",
        hubspot: values?.hubspot || false,
        salesforce: values?.salesforce || false,
        zoho: values?.zoho || false,
        gohighlevel: values?.gohighlevel || false,
      };
      let deletedData = actionData.filter((item: any) => item.type !== "crm");
      if (
        !values?.hubspot &&
        !values?.salesforce &&
        !values?.gohighlevel &&
        !values?.zoho
      ) {
        setActionData([...deletedData]);
      } else {
        setActionData([...deletedData, payload]);
      }
      if (values?.zapier_link) {
        setZapierData(values?.zapier_link);
      }
      resetAndCloseModal();
    },
  });
  const resetAndCloseModal = () => {
    formik.resetForm();
    handleClose();
  };

  useEffect(() => {
    formik.setFieldValue("hubspot", syncData?.hubspot);
    formik.setFieldValue("salesforce", syncData?.salesforce);
    formik.setFieldValue("gohighlevel", syncData?.gohighlevel);
    formik.setFieldValue("zoho", syncData?.zoho);
  }, [isOpen, syncData]);
  useEffect(() => {
    formik.setFieldValue("zapierHook", zapierData ? true : false);
    formik.setFieldValue("zapier_link", zapierData ? zapierData : "");
  }, [isOpen, zapierData]);
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetAndCloseModal}
        size={"sm"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"400px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("assistantForm.SyncDataCRM")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            {loading && <Loader />}
            <div>
              <FormControl mt={4}>
                <Checkbox
                  id="hubspot"
                  colorScheme="cyan"
                  defaultChecked={formik.values.hubspot}
                  onChange={formik.handleChange}
                  disabled={!connectorListData?.hubspot}
                >
                  {t("assistantForm.hubspot")}
                </Checkbox>
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="salesforce"
                  colorScheme="cyan"
                  defaultChecked={formik.values.salesforce}
                  onChange={formik.handleChange}
                  disabled={!connectorListData?.salesForce}
                >
                  {t("assistantForm.salesforce")}
                </Checkbox>
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="gohighlevel"
                  colorScheme="cyan"
                  defaultChecked={formik.values.gohighlevel}
                  onChange={formik.handleChange}
                  disabled={!connectorListData?.goHighLevel}
                >
                  {t("integrations.integ.GoHighLevel")}
                </Checkbox>
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="zoho"
                  colorScheme="cyan"
                  defaultChecked={formik.values.zoho}
                  onChange={formik.handleChange}
                  disabled={!connectorListData?.zoho}
                >
                  {t("zoho")}
                </Checkbox>
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="zapierHook"
                  colorScheme="cyan"
                  defaultChecked={formik.values.zapierHook}
                  onChange={formik.handleChange}
                >
                  {t("assistantForm.zapierHook")}
                </Checkbox>
              </FormControl>
              {formik.values.zapierHook && (
                <div className="mt-9">
                  <FormLabel color={"gray"}>
                    <ul className="numbered-list">
                      <li>{t("assistantForm.zapierHookCont1", {appName: appData?.website_name})}</li>
                      <li>{t("assistantForm.zapierHookCont2")}</li>
                    </ul>
                  </FormLabel>
                  <FormControl mt={4}>
                    <FormLabel>{t("assistantForm.zapierHook")}</FormLabel>
                    <input
                      className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                      id="zapier_link"
                      type="text"
                      value={formik.values.zapier_link}
                      onChange={formik.handleChange}
                      placeholder={t("assistantForm.zapierLink")}
                      name="zapier_link"
                    />
                    {formik.touched.zapier_link && formik.errors.zapier_link ? (
                      <div className="text-red-500">
                        {formik.errors.zapier_link.toString()}
                      </div>
                    ) : null}
                  </FormControl>
                </div>
              )}
            </div>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <div>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => formik.handleSubmit()}
                // isDisabled={
                //   !formik.values.hubspot ||
                //   (!formik.values.salesforce && !formik.values.zapier_link)
                // }
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("save")}
              </Button>
              <Button onClick={resetAndCloseModal}>{t("cancel")}</Button>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CRMsyncModal;
