/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Card,
} from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdMenuOpen, MdDelete, MdEdit } from "react-icons/md";
import { IoCaretDownCircleOutline } from "react-icons/io5";
import { TbUserQuestion } from "react-icons/tb";
import SearchBar from "views/admin/default/components/Searchbar";
import { useTranslation } from "react-i18next";
interface FormData {
  identifier: string;
  question: string;
  options: any[];
  lookingFor: string;
  outputExamples: any[];
  selectedOption: string;
}
interface FormD {
  say: string;
}
let initialValues: FormData = {
  identifier: "",
  question: "",
  options: [""],
  lookingFor: "",
  outputExamples: [""],
  selectedOption: "",
};
let initialVal: FormD = {
  say: "",
};
const AddInfoExtractorModal = ({
  isOpen,
  handleClose,
  setActionData,
  questionsData,
  actionData,
}: any) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataAll, setTableDataAll] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const validationSchema = Yup.object().shape({
    identifier: Yup.string().required("Identifier is required"),
    question:
      selectedOption !== "openQuestion"
        ? Yup.string().required("Question is required")
        : Yup.string(),
    options:
      selectedOption === "singleChoice"
        ? Yup.array()
            .of(Yup.string().required("Option is required"))
            .min(1, "At least one option is required")
        : Yup.array(),
    lookingFor:
      selectedOption === "openQuestion"
        ? Yup.string().required("Looking for is required")
        : Yup.string(),
    outputExamples:
      selectedOption === "openQuestion"
        ? Yup.array()
            .of(Yup.string().required("Output example is required"))
            .min(1, "At least one output example is required")
        : Yup.array(),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let yesNoQuestionData = {
        identifier: values.identifier,
        question: values.question,
        type: selectedOption,
      };
      let singleChoiceQuestionData = {
        identifier: values.identifier,
        question: values.question,
        options: values.options,
        type: selectedOption,
      };
      let openQuestionData = {
        identifier: values.identifier,
        lookingFor: values.lookingFor,
        outputExamples: values.outputExamples,
        type: selectedOption,
      };
      let updatedTableData = [...tableData];

      if (selectedOption === "singleChoice") {
        if (isEdit) {
          updatedTableData[editIndex] = singleChoiceQuestionData;
        } else {
          updatedTableData.push(singleChoiceQuestionData);
        }
      } else if (selectedOption === "openQuestion") {
        if (isEdit) {
          updatedTableData[editIndex] = openQuestionData;
        } else {
          updatedTableData.push(openQuestionData);
        }
      } else {
        if (isEdit) {
          updatedTableData[editIndex] = yesNoQuestionData;
        } else {
          updatedTableData.push(yesNoQuestionData);
        }
      }
      setTableDataAll(updatedTableData);
      setTableData(updatedTableData);
      // if (selectedOption === "singleChoice") {
      //   setTableData((prev: any) => [...prev, singleChoiceQuestionData]);
      // } else if (selectedOption === "openQuestion") {
      //   setTableData((prev: any) => [...prev, openQuestionData]);
      // } else {
      //   setTableData((prev: any) => [...prev, yesNoQuestionData]);
      // }
      formik.resetForm();
      setSelectedOption("");
      setIsEdit(false);
      setEditIndex(null);
    },
  });
  const validationSch = Yup.object().shape({
    say: Yup.string().required("Say is required"),
  });

  const formikVal = useFormik({
    initialValues: initialVal,
    validationSchema: validationSch,
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });
  const closeModal = () => {
    formik.resetForm();
    formikVal.resetForm();
    handleClose();
    setSelectedOption("");
    setTableData([]);
    setTableDataAll([]);
    setEditIndex(null);
  };
  const handleCancelQuestions = () => {
    formik.resetForm();
    setSelectedOption("");
    setEditIndex(null);
  };
  const chooseQuestionType = (status: string) => {
    setSelectedOption(status);
  };

  const handleDelete = (index: number) => {
    let FilteredData = tableData.filter((_: any, i: number) => {
      return i !== index;
    });
    setTableData(FilteredData);
    setTableDataAll(FilteredData);
  };
  const handleEdit = (item: any, index: number) => {
    setIsEdit(true);
    chooseQuestionType(item?.type);
    setEditIndex(index);
    formik.setValues({
      identifier: item?.identifier || "",
      question: item?.question || "",
      options: item?.options || [""],
      lookingFor: item?.lookingFor || "",
      outputExamples: item?.outputExamples || [""],
      selectedOption: item?.selectedOption || "",
    });
  };

  const handleSubmit = (values: any) => {
    let deletedData = actionData.filter(
      (item: any) => item.type !== "infoExtractor"
    );
    let extractorData = {
      type: "infoExtractor",
      say: values.say,
      questions: tableData,
    };
    setActionData(() => [...deletedData, extractorData]);
    closeModal();
  };

  useEffect(() => {
    if (isOpen && questionsData?.length) {
      setTableData(questionsData);
      setTableDataAll(questionsData);
    }
    let findData = actionData.find(
      (item: any) => item.type === "infoExtractor"
    );
    formikVal.setValues({
      say: findData?.say || "",
    });
  }, [isOpen, actionData]);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (tableData) {
      const filteredData = tableDataAll.filter(
        (item: any) =>
          item?.identifier?.toLowerCase()?.includes(value.toLowerCase()) ||
          item?.question?.toLowerCase()?.includes(value.toLowerCase()) ||
          item?.lookingFor?.toLowerCase()?.includes(value.toLowerCase())
      );
      setTableData(filteredData);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={closeModal}
        blockScrollOnMount={false}
        size={"xl"}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW={"1200px"}
          minH={"500px"}
          className="dark:bg-navy-900"
        >
          <DrawerHeader>{t("assistantForm.infoExtractor")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            {/* <FormControl>
              <FormLabel>Campaign Name*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="campaignName"
                type="text"
                value={formik.values.campaignName}
                onChange={formik.handleChange}
              />
              {formik.touched.campaignName && formik.errors.campaignName ? (
                <div className="text-red-500">
                  {formik.errors.campaignName.toString()}
                </div>
              ) : null}
            </FormControl> */}
            {selectedOption === "" && (
              <div>
                <div className="mb-4 flex justify-between">
                  <SearchBar
                    initialData={searchQuery}
                    handleFilteredData={handleSearch}
                  />
                  <Menu>
                    <MenuButton
                      as={Button}
                      leftIcon={<GoPlus className="h-7 w-7" />}
                    >
                      {t("assistantForm.addQuestion")}
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() => chooseQuestionType("yesNoQuestion")}
                      >
                        <span>
                          <IoMdCheckboxOutline />
                        </span>
                        <span className="pl-2">
                          {t("assistantForm.yesNoQuestion")}
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => chooseQuestionType("singleChoice")}
                      >
                        <span>
                          <IoCaretDownCircleOutline />
                        </span>
                        <span className="pl-2">
                          {t("assistantForm.singleChoice")}
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => chooseQuestionType("openQuestion")}
                      >
                        <span>
                          <MdMenuOpen />
                        </span>
                        <span className="pl-2">
                          {t("assistantForm.openQuestion")}
                        </span>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
                <FormControl>
                  <FormLabel>{t("assistantForm.say")} *</FormLabel>
                  <input
                    className="w-full appearance-none rounded border-[1px] border-gray-200 px-4 
                        py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="say"
                    type="text"
                    value={formikVal.values.say}
                    onChange={formikVal.handleChange}
                    placeholder={t("assistantForm.sayPlaceholder")}
                  />
                  {formikVal.touched.say && formikVal.errors.say ? (
                    <div className="text-red-500">
                      {formikVal.errors.say.toString()}
                    </div>
                  ) : null}
                </FormControl>
              </div>
            )}
            {selectedOption && (
              <div>
                <FormControl>
                  <FormLabel>{t("assistantForm.identifier")} *</FormLabel>
                  <input
                    className="w-full appearance-none rounded border-[1px] border-gray-200 px-4 
                        py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="identifier"
                    type="text"
                    value={formik.values.identifier}
                    onChange={formik.handleChange}
                    placeholder={t("assistantForm.identifiePlaceholder")}
                  />
                  {formik.touched.identifier && formik.errors.identifier ? (
                    <div className="text-red-500">
                      {formik.errors.identifier.toString()}
                    </div>
                  ) : null}
                </FormControl>
                {selectedOption !== "openQuestion" && (
                  <FormControl className="mt-4">
                    <FormLabel>{t("assistantForm.question")} *</FormLabel>
                    <input
                      className="w-full appearance-none rounded border-[1px] border-gray-200 px-4 
                        py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                      id="question"
                      type="text"
                      value={formik.values.question}
                      onChange={formik.handleChange}
                      placeholder={t("assistantForm.questionPlaceholder")}
                    />
                    {formik.touched.question && formik.errors.question ? (
                      <div className="text-red-500">
                        {formik.errors.question.toString()}
                      </div>
                    ) : null}
                  </FormControl>
                )}
                {selectedOption === "singleChoice" && (
                  <div>
                    <FormControl className="mt-4">
                      <FormLabel>{t("assistantForm.options")} *</FormLabel>
                      {/* Render input fields for options */}
                      {formik.values.options.map((option: any, index: any) => (
                        <div key={index}>
                          <div className="mb-4 flex gap-2">
                            <input
                              className="w-full appearance-none rounded border-[1px] border-gray-200
                                px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                              type="text"
                              value={option}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `options[${index}]`,
                                  e.target.value
                                )
                              }
                              placeholder={`${t(
                                "assistantForm.optionPlaceholder"
                              )} ${index + 1}`}
                            />
                            {index !== 0 && (
                              <button
                                type="button"
                                onClick={() => {
                                  const newOptions = [...formik.values.options];
                                  newOptions.splice(index, 1);
                                  formik.setFieldValue("options", newOptions);
                                }}
                                className="focus:shadow-outline flex-shrink-0 rounded bg-red-500 px-2 py-2 font-bold text-white hover:bg-red-600 focus:outline-none"
                              >
                                <MdDelete />
                              </button>
                            )}
                          </div>
                          {/* {formik.touched.options &&
                          formik.errors.options &&
                          formik.errors.options[index] ? (
                            <div className="text-red-500">
                              {formik.errors.options[index]}
                            </div>
                          ) : null} */}
                          {formik.touched.options && formik.errors.options ? (
                            <div className="mb-3 text-red-500">
                              {Array.isArray(formik.errors.options) ? (
                                formik.errors.options.map(
                                  (error: any, i: any) =>
                                    i === index ? (
                                      <div key={i}>{error}</div>
                                    ) : null
                                )
                              ) : (
                                <div>{formik.errors.options.toString()}</div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ))}
                      {/* Render button to add more options */}
                      <Button
                        onClick={() =>
                          formik.setFieldValue("options", [
                            ...formik.values.options,
                            "",
                          ])
                        }
                        leftIcon={<GoPlus className="h-7 w-7" />}
                      >
                        {t("assistantForm.addOption")}
                      </Button>
                    </FormControl>
                  </div>
                )}
                {selectedOption === "openQuestion" && (
                  <div>
                    <FormControl className="mt-4">
                      <FormLabel>
                        {t("assistantForm.lookingFindOut")} *
                      </FormLabel>
                      {/* Render input field for lookingFor */}
                      <input
                        className="w-full appearance-none rounded border-[1px] border-gray-200 px-4 
                  py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                        type="text"
                        id="lookingFor"
                        value={formik.values.lookingFor}
                        onChange={formik.handleChange}
                        name="lookingFor"
                        placeholder={t(
                          "assistantForm.lookingFindOutPlaceholder"
                        )}
                      />
                      {/* Render error message for lookingFor if touched and has error */}
                      {formik.touched.lookingFor && formik.errors.lookingFor ? (
                        <div className="text-red-500">
                          {formik.errors.lookingFor.toString()}
                        </div>
                      ) : null}
                    </FormControl>
                    <FormControl className="mt-4">
                      <FormLabel>
                        {t("assistantForm.OutputExamples")} *
                      </FormLabel>
                      {/* Render input fields for outputExamples */}
                      {formik.values.outputExamples.map(
                        (example: any, index: number) => (
                          <div key={index}>
                            <div className="mb-4 flex gap-2">
                              <input
                                className=" w-full appearance-none rounded border-[1px] border-gray-200 
                      px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                                type="text"
                                value={example}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `outputExamples[${index}]`,
                                    e.target.value
                                  )
                                }
                                placeholder={`${t(
                                  "assistantForm.OutputExamplePlaceholder"
                                )} ${index + 1}`}
                              />
                              {index !== 0 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newOutputExamples = [
                                      ...formik.values.outputExamples,
                                    ];
                                    newOutputExamples.splice(index, 1);
                                    formik.setFieldValue(
                                      "outputExamples",
                                      newOutputExamples
                                    );
                                  }}
                                  className="focus:shadow-outline flex-shrink-0 rounded bg-red-500 px-2 py-2 font-bold text-white hover:bg-red-600 focus:outline-none"
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </div>
                            {formik.touched.outputExamples &&
                            formik.errors.outputExamples ? (
                              <div className="mb-3 text-red-500">
                                {Array.isArray(formik.errors.outputExamples) ? (
                                  formik.errors.outputExamples.map(
                                    (error: any, i: any) =>
                                      i === index ? (
                                        <div key={i}>{error}</div>
                                      ) : null
                                  )
                                ) : (
                                  <div>
                                    {formik.errors.outputExamples.toString()}
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        )
                      )}
                      {/* Render button to add more outputExamples */}
                      <Button
                        onClick={() =>
                          formik.setFieldValue("outputExamples", [
                            ...formik.values.outputExamples,
                            "",
                          ])
                        }
                        leftIcon={<GoPlus className="h-7 w-7" />}
                      >
                        {t("assistantForm.addOutputExample")}
                      </Button>
                    </FormControl>
                  </div>
                )}
                <div className="mt-3 flex items-center justify-end">
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="mr-3 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                    type="submit"
                  >
                    {t("save")}
                  </button>

                  <Button onClick={handleCancelQuestions}>{t("cancel")}</Button>
                </div>
              </div>
            )}
            {tableData && tableData.length === 0 ? (
              <Card className="mt-5">
                <div className="my-auto flex flex-col items-center justify-center">
                  <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                    <TbUserQuestion />
                  </div>
                  <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                    {t("assistantForm.noQuestions")}
                  </h4>
                </div>
              </Card>
            ) : (
              <table className="responsive-table mt-5 w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
                <thead className="text-black-700 border-b-2 bg-gray-50 text-xs uppercase  dark:bg-navy-900 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      {t("assistantForm.identifier")}
                    </th>
                    <th scope="col" className="px-6 py-3" align="center">
                      {t("assistantForm.question")}
                    </th>
                    <th scope="col" className="px-6 py-3" align="center">
                      {t("assistantForm.type")}
                    </th>
                    <th scope="col" className="px-6 py-3" align="center">
                      {t("assistantForm.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item: any, index: number) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                      } ${
                        index % 2 === 0
                          ? "dark:bg-navy-900"
                          : "dark:bg-navy-900"
                      }`}
                    >
                      <td
                        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                        data-label={t("assistantForm.identifier")}
                      >
                        {item?.identifier}
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                        data-label={t("assistantForm.question")}
                      >
                        <p className="flex min-h-[20px] items-center justify-end gap-2 sm:justify-center">
                          {item?.question || item?.lookingFor}
                        </p>
                      </td>
                      <td
                        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                        data-label={t("assistantForm.type")}
                      >
                        <p className="flex min-h-[20px] items-center justify-end gap-2 sm:justify-center">
                          {item?.type}
                        </p>
                      </td>
                      <td
                        className="flex justify-center gap-2 px-6 py-4"
                        data-label={t("assistantForm.actions")}
                      >
                        <div className="flex items-center justify-end gap-2 sm:justify-center">
                          <MdEdit
                            onClick={() => handleEdit(item, index)}
                            className="h-6 w-6 cursor-pointer font-medium text-[#000000] hover:underline dark:text-white"
                          />
                          <MdDelete
                            onClick={() => handleDelete(index)}
                            className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <button
              onClick={() => formikVal.handleSubmit()}
              disabled={tableData.length === 0}
              className={` ${
                tableData.length
                  ? "bg-blue-700 text-white hover:bg-blue-800 active:bg-blue-800"
                  : "bg-[#0000001f] text-[#00000042] dark:bg-gray-300"
              } mr-3 flex items-center justify-center gap-1 rounded px-6 py-2`}
              type="submit"
            >
              {t("assistantForm.create")}
            </button>

            <Button onClick={closeModal}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddInfoExtractorModal;
