import axios, { AxiosRequestConfig } from "axios";

export const submitFeedback = async (
  ratingValue: any,
  feedbackText: any,
  getSelectedOptionsString: any
) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const payload = {
    feedback: feedbackText,
    improvements: getSelectedOptionsString(),
    rating: ratingValue,
  };

  try {
    const response = await axios.post(
      `${baseUrl}/user/feedback`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting feedback:", error);
  }
};
