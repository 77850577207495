/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { MdCampaign, MdEdit, MdOutlineNoteAdd } from "react-icons/md";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { formatDateAmPm } from "utils/utils";
import { useColorMode } from "@chakra-ui/system";
import { IoAddCircle } from "react-icons/io5";
import { AiFillAudio } from "react-icons/ai";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";
const ContactDetailComponent = ({ contactData }: any) => {
  const { t } = useTranslation();
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const { colorMode } = useColorMode();
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );

  const formatStringToHTML = (input: any) => {
    // Replace "- Heading:\n" with <h2> tags
    let formattedString = input
      .replace(/- (.*):\\n/g, "<h2>$1</h2>\n")
      // Replace "- Item\n" with <p> tags and add <br /> for line breaks
      .replace(/- (.*)\\n/g, "<p>$1</p>\n")
      // Replace double line breaks (\\n\\n) with <p><br/></p>
      .replace(/\\n\\n/g, "<p><br/></p>\n")
      // Replace single line breaks (\\n) with <br />
      .replace(/\\n/g, "<br />\n");

    return formattedString;
  };
  return (
    <div>
      <div className="w-full">
      <div className="flex flex-col gap-5 lg:flex-row">
      <div className="flex w-full flex-col gap-1 xxl:w-[50%]">
        <div className="mt-4">
          <label className="text-sm">{t("contacts.jobTitle")}:</label>
          <h3 className="font-bold">
            {contactData?.contact?.job_title || "-"}
          </h3>
        </div>
        <div className="mt-4">
          <label className="text-sm">{t("contactDetail.fullName")}:</label>
          <h3 className="font-bold">
            {contactData?.contact?.first_name}{" "}
            {contactData?.contact?.last_name}
          </h3>
        </div>
        </div>
        <div className="flex w-full flex-col gap-1 xxl:w-[50%]">
          <div className="mt-4">
            <label className="text-sm">{t("contacts.email")}:</label>
            <h3 className="font-bold">{contactData?.contact?.email || "-"}</h3>
          </div>
          <div className="mt-4">
            <label className="text-sm">{t("contacts.phone")}:</label>
            <h3 className="font-bold">{contactData?.contact?.phone || "-"}</h3>
          </div>
        </div>
        </div>
        
      </div>
      <div className="flex w-full gap-1 bg-lightPrimary dark:bg-navy-900 mt-5">
        {contactData?.activities && contactData?.activities.length > 0 ? (
          <VerticalTimeline layout={"1-column-left"}>
            {contactData?.activities &&
              contactData?.activities.map((op: any, i: any) => (
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                  contentStyle={{
                    background: colorMode === "dark" ? "#1b254b" : "#fff",
                    color: colorMode === "dark" ? "#fff" : "#000",
                  }}
                  contentArrowStyle={{
                    borderRight: `7px solid ${
                      colorMode === "dark" ? "#1b254b" : "#fff"
                    }`,
                  }}
                  date={formatDateAmPm(op?.created_date)}
                  iconStyle={{
                    background: "#14bfff",
                    color: "#000",
                  }}
                  icon={
                    op?.type === "UPDATE" ? (
                      <MdEdit color="white" />
                    ) : op?.type === "CREATE" ? (
                      <IoAddCircle color="white" />
                    ) : op?.type === "CALL_NOTES" ? (
                      <MdOutlineNoteAdd color="white" />
                    ) : op?.type === "ADD_TO_CAMPAIGN" ? (
                      <MdCampaign color="white" />
                    ) : (
                      <AiFillAudio color="white" />
                    )
                  }
                  position={"right"}
                >
                  <div>
                    {op.type === "RECORDING" ? (
                      <div className="max-w-[300px]">
                        <WavePlayer
                          audio={
                            op.notes?.startsWith("http://") ||
                            op.notes?.startsWith("https://")
                              ? op.notes
                              : audioUrl + op.notes
                          }
                          index={i}
                          currentPlayingIndex={currentPlayingIndex}
                          setCurrentPlayingIndex={setCurrentPlayingIndex}
                        />
                      </div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatStringToHTML(
                            op?.notes
                              ? String(op.notes)
                                  .replace(/\\n/g, " ")
                                  .replace(/\n/g, " ")
                              : ""
                          ),
                        }}
                      />
                    )}
                  </div>
                </VerticalTimelineElement>
              ))}
          </VerticalTimeline>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {t("contactDetail.noActivityFound")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactDetailComponent;
