/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import OnBoardingStep1 from "components/onboardingSteps/OnBoardingStep1";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OnBoarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/admin/assistants/create-assistant");

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "New Assistant",
        buttonName: " NEW ASSISTANT",
      });
    }
  };
  return (
    <div className="flex h-full w-full max-w-full flex-col items-center justify-start overflow-auto p-2 px-2 pb-10 pt-16 md:mx-0 md:px-0  lg:justify-start">
      <div className="flex justify-center text-center text-4xl font-bold">
        {t("onBoarding.step1Heading")}
      </div>
      <OnBoardingStep1 handleClick={handleClick} />
    </div>
  );
};

export default OnBoarding;
