/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Checkbox,
  Alert,
} from "@chakra-ui/react";
import Card from "components/card";
import { getWhatsAppTemp, whatsAppConfiguration } from "services/integrations";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const EditSendMessageModal = ({
  isOpen,
  handleClose,
  setActionData,
  sendMessageData,
  actionData,
}: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isWhatappIntegration, setIsWhatappIntegration] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  useEffect(() => {
    fetchWhatsAppTemp();
    fetchWhatsAppConfig();
  }, [isOpen]);

  const validationSchema = Yup.object().shape({
    is_sms: Yup.boolean(),
    smsDescription: Yup.string().when(["is_sms"], ([is_sms], schema) => {
      if (is_sms) {
        return Yup.string().required(t("assistantForm.conditionValid"));
      }
      return schema;
    }),
    smsContent: Yup.string().when(["is_sms"], ([is_sms], schema) => {
      if (is_sms) {
        return Yup.string().required(t("assistantForm.contentValid"));
      }
      return schema;
    }),
    end_call_sms: Yup.boolean(),
    is_whatsapp: Yup.boolean(),
    description: Yup.string().when(["is_whatsapp"], ([is_whatsapp], schema) => {
      if (is_whatsapp) {
        return Yup.string().required(t("assistantForm.templateValid"));
      }
      return schema;
    }),
    content: Yup.string().when(["is_whatsapp"], ([is_whatsapp], schema) => {
      if (is_whatsapp) {
        return Yup.string().required(t("assistantForm.contentValid"));
      }
      return schema;
    }),
    end_call_whatsapp: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      is_sms: sendMessageData?.sms?.is_sms,
      smsContent: sendMessageData?.sms?.content || "",
      smsDescription: sendMessageData?.sms?.description || "",
      end_call_sms: sendMessageData?.sms?.end_call_sms || false,
      content: "",
      description: sendMessageData?.whatsapp?.description || "",
      end_call_whatsapp: sendMessageData?.whatsapp?.end_call_sms || false,
      is_whatsapp: sendMessageData?.whatsapp?.is_whatsapp,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let whatsAppTemp: any = "";
      if (values?.content) {
        whatsAppTemp = templateList.find((item) => item.id === values?.content);
      }
      let sendMessageData = {
        type: "sendMessage",
        say: "sending you the message",
        sms: {
          description: values?.is_sms ? values?.smsDescription : "",
          content: values?.is_sms ? values?.smsContent : "",
          end_call_sms: values?.end_call_sms,
          is_sms: values?.is_sms,
        },
        whatsapp: {
          description: values?.is_whatsapp ? values?.description : "",
          content: values?.is_whatsapp ? whatsAppTemp : null,
          end_call_sms: values?.is_whatsapp ? values?.end_call_whatsapp : false,
          is_whatsapp: values?.is_whatsapp,
        },
      };
      let deletedData = actionData.filter(
        (item: any) => item.type !== "sendMessage"
      );
      setActionData(() => [...deletedData, sendMessageData]);
      handleClose();
    },
  });
  const fetchWhatsAppTemp = () => {
    setIsLoading(true);
    getWhatsAppTemp()
      .then((response) => {
        setIsLoading(false);
        if (response?.success) {
          if (response?.data) {
            const approvedList = response?.data.filter(
              (item: any) =>
                item.status === "APPROVED" &&
                !item.components.some(
                  (component: any) => component.example !== undefined
                )
            );
            let contentTempId = "";
            if (sendMessageData?.whatsapp?.content) {
              let parseData = sendMessageData?.whatsapp?.content;
              contentTempId = parseData.id;
            }
            formik.setFieldValue("content", contentTempId);
            setTemplateList(approvedList);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const fetchWhatsAppConfig = () => {
    whatsAppConfiguration()
      .then((response) => {
        if (response?.success) {
          if (response?.data && response?.data.length > 0) {
            setIsWhatappIntegration(true);
            formik.setFieldValue("is_whatsapp", true);
          } else {
            setIsWhatappIntegration(false);
            formik.setFieldValue("is_whatsapp", false);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (sendMessageData) {
      formik.setValues({
        is_sms: sendMessageData?.sms?.is_sms,
        smsContent: sendMessageData?.sms?.content || "",
        smsDescription: sendMessageData?.sms?.description || "",
        end_call_sms: sendMessageData?.sms?.end_call_sms || false,
        // content: sendMessageData?.whatsapp?.content || "",
        description: sendMessageData?.whatsapp?.description || "",
        end_call_whatsapp: sendMessageData?.whatsapp?.end_call_sms || false,
        is_whatsapp: sendMessageData?.whatsapp?.is_whatsapp,
      });
    }
  }, [sendMessageData]);
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"md"}
        blockScrollOnMount={false}
      >
        {isLoading && <Loader />}
        <DrawerOverlay />
        <DrawerContent maxW={"600px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("assistantForm.sendSMSWhatsApp")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormLabel color={"gray"}>
              {t("assistantForm.smsWCaption")}
            </FormLabel>
            <Card extra={"w-full p-4"}>
              <FormControl mt={2}>
                <div className="mb-2">
                  <div>
                    <Checkbox
                      id="is_sms"
                      colorScheme="cyan"
                      onChange={formik.handleChange}
                      isChecked={formik.values.is_sms}
                    >
                      {t("assistantForm.sms")}
                    </Checkbox>
                  </div>
                  <div className="mt-2">
                    {formik.values.is_sms && (
                      <Alert status="warning">
                        {t("assistantForm.smsLabel")}
                      </Alert>
                    )}
                  </div>
                </div>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>{t("assistantForm.smsContent")} *</FormLabel>
                <textarea
                  className="max-h-[150px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="smsContent"
                  rows={4}
                  value={formik.values.smsContent?.replace(/\\n|\\/g, " ")}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      formik.setFieldValue("end_call_sms", false);
                    }
                    formik.setFieldValue("smsContent", e.target.value);
                  }}
                  disabled={!formik.values.is_sms}
                  placeholder={t("assistantForm.smsContentPlaceholder")}
                />
                {formik.touched.smsContent && formik.errors.smsContent ? (
                  <div className="text-red-500">
                    {formik.errors.smsContent.toString()}
                  </div>
                ) : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>{t("assistantForm.conditionText")} *</FormLabel>
                <input
                  className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="smsDescription"
                  type="text"
                  value={formik.values.smsDescription?.replace(/\\n|\\/g, " ")}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      formik.setFieldValue("end_call_sms", false);
                    }
                    formik.setFieldValue("smsDescription", e.target.value);
                  }}
                  disabled={!formik.values.is_sms}
                  placeholder={t("assistantForm.conditionTextPlaceholder")}
                />
                {formik.touched.smsDescription &&
                formik.errors.smsDescription ? (
                  <div className="text-red-500">
                    {formik.errors.smsDescription.toString()}
                  </div>
                ) : null}
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="end_call_sms"
                  colorScheme="cyan"
                  onChange={formik.handleChange}
                  isChecked={formik.values.end_call_sms}
                  disabled={
                    !formik.values.smsContent ||
                    !formik.values.smsDescription ||
                    !formik.values.is_sms
                  }
                >
                  {t("assistantForm.smsCallEnds")}
                </Checkbox>
              </FormControl>
            </Card>
            <Card extra={"w-full p-4 mt-4"}>
              <FormControl mt={2}>
                {!isWhatappIntegration && (
                  <div className="flex items-center justify-end text-blue-700">
                    {t("assistantForm.configWhatsapp")}
                  </div>
                )}
                <div className="mb-2">
                  <div>
                    <Checkbox
                      id="is_whatsapp"
                      colorScheme="cyan"
                      onChange={formik.handleChange}
                      isChecked={formik.values.is_whatsapp}
                      isDisabled={!isWhatappIntegration}
                    >
                      {t("assistantForm.whatsApp")}
                    </Checkbox>
                  </div>
                  <div className="mt-2">
                    {formik.values.is_whatsapp && (
                      <Alert status="warning">
                        {t("assistantForm.whatsAppLabel")}
                      </Alert>
                    )}
                  </div>
                </div>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>{t("assistantForm.whatsAppTemplate")} *</FormLabel>
                <select
                  className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="content"
                  value={formik.values.content}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      formik.setFieldValue("end_call_sms", false);
                    }
                    formik.setFieldValue("content", e.target.value);
                  }}
                  disabled={!formik.values.is_whatsapp}
                >
                  <option value="">{t("assistantForm.selectTemp")}</option>
                  {templateList.map((op, i) => (
                    <option key={i} value={op.id}>
                      {op.name}
                    </option>
                  ))}
                </select>
                {/* <textarea
                  className="max-h-[150px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="content"
                  rows={4}
                  value={formik.values.content?.replace(/\\n|\\/g, " ")}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      formik.setFieldValue("end_call_sms", false);
                    }
                    formik.setFieldValue("content", e.target.value);
                  }}
                  placeholder="Ex. : Hello, thanks for your interest in a demo meeting. you can book your session here: [Booking Link]."
                /> */}
                {formik.touched.content && formik.errors.content ? (
                  <div className="text-red-500">
                    {formik.errors.content.toString()}
                  </div>
                ) : null}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>{t("assistantForm.conditionText")} *</FormLabel>
                <input
                  className="h-[50px] w-full appearance-none rounded border-[1px] border-gray-500 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="description"
                  type="text"
                  value={formik.values.description?.replace(/\\n|\\/g, " ")}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      formik.setFieldValue("end_call_sms", false);
                    }
                    formik.setFieldValue("description", e.target.value);
                  }}
                  disabled={!formik.values.is_whatsapp}
                  placeholder={t("assistantForm.conditionTextPlaceholder")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="text-red-500">
                    {formik.errors.description.toString()}
                  </div>
                ) : null}
              </FormControl>
              <FormControl mt={4}>
                <Checkbox
                  id="end_call_whatsapp"
                  colorScheme="cyan"
                  onChange={formik.handleChange}
                  isChecked={formik.values.end_call_whatsapp}
                  disabled={
                    !formik.values.content ||
                    !formik.values.description ||
                    !formik.values.is_whatsapp
                  }
                >
                  {t("assistantForm.whatsappCallEnds")}
                </Checkbox>
              </FormControl>
            </Card>

            {!formik.values.is_sms && !formik.values.is_whatsapp && (
              <div className="mt-4 text-red-500">
                {t("assistantForm.selectOneOption")}
              </div>
            )}
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={handleClose}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditSendMessageModal;
