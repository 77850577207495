export const templateData: any = [
  {
    name: "leadGeneration",
    isSelected: false,
  },
  {
    name: "customerEngagement",
    isSelected: false,
  },
  {
    name: "appointmentSetting",
    isSelected: false,
  },
  {
    name: "feedbackSurvey",
    isSelected: false,
  },
  {
    name: "salesUpselling",
    isSelected: false,
  },
  {
    name: "retentionWinback",
    isSelected: false,
  },
  {
    name: "marketResearch",
    isSelected: false,
  },
  {
    name: "complianceVerification",
    isSelected: false,
  },
  {
    name: "customGenerativeAI",
    isSelected: false,
  },
];
