/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Switch,
  useToast,
  Input,
} from "@chakra-ui/react";
import { createCampaign } from "services/campaign";
import { getContactList } from "services/contact";
import { getPhoneNumbers } from "services/phoneNumbers";
import { getAssitantList } from "services/assitant";
import Loader from "components/Loader/Loader";
import SaveConfirmModal from "./SaveConfirmModal";
import { responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";
import TooltipHorizon from "components/tooltip";
import { FaInfoCircle } from "react-icons/fa";
let retryOptions = [
  {
    label: "15 min",
    value: "15",
  },
  {
    label: "30 min",
    value: "",
  },
  {
    label: "1 hour",
    value: "60",
  },
  {
    label: "90 min",
    value: "90",
  },
  {
    label: "180 min",
    value: "180",
  },
  {
    label: "1 day",
    value: "1440",
  },
  {
    label: "2 day",
    value: "2880",
  },
];
const AddCampaignsModal = ({
  isOpen,
  handleClose,
  setRefresh,
  refresh,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [assistantList, setAssistantList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [retryDisable, setRetryDisable] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  let assistantValidation = Yup.string();
  if (assistantList?.length === 0) {
    assistantValidation = assistantValidation.required(
      t("campaign.assistantValid")
    );
  }
  let phoneNumbersValidation = Yup.string();
  if (phoneNumbers?.length === 0) {
    phoneNumbersValidation = phoneNumbersValidation.required(
      t("campaign.phoneValid")
    );
  }
  let contactValidation = Yup.string();
  if (contactList?.length === 0) {
    contactValidation = contactValidation.required(t("campaign.segmentValid"));
  }
  const validationSchema = Yup.object().shape({
    campaignName: Yup.string().required(t("campaign.campaignValid")),
    assistant: assistantValidation,
    phoneNumbers: phoneNumbersValidation,
    contact: contactValidation,
    scheduled: Yup.boolean(),
    scheduled_at: Yup.string().when("scheduled", ([scheduled], schema) => {
      if (scheduled)
        return Yup.string()
          .required(t("campaign.scheduleDateValid"))
          .test("is-future-date", t("campaign.scheduleDateValid1"), (value) => {
            const selectedDate = new Date(value);
            const currentDate = new Date();
            return selectedDate > currentDate;
          });
      return schema;
    }),
    retry: Yup.boolean(),
    retriesNumber: Yup.string().when("retry", ([retry], schema) => {
      if (retry)
        return Yup.number()
          .min(1, t("campaign.retryValid"))
          .required(t("campaign.retryValid1"));
      return schema;
    }),
    retryTime: Yup.string().when("retry", ([retry], schema) => {
      if (retry) return Yup.string().required(t("campaign.retryValid2"));
      return schema;
    }),
  });
  useEffect(() => {
    getPhoneNumbers()
      .then((response) => {
        responseHendler(response);
        if (response.success) {
          setPhoneNumbers(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    let param = {
      page: -1,
      perpage: 10,
      search: "",
    };
    getContactList(param)
      .then((response: any) => {
        responseHendler(response);
        if (response.success) {
          setContactList(response?.data);
        }
      })
      .catch((error: string) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    getAssitantList()
      .then((response: any) => {
        if (response.success) {
          if (response?.data) {
            let itemData = response?.data.find(
              (item: any) => item.id === response?.data[0]?.id
            );
            if (!itemData.is_voice_note) {
              setRetryDisable(true);
              formik.setFieldValue("retry", false);
              formik.setFieldValue("retriesNumber", "");
              formik.setFieldValue("retryTime", "");
            } else {
              setRetryDisable(false);
            }
          }
          setAssistantList(response?.data);
        }
      })
      .catch((error: string) => {
        console.error("Error:", error);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      campaignName: "",
      assistant: "",
      phoneNumbers: "",
      contact: "",
      is_automation: false,
      scheduled: false,
      scheduled_at: "",
      retry: false,
      retriesNumber: "",
      retryTime: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let scheduledDt = "";
      if (values.scheduled_at) {
        const inputDate = new Date(values.scheduled_at);
        scheduledDt = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1)
          .toString()
          .padStart(
            2,
            "0"
          )}-${inputDate.getDate()} ${inputDate.getHours()}:${inputDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      }
      let createCampaignPayload = {
        campaigns_name: values.campaignName,
        assistant_id: values.assistant ? values.assistant : assistantList[0].id,
        segment_id: values.contact ? values.contact : contactList[0].id,
        dialer_id: values.phoneNumbers
          ? values.phoneNumbers
          : phoneNumbers[0].id,
        is_automation: values.is_automation,
        scheduled_at: values.scheduled ? new Date(scheduledDt) : "",
        retry_count: values.retry ? Number(values.retriesNumber) : 1,
        retry_time: values.retry ? Number(values.retryTime) : null,
      };
      setLoading(true);
      createCampaign(createCampaignPayload)
        .then((response: any) => {
          if (response.success) {
            setLoading(false);
            setRefresh(!refresh);
            closeModal();
            toast({
              description: t("messages.CAMPAIGN_CREATED"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            formik.resetForm();
          } else {
            setLoading(false);
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: string) => {
          setLoading(false);
          console.error("Error:", error);
        });
    },
  });

  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };
  const handleChangeScheduled = (e: any) => {
    formik.setFieldValue("scheduled_at", e.target.value);
  };
  const handleChangeAssistant = (e: any) => {
    let itemData = assistantList.find(
      (item: any) => item.id === e.target.value
    );
    if (!itemData.is_voice_note) {
      setRetryDisable(true);
      formik.setFieldValue("retry", false);
      formik.setFieldValue("retriesNumber", "");
      formik.setFieldValue("retryTime", "");
    } else {
      setRetryDisable(false);
    }
    formik.setFieldValue("assistant", e.target.value);
  };
  const checkIsDirtyForm = () => {
    let isDirty = formik.dirty && formik.submitCount === 0;
    if (isDirty) {
      openWarningModal();
    } else {
      closeModal();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    closeModal();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        size={"sm"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("campaign.createCampaign")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            {loading && <Loader />}
            <FormControl>
              <FormLabel>{t("campaign.campaignName")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="campaignName"
                type="text"
                value={formik.values.campaignName}
                onChange={formik.handleChange}
              />
              {formik.touched.campaignName && formik.errors.campaignName ? (
                <div className="text-red-500">
                  {formik.errors.campaignName.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("campaign.voiceAssistant")} *</FormLabel>

              <select
                id="assistant"
                value={formik.values.assistant}
                onChange={(e) => handleChangeAssistant(e)}
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                {assistantList?.map((assistant, index) => (
                  <option key={index} value={assistant.id}>
                    {assistant.name}
                  </option>
                ))}
              </select>
              {formik.touched.assistant && formik.errors.assistant ? (
                <div className="text-red-500">
                  {formik.errors.assistant.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("campaign.dialer")} *</FormLabel>

              <select
                value={formik.values.phoneNumbers}
                onChange={formik.handleChange}
                id="phoneNumbers"
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                {phoneNumbers?.map((phoneNumber, index) => (
                  <option key={index} value={phoneNumber.id}>
                    {phoneNumber.phone_number}
                  </option>
                ))}
              </select>
              {formik.touched.phoneNumbers && formik.errors.phoneNumbers ? (
                <div className="text-red-500">
                  {formik.errors.phoneNumbers.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("campaign.segmentList")} *</FormLabel>

              <select
                value={formik.values.contact}
                onChange={formik.handleChange}
                id="contact"
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                {contactList?.map((contact, index) => (
                  <option key={index} value={contact.id}>
                    {contact.name}
                  </option>
                ))}
              </select>
              {formik.touched.contact && formik.errors.contact ? (
                <div className="text-red-500">
                  {formik.errors.contact.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={4}
            >
              <FormLabel>
                <span className="flex items-center">
                  {t("campaign.automation")} 
                  <TooltipHorizon
                    trigger={
                      <span>
                        <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                      </span>
                    }
                    placement="top"
                    content={t("automationInfo")}
                    extra="rounded text-[11px]"
                  />
                </span>
              </FormLabel>
              <Switch
                id="is_automation"
                sx={{
                  "& input:checked ~ .chakra-switch__track": {
                    bg: "#14bfff",
                  },
                  "& input:checked ~ .chakra-switch__thumb": {
                    bg: "#14bfff",
                  },
                }}
                size="md"
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={4}
            >
              <FormLabel>
                <span className="flex items-center">
                  {t("campaign.scheduledTxt")} 
                  <TooltipHorizon
                    trigger={
                      <span>
                        <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                      </span>
                    }
                    placement="top"
                    content={t("scheduledInfo")}
                    extra="rounded text-[11px]"
                  />
                </span>
              </FormLabel>
              <Switch
                id="scheduled"
                sx={{
                  "& input:checked ~ .chakra-switch__track": {
                    bg: "#14bfff",
                  },
                  "& input:checked ~ .chakra-switch__thumb": {
                    bg: "#14bfff",
                  },
                }}
                size="md"
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.values.scheduled && (
              <FormControl mt={4}>
                <FormLabel className="flex items-center">{t("campaign.scheduledDT")}</FormLabel>
                <Input
                  value={formik.values.scheduled_at}
                  onChange={handleChangeScheduled}
                  id="scheduled_at"
                  name="scheduled_at"
                  type="datetime-local"
                  className="datepicker_custom w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                />
                {formik.touched.scheduled_at && formik.errors.scheduled_at ? (
                  <div className="text-red-500">
                    {formik.errors.scheduled_at.toString()}
                  </div>
                ) : null}
              </FormControl>
            )}
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={4}
            >
              <FormLabel>
                <span className="flex items-center">
                  {t("campaign.retry")} 
                  <TooltipHorizon
                    trigger={
                      <span>
                        <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                      </span>
                    }
                    placement="top"
                    content={t("retryInfo")}
                    extra="rounded text-[11px]"
                  />
                </span>
              </FormLabel>
              <Switch
                id="retry"
                sx={{
                  "& input:checked ~ .chakra-switch__track": {
                    bg: "#14bfff",
                  },
                  "& input:checked ~ .chakra-switch__thumb": {
                    bg: "#14bfff",
                  },
                }}
                size="md"
                onChange={formik.handleChange}
                disabled={retryDisable}
                isChecked={formik.values.retry}
              />
            </FormControl>
            {formik.values.retry && (
              <FormControl mt={4}>
                <FormLabel>{t("campaign.retryNo")} *</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                  py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="retriesNumber"
                  type="number"
                  value={formik.values?.retriesNumber}
                  onChange={formik.handleChange}
                />
                {formik.touched.retriesNumber && formik.errors.retriesNumber ? (
                  <div className="text-red-500">
                    {formik.errors.retriesNumber.toString()}
                  </div>
                ) : null}
              </FormControl>
            )}
            {formik.values.retry && (
              <FormControl mt={4}>
                <FormLabel>{t("campaign.retryTime")} *</FormLabel>

                <select
                  value={formik.values.retryTime}
                  onChange={formik.handleChange}
                  id="retryTime"
                  className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                >
                  <option value={""}>{t("campaign.selectRetryTime")}</option>
                  {retryOptions.map((op, i) => (
                    <option key={i} value={op.value}>
                      {op.label}
                    </option>
                  ))}
                </select>
                {formik.touched.retryTime && formik.errors.retryTime ? (
                  <div className="text-red-500">
                    {formik.errors.retryTime.toString()}
                  </div>
                ) : null}
              </FormControl>
            )}
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default AddCampaignsModal;
