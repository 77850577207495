/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import Footer from "components/footer/FooterAuthDefault";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyUser } from "services/Auth";
import { gtag } from "utils/utils";

const Verify = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [data, setData] = useState(null);
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const code = searchParams && searchParams.get("code");

  useEffect(() => {
    if (code) {
      verifyUser(code)
        .then((response) => {
          if (response?.success) {
            setData(response?.success);
          } else {
            toast({
              description: response?.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error :", error);
        });
    }

    // gtag('event', 'conversion', {'send_to': 'AW-16575539394/4mDTCJLLgLQZEMLR6t89'});
    gtag("event", "conversion", {
      send_to: "AW-16575539394/4mDTCJLLgLQZEMLR6t89",
    });
  }, [code]);
  return (
    <div className="mb-16 mt-16 flex h-full w-full max-w-full flex-col items-center justify-start px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      {data && (
        <div className="flex h-full flex-col items-center justify-center">
          <h1 className="mb-4 flex items-center gap-2 text-4xl font-bold text-green-600">
            <FaCheckCircle /> {t("congratulationsText")}
          </h1>
          <p className="mb-8  text-2xl font-bold text-gray-600">
            {t("userVerifiedSuccessfulText")}
          </p>
          <button
            onClick={() => navigate("/auth/sign-in")}
            className="linear mt-2 rounded bg-blue-700 px-[12px] py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
          >
            {t("gotoLoginText")}
          </button>
        </div>
      )}
      <div className="mt-5 flex items-center">
        <Footer />
      </div>
    </div>
  );
};

export default Verify;
