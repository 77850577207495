/* eslint-disable react-hooks/exhaustive-deps */
// import authImg from "assets/img/auth/auth.png";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useRoutes from "routes";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
// import { useEffect, useState } from "react";
// import Footer from "components/footer/FooterAuthDefault";

export default function Warning() {
  const location = useLocation();
  const routes = useRoutes();
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState(true);
  let appData: any = JSON.parse(localStorage.getItem("appSetting") || "{}");
  useEffect(() => {
    const fetchConfig = async () => {
      setIsWarning(true);
      try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/getSettings`); // Replace with your API URL
        const data = await response.json();
        if (data.success) {
          if (data?.data && data?.data.length > 0) {
            localStorage.setItem("appSetting", JSON.stringify(data?.data[0]));
            // Update meta description
            const metaDescription = document.querySelector(
              "meta[name='description']"
            );
            if (metaDescription) {
              metaDescription.setAttribute(
                "content",
                data?.data[0].website_description
              );
            } else {
              const newMeta = document.createElement("meta");
              newMeta.name = "description";
              newMeta.content = data?.data[0].website_description;
              document.head.appendChild(newMeta);
            }
            const linkFavicon = document.querySelector("link[rel='icon']");
            if (linkFavicon) {
              (linkFavicon as HTMLLinkElement).href =
                process.env.REACT_APP_URL_FOR_AUDIO +
                data?.data[0].favicon_logo;
            } else {
              const newFavicon = document.createElement("link");
              newFavicon.rel = "icon";
              newFavicon.href =
                process.env.REACT_APP_URL_FOR_AUDIO +
                data?.data[0].favicon_logo;
              document.head.appendChild(newFavicon);
            }
            navigate("/");
          }
        } else {
          localStorage.setItem("appSetting", JSON.stringify({}));
        }
        setTimeout(() => {
          setIsWarning(false);
        }, 500);
      } catch (err) {
        localStorage.setItem("appSetting", JSON.stringify({}));
        setIsWarning(false);
      }
    };

    fetchConfig();
  }, []);
  useEffect(() => {
    setDocTitle(routes);
  }, [location.pathname]);
  useEffect(() => {
    let isThemeChange = localStorage.getItem("isThemeChange");
    let themeMode = localStorage.getItem("themeMode");
    if (isThemeChange) {
      if (themeMode === "dark") {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    }
  }, []);
  const setDocTitle = (routes: RoutesType[]): string | boolean => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout) !== -1) {
        document.title = `${routes[i].name} | ${appData?.website_name || ""}`;
      }
    }
    return;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/server") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      {isWarning ? (
        <Loader />
      ) : (
        <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
          <FixedPlugin />
          <main className={`mx-auto min-h-screen`}>
            <div className="relative flex">
              <div
                className={`flex min-h-full w-full flex-col justify-start pt-12 md:w-[100%] lg:h-screen lg:w-[100%] lg:pt-0 xl:h-[100vh]  xl:px-0`}
              >
                <div className="mb-auto flex h-full flex-col overflow-auto pl-5 pr-5 md:pl-12 md:pr-12 lg:px-8 xl:max-w-full">
                  <div className="mx-auto">
                    <Routes>
                      {getRoutes(routes)}
                      <Route
                        path="/"
                        element={<Navigate to="/warning" replace />}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}
