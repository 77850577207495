/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Card from "components/card";
import { useTranslation } from "react-i18next";

const InfoExtractorTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || {};
  const [exportType, setExportType] = useState("csv");
  useEffect(() => {
    localStorage.setItem("campaignName", receivedData?.rowData?.campaigns_name);
  }, [receivedData]);
  const exportData = () => {
    const tableData = receivedData?.tableData || [];
    const headers = [
      "Name",
      "Phone Number",
      ...tableData[0]?.extracted_info?.map((info: any) => info?.question),
    ];

    const data = tableData.map((item: any) => [
      item?.name,
      item?.phoneNumber,
      ...item?.extracted_info?.map((info: any) => info.answer),
    ]);

    if (exportType === "csv" || exportType === "xlsx") {
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      if (exportType === "csv") {
        XLSX.writeFile(
          workbook,
          `${receivedData?.rowData?.campaigns_name}.csv`,
          { bookType: "csv" }
        );
      } else {
        XLSX.writeFile(
          workbook,
          `${receivedData?.rowData?.campaigns_name}.xlsx`,
          { bookType: "xlsx" }
        );
      }
    } else if (exportType === "pdf") {
      const doc = new jsPDF();
      (doc as any).autoTable({
        head: [headers],
        body: data,
      });
      doc.save(`${receivedData?.rowData?.campaigns_name}.pdf`);
    }
  };

  return (
    <div>
      <div className="mb-2">
        <div className="mb-3 mt-4 flex justify-end gap-2">
          <button
            onClick={() =>
              navigate("/admin/campaigns-detail", {
                state: { rowData: receivedData?.rowData },
              })
            }
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
          <select
            value={exportType}
            onChange={(e) => setExportType(e.target.value)}
            className="w-fit rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
          >
            <option value={"pdf"}>PDF</option>
            <option value={"csv"}>CSV</option>
            <option value={"xlsx"}>XLSX</option>
          </select>
          <button
            onClick={exportData}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 uppercase text-white hover:bg-blue-800"
          >
            {t("billing.download")}
          </button>
        </div>
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mt-1 overflow-x-auto">
          <table className="responsive-table-info w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("campaign.name")}
                    </p>
                  </div>
                </th>
                <th
                  scope="col"
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                >
                  {" "}
                  <div className="items-center justify-between text-xs text-gray-200">
                    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                      {t("campaign.phone")}
                    </p>
                  </div>
                </th>
                {receivedData?.tableData[0]?.extracted_info?.map(
                  (infoItem: any, i: number) => (
                    <th
                      scope="col"
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      key={i}
                    >
                      {" "}
                      <div className="items-center justify-between text-xs text-gray-200">
                        <p className="flex items-center justify-center whitespace-nowrap text-sm font-bold text-gray-600 dark:text-white">
                          {infoItem?.question}
                        </p>
                      </div>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {receivedData?.tableData &&
                receivedData?.tableData?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("campaign.name")}
                    >
                      <p className="flex items-center gap-2 sm:justify-center">
                        {item?.name}
                      </p>
                    </td>
                    <td
                      className="min-w-[150px] border-white/0 py-3 pr-4"
                      data-label={t("campaign.phone")}
                    >
                      <p className="flex items-center gap-2 sm:justify-center">
                        {item?.phoneNumber}
                      </p>
                    </td>
                    {item?.extracted_info?.map((infoItem: any, i: number) => (
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={infoItem?.question}
                        key={i}
                      >
                        <p className="flex items-center gap-2 sm:justify-center">
                          {Array.isArray(infoItem?.answer)
                            ? infoItem.answer.join(", ")
                            : infoItem?.answer
                            ? infoItem?.answer
                            : "NA"}
                        </p>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default InfoExtractorTable;
