/* eslint-disable react-hooks/exhaustive-deps */
// Chakra Imports
// Custom Icons
import { useColorMode } from "@chakra-ui/system";
import React, { useEffect } from "react";

import { RiMoonFill, RiSunFill } from "react-icons/ri";
export default function FixedPlugin(props: { [s: string]: any }) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );
  useEffect(() => {
    if (colorMode === "dark" || darkmode) {
      document.body.classList.add("dark");
      setDarkmode(true);
    } else {
      document.body.classList.remove("dark");
      setDarkmode(false);
    }
  }, [colorMode]);

  useEffect(() => {
    let isThemeChange = localStorage.getItem("isThemeChange");
    if (
      !isThemeChange &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.body.classList.add("dark");
      setDarkmode(true);
      if (colorMode === "light") {
        toggleColorMode();
      }
    }
  }, []);

  return (
    <button
      className="border-px fixed bottom-[20px] left-[35px] !z-[99] flex h-[40px] w-[40px] items-center justify-center rounded-full border-[#6a53ff] bg-gradient-to-br from-blue-300 to-blue-700 p-0 sm:h-[60px] sm:w-[60px]"
      onClick={() => {
        localStorage.setItem("isThemeChange", "true");
        if (darkmode) {
          document.body.classList.remove("dark");
          setDarkmode(false);
          localStorage.setItem("themeMode", "light");
        } else {
          document.body.classList.add("dark");
          setDarkmode(true);
          localStorage.setItem("themeMode", "dark");
        }
        toggleColorMode();
      }}
      {...rest}
    >
      {/* // left={document.documentElement.dir === "rtl" ? "35px" : ""}
      // right={document.documentElement.dir === "rtl" ? "" : "35px"} */}
      <div className="cursor-pointer text-gray-600">
        {colorMode === "dark" ? (
          <RiSunFill className="h-4 w-4 text-white" />
        ) : (
          <RiMoonFill className="h-4 w-4 text-white" />
        )}
      </div>
    </button>
  );
}
