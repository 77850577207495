import React, { useState } from "react";
import SmtpSetup from "./smtpSetup";
import SMTPList from "./smtpTemplates";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SmtpComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("smtpList");
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);

    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };
  return (
    <>
      <div className="mb-2">
        <div className="mt-2 flex justify-start gap-4 py-2">
          <button
            onClick={() => navigate("/admin/integrations")}
            className="flex h-[46px] items-center justify-center gap-1 rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>

      <div className="m-auto max-w-[900px]">
        {/* <div className="text-[25px] font-bold">SMTP Integration</div> */}
        {/* <div className="my-[10px] text-[15px] font-medium text-gray-400">
        Take your journey to the next level with add-ons and integrations.
      </div> */}
        <div className="mb-2 mt-[10px] flex justify-center pt-5">
          <div className="mb-4 w-full border-b-[2px] border-gray-200 dark:border-gray-700">
            <ul
              className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "smtpList"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("smtpList")}
                  type="button"
                  role="tab"
                  aria-controls="styled-dashboard"
                  aria-selected={activeTab === "smtpList"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                    {t("integrations.smtp.smtpTemplate")}
                  </div>
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-1 ${
                    activeTab === "smtpSetup"
                      ? "border-b-2 border-blue-700 text-blue-700"
                      : " text-gray-600"
                  }`}
                  onClick={() => handleTabClick("smtpSetup")}
                  type="button"
                  role="tab"
                  aria-controls="styled-profile"
                  aria-selected={activeTab === "smtpSetup"}
                >
                  <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                    {t("integrations.smtp.smtpConfig")}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        {activeTab === "smtpList" ? <SMTPList /> : <SmtpSetup />}
      </div>
    </>
  );
};

export default SmtpComponent;
