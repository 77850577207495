import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import WarningLayout from "layouts/warning";
import { ConfirmProvider } from "hooks/confirm/confirm.provider";
import "react-vertical-timeline-component/style.min.css";
import "react-quill/dist/quill.snow.css";
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="server/*" element={<WarningLayout />} />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </>
  )
);
const App = () => {
  return (
    <ConfirmProvider>
      <RouterProvider
          fallbackElement={<div>Loading...</div>}
          router={router}
        />
    </ConfirmProvider>
  );
};

export default App;
