/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "components/Loader/Loader";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import { MdCheckCircle, MdError } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentSuccess } from "services/pricing";

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const code = searchParams && searchParams.get("code");

  useEffect(() => {
    paymentSuccess(code)
      .then((response) => {
        if (response?.success) {
          setData(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  }, []);

  const handleBackToHome = () => {
    navigate("/admin/dashboard");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {data?.success ? (
        <Card extra={"w-full h-full p-4 mt-10 bg-green-100 shadow-md"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="rounded-lg p-8">
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-green-200 p-2">
                  <MdCheckCircle className="animate-pulse text-5xl text-green-500" />
                </div>
                <h2 className="text-3xl font-bold text-green-700">
                  Payment Successful!
                </h2>
              </div>
              <h2 className="mt-2 text-center text-2xl font-bold text-gray-600">
                Amount added to your wallet.
              </h2>
              <div
                onClick={handleBackToHome}
                className="m-auto mt-4 w-fit cursor-pointer rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600"
              >
                Back to Home
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full h-full p-4 mt-10 bg-red-100 shadow-md"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="rounded-lg p-8">
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-red-200 p-2">
                  <MdError className="animate-pulse text-5xl text-red-500" />
                </div>
                <h2 className="text-3xl font-bold text-red-700">
                  Payment Failed
                </h2>
              </div>
              <h2 className="mt-2 text-center text-2xl font-bold text-gray-600">
                Please try again later.
              </h2>
              <div
                onClick={handleBackToHome}
                className="m-auto mt-4 w-fit cursor-pointer rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Back to Home
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default PaymentSuccess;
