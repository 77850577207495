import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  // useColorMode,
} from "@chakra-ui/react";
import CallTranscribeModal from "./CallTranscribeModal";
import { useTranslation } from "react-i18next";
import ContactDetailModal from "./ContactDetailModal";
const PlayGroundDetailModal = ({
  isOpen,
  handleClose,
  playgroundData,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  
  const openTranscribeModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseTranscribe = () => {
    setIsModalOpen(false);
  };
  const replacePlaceholders = (template: any, replacements: any) => {
    if (template === "") {
      return "";
    }
    return template.replace(
      /@\[(.+?)\]\((.+?)\)/g,
      (match: any, p1: any, p2: any) => {
        return replacements[p2] || match;
      }
    );
  };
  const goToContactDetailPage = () => {
    setContactModalOpen(true);
  }
  const handleCloseContact = () => {
    setContactModalOpen(false);
  }
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader>{playgroundData?.phone_number}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div className="absolute right-[60px] top-[16px] flex justify-end gap-2">
              <button
                onClick={() => goToContactDetailPage()}
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                {t("playground.contactDetail")}
              </button>
              {playgroundData?.call_transcribe &&
                playgroundData?.answered_by !== "robot" &&
                playgroundData?.newStatus !== "Failed" && (
                  <button
                    onClick={() => openTranscribeModal()}
                    className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                  >
                    {t("playground.callTranscribe")}
                  </button>
                )}
            </div>
            <div>
              <div>
                <p className="mt-3 text-xl font-bold dark:text-white">
                  {t("playground.startSpeech")}
                </p>
                <p className="mt-2">
                  {playgroundData?.start_speech
                    ? replacePlaceholders(playgroundData?.start_speech || "", {
                        firstName: playgroundData?.first_name,
                        lastName: playgroundData?.last_name,
                      })
                    : "-"}
                </p>
              </div>
              <div>
                <p className="mt-4 text-lg font-bold dark:text-white">
                  {t("playground.prompt")}
                </p>
                {/* <p className="mt-2">
                  {playgroundData?.prompt ? playgroundData?.prompt : "-"}
                </p> */}
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{
                    __html: playgroundData?.prompt
                      ? playgroundData?.prompt?.replace(/\\/g, "")
                      : "-",
                  }}
                ></div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CallTranscribeModal
        isOpen={isModalOpen}
        handleClose={handleCloseTranscribe}
        rowData={playgroundData?.call_transcribe}
      />
      
      <ContactDetailModal
        onClose={handleCloseContact}
        isOpen={contactModalOpen}
        contactNo={playgroundData?.phone_number}
      />
    </>
  );
};

export default PlayGroundDetailModal;
