/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");
  return (
    <div className="mx-auto flex text-center">
      <div className="px-4">
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: t("termsOfService.agreement", {
                termsLink: `<a href=${appData?.terms_and_conditions_url} target="_blank" rel="noopener noreferrer" style="color: #14bfff;">${t(
                  "termsOfService.termsOfService"
                )}</a>`,
                privacyLink: `<a href=${appData?.privacy_policy_url} target="_blank" rel="noopener noreferrer" style="color: #14bfff;">${t(
                  "termsOfService.privacyPolicy"
                )}</a>`,
                appName: appData?.website_name
              }),
            }}
          />
        </p>
      </div>
    </div>
  );
}
