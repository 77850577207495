/* eslint-disable array-callback-return */
import { useLocation, useNavigate } from "react-router-dom";
// import DashIcon from "components/icons/DashIcon";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

const RouterLinks = (props: {
  routes: any[];
  isOpen: boolean;
}): JSX.Element => {
  let location = useLocation();
  const navigate = useNavigate();

  const { routes, isOpen } = props;
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [menuName, setMenuName] = useState("");

  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };
  const handleMenuClick = (menu: any) => {
    if (menu.submenu && isOpen) {
      if (menuName === menu.name && submenuOpen) {
        setSubmenuOpen(false);
        setMenuName("");
      } else {
        setSubmenuOpen(true);
        setMenuName(menu.name);
      }
    } else {
      // Navigate to the main menu item's path
      navigate(`${menu.layout}/${menu.path}`);
    }
  };
  const handleMenuClickSub = (menu: any) => {
    if (menu.submenu && !isOpen) {
      if (menuName === menu.name && submenuOpen) {
        setSubmenuOpen(false);
        setMenuName("");
      } else {
        setSubmenuOpen(true);
        setMenuName(menu.name);
      }
    } else {
      // Navigate to the main menu item's path
      navigate(`${menu.layout}/${menu.path}`);
    }
  };
  useEffect(() => {
    setSubmenuOpen(false);
  }, [isOpen]);
  const createLinks = (routes: any, isOpen: boolean) => {
    return routes.map((menu: any, index: any) => {
      if (
        (menu.layout === "/admin" || menu.layout === "/auth") &&
        menu.sidebar
      ) {
        return (
          <React.Fragment key={index}>
            {/* Main Menu Item */}
            <Tooltip
              placement="right"
              label={menu?.name}
              isDisabled={isOpen}
              pointerEvents={"all"}
              className={`w-max rounded bg-white px-4 py-3 text-[11px] shadow-xl shadow-shadow-500 dark:!bg-white dark:shadow-none`}
            >
              <li>
                {isOpen && (
                  <div
                    className={`mt-2 flex cursor-pointer items-center justify-center gap-x-4 rounded-md p-2 text-sm text-navy-700 hover:bg-gray-200 dark:hover:bg-blue-700 ${
                      !submenuOpen && activeRoute(menu.path) === true
                        ? "bg-gray-200 dark:bg-blue-700"
                        : ""
                    }`}
                    onClick={() => handleMenuClick(menu)}
                  >
                    <span
                      className={`float-left block text-2xl ${
                        activeRoute(menu.path) === true
                          ? "font-bold text-blue-700 dark:text-white"
                          : "font-medium text-navy-700 dark:text-white"
                      }`}
                    >
                      {menu?.icon}
                    </span>
                    <span
                      className={`flex-1 text-base font-medium duration-200 ${
                        !isOpen && "block md:hidden"
                      } ${
                        activeRoute(menu.path) === true
                          ? "font-bold text-blue-700 dark:text-white"
                          : "font-medium text-navy-700 dark:text-white"
                      }`}
                    >
                      {menu?.name}
                    </span>
                    {menu?.submenu && isOpen && (
                      <BsChevronDown
                        className={`block cursor-pointer dark:text-white ${
                          submenuOpen && menuName === menu.name
                            ? "rotate-180"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents li's onClick from firing
                          handleMenuClick(menu);
                        }}
                      />
                    )}
                  </div>
                )}
                {!isOpen && (
                  <Popover
                    returnFocusOnClose={false}
                    isOpen={menuName === menu.name && submenuOpen && !isOpen}
                    onClose={() => handleMenuClickSub(menu)}
                    placement="right"
                    closeOnBlur={true}
                  >
                    <PopoverTrigger>
                      <div
                        className={`mt-2 flex cursor-pointer items-center justify-center gap-x-4 rounded-md p-2 text-sm text-navy-700 hover:bg-gray-200 dark:hover:bg-blue-700 ${
                          !submenuOpen && activeRoute(menu.path) === true
                            ? "bg-gray-200 dark:bg-blue-700"
                            : ""
                        }`}
                        onClick={() => {
                          handleMenuClickSub(menu);
                        }}
                      >
                        <span
                          className={`float-left block text-2xl ${
                            activeRoute(menu.path) === true
                              ? "font-bold text-blue-700 dark:text-white"
                              : "font-medium text-navy-700 dark:text-white"
                          }`}
                        >
                          {menu?.icon}
                        </span>
                      </div>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverHeader fontWeight="semibold">
                          {menu?.name}
                        </PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          {menu?.submenu &&
                            menuName === menu.name &&
                            submenuOpen && (
                              <ul>
                                {menu.submenuItems.map(
                                  (submenuItem: any, ind: number) => (
                                    <li
                                      key={submenuItem.path}
                                      className={`mt-2 flex cursor-pointer items-center justify-center gap-x-4 rounded-md p-2 pl-8 text-sm text-navy-700 hover:bg-gray-200 dark:hover:bg-blue-700 ${
                                        activeRoute(submenuItem.path) === true
                                          ? "bg-gray-200 dark:bg-blue-700"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        navigate(
                                          `${submenuItem.layout}/${submenuItem.path}`
                                        );
                                        handleMenuClickSub(menu);
                                      }}
                                    >
                                      <span
                                        className={`float-left block text-2xl ${
                                          activeRoute(submenuItem.path) === true
                                            ? "font-bold text-blue-700 dark:text-white"
                                            : "font-medium text-navy-700 dark:text-white"
                                        }`}
                                      >
                                        {submenuItem?.icon}
                                      </span>
                                      <span
                                        className={`flex-1 text-base font-medium duration-200  ${
                                          activeRoute(submenuItem.path) === true
                                            ? "font-bold text-blue-700 dark:text-white"
                                            : "font-medium text-navy-700 dark:text-white"
                                        }`}
                                      >
                                        {submenuItem?.name}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                )}
              </li>
            </Tooltip>
            {/* Submenu */}
            {menu?.submenu &&
              menuName === menu.name &&
              submenuOpen &&
              isOpen && (
                <ul>
                  {menu.submenuItems.map((submenuItem: any, ind: number) => (
                    <li
                      key={submenuItem.path}
                      className={`mt-2 flex cursor-pointer items-center justify-center gap-x-4 rounded-md p-2 pl-8 text-sm text-navy-700 hover:bg-gray-200 dark:hover:bg-blue-700 ${
                        activeRoute(submenuItem.path) === true
                          ? "bg-gray-200 dark:bg-blue-700"
                          : ""
                      }`}
                      onClick={() =>
                        navigate(`${submenuItem.layout}/${submenuItem.path}`)
                      }
                    >
                      <span
                        className={`float-left block text-2xl ${
                          activeRoute(submenuItem.path) === true
                            ? "font-bold text-blue-700 dark:text-white"
                            : "font-medium text-navy-700 dark:text-white"
                        }`}
                      >
                        {submenuItem?.icon}
                      </span>
                      <span
                        className={`flex-1 text-base font-medium duration-200 ${
                          !isOpen && "block md:hidden"
                        } ${
                          activeRoute(submenuItem.path) === true
                            ? "font-bold text-blue-700 dark:text-white"
                            : "font-medium text-navy-700 dark:text-white"
                        }`}
                      >
                        {submenuItem?.name}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
          </React.Fragment>
        );
      }
    });
  };
  return <>{createLinks(routes, isOpen)}</>;
};

export default RouterLinks;
