import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useRef } from "react";
import { FcPrevious, FcNext } from "react-icons/fc";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const InstructionModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();
  let appData: any = JSON.parse(localStorage.getItem("appSetting")  || "{}");
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    arrows: false,
  };
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  let sliderRef: any = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"xxl"}
      scrollBehavior={"inside"}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxW={"600px"} minH={500} className="dark:bg-navy-900">
        <ModalHeader>{t("assistantForm.readInstructions")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow={"hidden"}>
          <Slider
            ref={(slider: any) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            <div>
              <div className="flex flex-col gap-3 p-5">
                <h3 className="text-[20px] font-semibold">
                  {t("assistantForm.instruction.tips")}
                </h3>
                <p className="font-medium">
                  {t("assistantForm.instruction.line1")}
                </p>
                <ul className="pl-5">
                  <li>{t("assistantForm.instruction.list1")}</li>
                  <li>{t("assistantForm.instruction.list2")}</li>
                  <li>{t("assistantForm.instruction.list3")}</li>
                  <li>{t("assistantForm.instruction.list4", {appName: appData?.website_name})}</li>
                </ul>
                <p className="font-medium">
                  {t("assistantForm.instruction.line2")}
                </p>
                <p className="font-medium">
                  {t("assistantForm.instruction.line3")}
                </p>
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-3 p-5">
                <h3 className="text-[20px] font-semibold">
                  {t("assistantForm.instruction.voiceTip")}
                </h3>
                <p className="font-medium">
                  {t("assistantForm.instruction.voiceTip1")}
                </p>
                <p className="font-medium">
                  {t("assistantForm.instruction.voiceTip2")}
                </p>
              </div>
            </div>
          </Slider>
          <div className="flex justify-around">
            <Button className="button" onClick={previous}>
              <FcPrevious />
            </Button>
            <Button className="button" onClick={next}>
              <FcNext />
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default InstructionModal;
