import React, { useState } from "react";
import ThirdParty from "./thirdParty";
import ApiKey from "./apiKey";
import { useTranslation } from "react-i18next";

const Integrations = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("thirdParty");
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);

    // gtm
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: tab,
        buttonName: tab,
      });
    }
  };
  return (
    <div className="m-auto max-w-[900px]">
      <div className="text-[25px] font-bold">
        {t("integrations.integrations")}
      </div>
      <div className="my-[10px] text-[15px] font-medium text-gray-400">
        {t("integrations.caption")}
      </div>
      <div className="mb-2 mt-[10px] flex justify-center pt-5">
        <div className="mb-4 w-full border-b-[2px] border-gray-200 dark:border-gray-700">
          <ul
            className="-mb-px flex flex-wrap gap-5 text-center text-sm font-medium"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-1 ${
                  activeTab === "thirdParty"
                    ? "border-b-2 border-blue-700 text-blue-700"
                    : " text-gray-600"
                }`}
                onClick={() => handleTabClick("thirdParty")}
                type="button"
                role="tab"
                aria-controls="styled-dashboard"
                aria-selected={activeTab === "thirdParty"}
              >
                <div className="flex items-center justify-center gap-1 px-3 text-[16px] lg:px-1">
                  {t("integrations.thirdParty")}
                </div>
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-1 ${
                  activeTab === "restApi"
                    ? "border-b-2 border-blue-700 text-blue-700"
                    : " text-gray-600"
                }`}
                onClick={() => handleTabClick("restApi")}
                type="button"
                role="tab"
                aria-controls="styled-profile"
                aria-selected={activeTab === "restApi"}
              >
                <div className="flex items-center justify-center gap-1 px-3 text-[16px]">
                  {t("integrations.restAPI")}
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      {activeTab === "thirdParty" ? <ThirdParty /> : <ApiKey />}
    </div>
  );
};

export default Integrations;
