/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import SaveConfirmModal from "./SaveConfirmModal";
import { updateCompany } from "services/crm";
import { useTranslation } from "react-i18next";

const CompanyEditModal = ({
  isOpen,
  handleClose,
  rowData,
  setRefresh,
  refresh,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(
      t("contactCompanies.companyNameValidation")
    ),
    domain: Yup.string().required(t("contactCompanies.domainValidation")),
    industry: Yup.string().required(t("contactCompanies.industryValidation")),
    numberOfEmployees: Yup.string().required(
      t("contactCompanies.employeesValidation")
    ),
    annualRevenue: Yup.string().required(
      t("contactCompanies.revenueValidation")
    ),
    description: Yup.string().required(
      t("contactCompanies.descriptionValidation")
    ),
  });

  const formik = useFormik({
    initialValues: {
      companyName: rowData?.company_name || "",
      domain: rowData?.domain || "",
      industry: rowData?.industry || "",
      numberOfEmployees: rowData?.number_of_employees || "",
      annualRevenue: rowData?.annual_revenue || "",
      description: rowData?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const payload = {
        company_name: values.companyName,
        domain: values.domain,
        industry: values.industry,
        number_of_employees: values.numberOfEmployees,
        annual_revenue: values.annualRevenue,
        description: values.description,
      };
      updateCompany(payload, rowData?.id).then((response: any) => {
        if (response?.success) {
          setRefresh(!refresh);
          toast({
            description: t("messages.companyUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          handleClose();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      });
    },
  });

  useEffect(() => {
    if (rowData && isOpen) {
      formik.setValues({
        companyName: rowData?.company_name || "",
        domain: rowData?.domain || "",
        industry: rowData?.industry || "",
        numberOfEmployees: rowData?.number_of_employees || "",
        annualRevenue: rowData?.annual_revenue || "",
        description: rowData?.description || "",
      });
    }
  }, [rowData, isOpen]);

  const checkIsDirtyForm = () => {
    let initialValue = {
      companyName: rowData?.company_name || "",
      domain: rowData?.domain || "",
      industry: rowData?.industry || "",
      numberOfEmployees: rowData?.number_of_employees || "",
      annualRevenue: rowData?.annual_revenue || "",
      description: rowData?.description || "",
    };
    let isDirty =
      JSON.stringify(initialValue) !== JSON.stringify(formik?.values);
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        // scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("contactCompanies.editCompany")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.companyName")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="companyName"
                type="text"
                value={formik.values.companyName}
                onChange={formik.handleChange}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="text-red-500">
                  {formik.errors.companyName.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.domain")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="domain"
                type="text"
                value={formik.values.domain}
                onChange={formik.handleChange}
              />
              {formik.touched.domain && formik.errors.domain ? (
                <div className="text-red-500">
                  {formik.errors.domain.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.industry")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="industry"
                type="text"
                value={formik.values.industry}
                onChange={formik.handleChange}
              />
              {formik.touched.industry && formik.errors.industry ? (
                <div className="text-red-500">
                  {formik.errors.industry.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.employees")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="numberOfEmployees"
                type="number"
                value={formik.values.numberOfEmployees}
                onChange={formik.handleChange}
              />
              {formik.touched.numberOfEmployees &&
              formik.errors.numberOfEmployees ? (
                <div className="text-red-500">
                  {formik.errors.numberOfEmployees.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.annualRevenue")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="annualRevenue"
                type="number"
                value={formik.values.annualRevenue}
                onChange={formik.handleChange}
              />
              {formik.touched.annualRevenue && formik.errors.annualRevenue ? (
                <div className="text-red-500">
                  {formik.errors.annualRevenue.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactCompanies.description")}*</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                rows={5}
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-500">
                  {formik.errors.description.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default CompanyEditModal;
