import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { playGroundFeedback } from "services/playground";
import { Button, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const PlaygroundFeedBackModal = ({
  onClose,
  isOpen,
  call_type,
  call_id,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [ratingValue, setRatingValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>("");

  const ratingIcon = () => {
    if (ratingValue > 0 && ratingValue < 4) {
      return "😞";
    } else if (ratingValue > 3 && ratingValue < 7) {
      return "😑";
    } else if (ratingValue > 6 && ratingValue < 10) {
      return "😊";
    } else if (ratingValue === 10) {
      return "😍";
    } else {
      return "";
    }
  };

  const rating = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleSubmitFeedback = () => {
    const feedbackPayload = {
      rating: ratingValue,
      description: feedbackText,
      call_type: call_type,
      call_id: call_id,
    };
    setLoading(true);
    playGroundFeedback(feedbackPayload)
      .then((response) => {
        setLoading(false);
        toast({
          title: t("playground.feedbackSubmitted"),
          description: response?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        handleCloseModal();
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const handleCloseModal = () => {
    onClose();
    setRatingValue(0);
    setFeedbackText("");
  };
  return (
    <div>
      {loading && <Loader />}
      <Drawer
        placement="right"
        onClose={onClose}
        size={"md"}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent
          borderRadius={8}
          maxW="700px"
          className="dark:bg-navy-900"
        >
          <DrawerHeader fontSize={24} fontWeight={500}>
            {t("playground.feedback")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody padding={"0px 24px 20px 24px"}>
            <div className="mb-[32px] mt-4 flex items-center justify-center">
              <div className="flex w-full flex-col">
                <div className="flex justify-between">
                  <span>Rate Us:</span>
                  <span>{ratingIcon()}</span>
                </div>
                <div className="mt-2 flex flex-wrap justify-start gap-2 md:justify-between">
                  {rating.map((rating, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer rounded-[6px] ${
                        ratingValue >= rating ? "bg-blue-700" : "bg-[#f5f5f5] "
                      }  p-2 px-5 font-medium dark:text-[#000000]`}
                      onClick={() => {
                        setRatingValue(rating);
                        ratingIcon();
                      }}
                    >
                      {rating}
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex justify-between">
                  <span className="text-[12px]">
                    {t("playground.needImprovement")}
                  </span>
                  <span className="text-[12px]">
                    {t("playground.excellent")}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-2 mt-[16px] flex w-full flex-col">
              <div className="mb-1 font-bold text-[#00000099] dark:text-white">
                {t("playground.feedbackSent")}
              </div>
              <textarea
                onChange={(e) => setFeedbackText(e.target.value)}
                className="w-full rounded  border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                placeholder={t("playground.feedbackPlaceholder")}
                rows={10}
                style={{
                  maxHeight: "10rem",
                  overflowY: "auto",
                  minHeight: "46px",
                }}
              />
            </div>
          </DrawerBody>
          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleSubmitFeedback()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("submitText")}
            </Button>
            <Button onClick={handleCloseModal}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default PlaygroundFeedBackModal;
