import React, { useEffect, useState } from "react";
import { Card } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { GoPlus } from "react-icons/go";
import AddTicketModal from "components/modal/AddTicketModal";
import TicketDetailsModal from "components/modal/TicketDetails";
import { MdOutlineSupportAgent } from "react-icons/md";
import { getTecketList } from "services/ticket";
import { formatDateAmPm } from "utils/utils";
import Pagination from "components/pagination";
import { useTranslation } from "react-i18next";

const TicketList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isAddTicketOpen, setIsAddTicketOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(false);
  const [ticketDetailsData, setTicketDetailsData] = useState([]);
  const [cardData, setCardData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTicketData = cardData?.slice(indexOfFirstUser, indexOfLastUser);

  const handleAddTicketClose = () => {
    setIsAddTicketOpen(false);
  };

  const handleTicketDetailsClose = () => {
    setTicketDetails(false);
  };

  useEffect(() => {
    getTecketList()
      .then((response: any) => {
        setLoading(false);
        if (response.success) {
          if (response?.data) {
            setCardData(response?.data);
          } else {
            setCardData([]);
          }
        }
      })
      .catch((error: string) => {
        setLoading(false);
        console.error("Error Ticket list:", error);
      });
  }, [refresh]);

  const createNewTicket = () => {
    setIsAddTicketOpen(true);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Add New Ticket",
        buttonName: "Add New Ticket ",
      });
    }
  };

  const ShowTicketDatails = (item: any) => {
    setTicketDetails(true);
    setTicketDetailsData(item);
  };

  return (
    <div>
      <div className="mt-4">
        {loading && <Loader />}
        <div className="mb-3 flex justify-end">
          <button
            onClick={() => createNewTicket()}
            className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            {t("supports.addNewTicket")}
          </button>
        </div>
        {cardData && cardData.length === 0 ? (
          <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                <MdOutlineSupportAgent />
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                {t("supports.noTicket")}
              </h4>
              <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                {t("supports.getStarted")}
              </p>
              <button
                onClick={() => createNewTicket()}
                className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                <GoPlus className="h-7 w-7" />
                {t("supports.addNewTicket")}
              </button>
            </div>
          </Card>
        ) : (
          <div className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:bg-navy-800 dark:text-white dark:shadow-none">
            <table className="responsive-table w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2 bg-gray-50 text-xs uppercase  dark:bg-navy-800 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    {t("supports.ticket")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("supports.createdAt")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("supports.lastUpdate")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("supports.status")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTicketData.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b ${
                      index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                    } ${
                      index % 2 === 0 ? "dark:bg-navy-800" : "dark:bg-navy-800"
                    }`}
                  >
                    <td
                      className="cursor-pointer whitespace-nowrap px-6  py-4 text-lg font-medium text-blue-700 hover:underline"
                      data-label="title"
                      onClick={() => ShowTicketDatails(item)}
                    >
                      {item?.title}
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      data-label="Created At"
                    >
                      {formatDateAmPm(item?.created_at)}
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      data-label="Last Used"
                    >
                      {item?.last_update
                        ? formatDateAmPm(item?.last_update)
                        : "-"}
                    </td>
                    <td className="p-4" data-label="Status">
                      <p
                        className={`ml-auto flex w-fit items-center justify-center gap-4 rounded-full px-3 font-semibold capitalize text-[#000] md:ml-0 ${
                          item?.closed_at === null
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {item?.closed_at === null ? "active" : "close"}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <AddTicketModal
        isOpen={isAddTicketOpen}
        handleClose={handleAddTicketClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <TicketDetailsModal
        isOpen={ticketDetails}
        handleClose={handleTicketDetailsClose}
        ticketDetails={ticketDetailsData}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      <Pagination
        perPage={perPage}
        allTotal={cardData?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default TicketList;
